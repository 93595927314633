import React, { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import PageWrapper from "../components/PageWrapper";
import Hero from "../components/Hero";
import Features from "../components/Features";
import PricingInfo from "../components/PricingInfo";
import Testimonials from "../components/Testimonials";
import AboutUs from "../components/AboutUs";
import Footer from "../components/Footer";
import AlertDialog from "../components/AlertDialog";
import { Typography } from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


export default function Landing() {
  const navigate = useNavigate();

  // Setup state for info being collected
  const [data, setData] = useState({
    title: undefined,
    severity: "info",
    message: undefined,
  });

  useEffect(() => {

    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("video") === "overview") {
      navigate("/overview");
    } else if (query.get("stripe") === "success") {
      setData({ ...data, 
        title: "Order Placed",
        message: "You will receive an email confirmation.  You will need to log back in to see the new subscription.  It may take a few minutes for your subscription to become active, so if you don't see it right away, try logging in a few minutes later."
      });
    } else if (query.get("stripe") === "cancel") {
      setData({ ...data, 
        title: "Order Cancelled",
        severity: "warning",
        message: "Please log back in and try again."
      });
    }

  }, []);

  return (
    <PageWrapper landing="true">
        <Hero />
        <div style={{display: "flex", justifyContent: "center", margin: "20px"}}>
          <Typography variant="h4" color="primary">Try 5 free comments before you buy.  No credit card required to try!!!</Typography>
        </div>
        <div style={{display: "flex", justifyContent: "center", margin: "20px"}}>
          <Typography variant="h4" color="primary">English Language and Social Studies Updated to new Curriculum</Typography>
        </div>
        <div style={{display: "flex", justifyContent: "center", margin: "20px"}}>
          <Typography variant="h5" color="primary">MyReportAssistant supports most subjects from the K-8 Ontario Curriculum, NOT just Learning Skills.  Create your report comments for English, Math, Social Studies, Arts, Science, etc...  Compare that against other AI report writers.</Typography>
        </div>
        <Features />
        <PricingInfo />
        <Testimonials />
        <AboutUs />
        <Footer />
        <AlertDialog message={data.message} title={data.title} severify={data.severity} onClose={() => navigate("/login", {replace: true})}/>
    </PageWrapper>
  );
}