import httpPost from "./httpPost";
import Constants from "../../constants";
import getReportKeys from "../getReportKeys";

//=============================================================================
// Add a new student report for the current student
//=============================================================================
export default async function addReport(appContext, comment) {
    const url = Constants.REQ_ADD_REPORT.replace("<id>", appContext.student._id);

    // For the body of the request, we provide the context as well as the generated comment
    const body = {
        reportPeriod : appContext.classroom.reportPeriod,
        classroomId: appContext.classroom._id,
        subject: appContext.subject.name,
        keys : getReportKeys(appContext),
        comment
    }

    // Save the report
    const result = await httpPost(url, body);
    if (result.report) {

        // Need to locate student in the classroom to update their reports in this session
        let student = appContext.classroom.students.find(student => student._id === appContext.student._id);
        if (student) {
            student.reports.unshift(result.report);
        }
    }

    return result;
}