export default class Constants {

    static CLIENT_VERSION = "2023-05-24-A";

    // Maximum number of attempts at generating a comment
    static MAX_COMMENT_ATTEMPTS = 3;

    // Maximum string sizes
    static MAX_LABEL_SIZE = 64;
    static MAX_SPECIFIC_COMMENT_SIZE = 300;
    static MAX_EXPECTATION_SIZE = 500;
    static MAX_COMMENT_SIZE = 3000;
    static MAX_INSTRUCTIONS_SIZE = 300;

    // Timeouts for HTTP calls
    static HTTP_LONG_TIMEOUT = 250000;
    static HTTP_SHORT_TIMEOUT = 10000;

    // Server Requests
    static REQ_VERIFY_RECAPTCHA="api/auth/verifyReCaptcha";
    static REQ_COMPLETE_LOGIN="api/auth/completeLogin";
    static REQ_ADD_CLASSROOM = "api/educators/<id>/classrooms";
    static REQ_UPDATE_CLASSROOM = "api/classrooms/<id>";
    static REQ_GET_CLASSROOM = "api/classrooms/<id>";    
    static REQ_GET_COMMENT = "api/comments/<id>";
    static REQ_GET_STUDENTS = "api/classrooms/<id>/students";
    static REQ_GET_SUBJECT= "api/subjects/<name>";
    static REQ_UPDATE_SUBJECT= "api/subjects/<name>/update";
    static REQ_RESTORE_SUBJECT= "api/subjects/<name>/restore";
    static REQ_UPDATE_REPORT_PERIOD= "api/classrooms/<id>/reportPeriods";
    static REQ_ADD_STUDENT = "api/classrooms/<id>/students";
    static REQ_UPDATE_STUDENT = "api/students/<id>";
    static REQ_UPDATE_CLASSROOM_ACTIVE="api/classrooms/<id>/isActive";
    static REQ_UPDATE_CLASSROOM_MODE="api/classrooms/<id>/mode";
    static REQ_UPDATE_CLASSROOM_CLASSORDER="api/classrooms/<id>/classOrder";
    static REQ_UPDATE_EDUCATOR_SHOW_HIDDEN_CLASSROOMS="api/educators/<id>/showHiddenClassrooms";
    static REQ_UPDATE_EDUCATOR_AGREE_TO_TERMS_AND_CONDITIONS="api/educators/<id>/agreeToTermsAndConditions";
    static REQ_ADD_REPORT = "api/students/<id>/reports";
    static REQ_NEW_COMMENT = "api/reportAssistant/comments";
    static REQ_RETRY_COMMENT = "api/reportAssistant/comments/retry";
    static REQ_CREATE_CHECKOUT_SESSION = "api/stripe/createCheckoutSession";

    // Privacy modes
    static PRIVACY_DEFAULT = "default";
    static PRIVACY_ALIAS = "alias";
    static PRIVACY_ANONYMOUS = "anonymous";

    // Other configuration
    static EXPECTATION_MAX_LEN = 300;
}


// Here we have a collection of alternative gender choices based on male/female/non-binary
export const genderChoices = [
    ["he", "she", "they"],
    ["his", "her", "their"],
    ["him", "her", "them"],
    ["boy", "girl", "person"],
    ["himself", "herself", "themself"]
];