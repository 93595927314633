import React from 'react';
import {useNavigate} from "react-router-dom";
import { Grid, Typography, Button, Box } from '@mui/material';
import kidsBlackboard from '../images/kidsBlackboard.jpg';

const Hero = () => {
  const navigate = useNavigate();

  return (
    <Box>

    <Typography variant="h5" fontWeight={700} sx={{display: {sm: 'block', md: 'none'}, marginTop: '20px', marginLeft: '20px', color: '#1976d2'}}>
      Your Reports ... Refined!
    </Typography>
    <Typography variant="h5" fontWeight={700} sx={{display: {xs: 'none', md: 'block'}, marginTop: '20px', marginLeft: '20px', color: '#1976d2'}}>
      &nbsp;
    </Typography>

    <Box 
      sx={{
        width: '100%',
        display: 'flex',
        minHeight: '300px',
        alignItems: 'center',
        justifyContent: 'right',
        backgroundImage: `url(${kidsBlackboard})`,
        backgroundSize: "cover"
      }}
    >
      <Grid container spacing={6} 
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'right',
          maxWidth: '1300px',
          padding: '30px'
        }}
      >
        <Grid item xs={13} md={6} sx={{textAlign: 'right', justifyContent: 'right'}}>
          <Typography variant="h4" fontWeight={700} sx={{display: {xs: 'none', sm: 'none', md: 'block'}, paddingBottom: '15px', color: '#eee'}}>
            Your Reports ... Refined!
          </Typography>
          <Typography variant="h6" sx={{display: {xs: 'none', md: 'flex'}, paddingBottom: '30px', opacity: '90%', color: '#eee'}}>
            Use AI technology to cut down the time it takes to complete your report cards
            while improving the quality and diversity of your comments. 
          </Typography>
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => navigate("/signUp")}
            sx={{ display: {xs: 'none', sm: 'flex'}, width: '200px', fontSize: '16px' }}
          >
            Get started
          </Button>
          </div>
        </Grid>
      </Grid>
    </Box>
    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
    <Button variant="contained" color="primary" onClick={() => navigate("/login")} sx={{display: {xs: 'block', sm: 'none'}, width: '200px', fontSize: '16px', marginTop: '10px', marginRight: '10px'}}>
      Get started
    </Button>
    </div>
    </Box>
  );
};

export default Hero;