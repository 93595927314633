import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../components/AppContext";
import AlertDialog from "../components/AlertDialog";
import PageWrapper from "../components/PageWrapper";
import {StyledTableRow, StyledTableCell} from "../components/StyledTable";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import getComment from "../helpers/httpRequests/getComment";


export default function StudentSubjectSummary() {

  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const [message, setMessage] = useState(undefined);
  const [sending, setSending] = useState(false);


  //============================================================================
  // Handle request to review comment from report
  //============================================================================
  const reviewComment = async (event, summaryLine, reportPeriod) => {
    event.stopPropagation();

    if (!sending) {
      setSending(true);

      const commentId = summaryLine.reports[reportPeriod].commentId;
      if (!commentId) {
        setMessage("Comment did not complete successfully");
      } else {
        const result = await getComment(commentId);
        if (result.comment) {
          appContext.subject = summaryLine.subject;
          appContext.comment = result.comment;
          navigate("/reviewComment");
        } else {
          setMessage("Comment no longer available.  Typically comments are kept for only 7 days");
        }
      }

      setSending(false);
    }
  }


  //============================================================================
  // Return subject within curriculum corresponding to provided name
  //============================================================================
  const getSubjectSummary = (subjectName) => {

    // Retrieve the subject from the curriculum
    return appContext.classroom.subjectSummaries.find(subjectSummary => {
      return subjectSummary.name === subjectName;
    });
  }

  //============================================================================
  // Build up subject summary for the current studen
  //============================================================================
  const buildSummaryLines = () => {
    let summaryLines = [];

    for (const subject of appContext.classroom.subjectSummaries) {

      let summaryLine = {
        subject,
        reports : {
        } 
      }

      // For each report period, find the first report that matches (if any)
      for (const reportPeriod of appContext.classroom.curriculum.reportPeriods) {
        const report = appContext.student.reports.find(report => {
          return (report.reportPeriod === reportPeriod.name) &&
                 (report.classroomId === appContext.classroom._id) &&
                 (report.subject === subject.name);
        });
        summaryLine.reports[reportPeriod.name] = report;
      }

      summaryLines.push(summaryLine);
    }

    return summaryLines;
  }

  const summaryLines = buildSummaryLines();


  return (
    <PageWrapper 
      title={"Subject Summary for " + appContext.student.name}
      leftButton="Back"
      leftIcon={<ArrowBackIcon />}
      onLeftClick={() => navigate(-1)}
      showClassroom
      showStudent
    >

      <div style={{margin: "0px 10px 0px 10px"}}>

        <Typography variant="h6">
          You can click on the checkmark in any column to view the corresponding comment made during the current term.
        </Typography>

        <br/>
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow hover>
                  <StyledTableCell>Subject</StyledTableCell>
                  {appContext.classroom.curriculum.reportPeriods.map((reportPeriod) => (
                    <StyledTableCell key={reportPeriod.name} align="center">{reportPeriod.name}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
              {summaryLines.map((summaryLine) => (
                <StyledTableRow
                  key={summaryLine.subject.name}
                  hover
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    <div style={{display: "flex", alignItems: "center"}}>
                      {summaryLine.subject.label}
                    </div>
                  </StyledTableCell>
                  {appContext.classroom.curriculum.reportPeriods.map((reportPeriod) => (
                    <StyledTableCell key={reportPeriod.name} align="center">{summaryLine.reports[reportPeriod.name] && <CheckIcon onClick={(event) => reviewComment(event, summaryLine, reportPeriod.name)}/>}</StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> 
      </div>

      <AlertDialog message={message} title="Comment no longer available" onClose={() => setMessage(undefined)}/>

    </PageWrapper>
  );
}