import { useContext, useState } from "react";
import AppContext from "../components/AppContext";
import {useNavigate} from "react-router-dom";
import { Container, Grid, Box, Typography, Stack, Button } from '@mui/material';
import { GoogleLoginButton } from "react-social-login-buttons";
import { useForm, FormProvider } from 'react-hook-form';
import FormInput from '../components/FormInput';
import PageWrapper from "../components/PageWrapper";
import { LinkItem } from "../globalStyles";
import { object, string } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { registerWithEmailAndPassword, signInWithGoogle } from "../auth/auth";
import AlertDialog from "../components/AlertDialog";
import completeLogin from "../helpers/httpRequests/completeLogin";
import ReCAPTCHA from "react-google-recaptcha";

  
export default function Signup() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const [error, setError] = useState(undefined);
  const [message, setMessage] = useState(undefined);
  const [reCaptchaToken, setReCaptchaToken] = useState(null);

  // 👇 Default Values
  const defaultValues = {
    name: '',
    email: '',
    password: '',
    passwordConfirm: '',
  };

  // SignUp Schema with Zod
  const signupSchema = object({
    name: string().min(1, 'Name is required').max(70),
    email: string().min(1, 'Email is required').email('Email is invalid'),
    password: string()
      .min(1, 'Password is required')
      .min(8, 'Password must be more than 8 characters')
      .max(32, 'Password must be less than 32 characters')
      .regex(new RegExp(".*[A-Z].*"), "Must have at least one uppercase character")
      .regex(new RegExp(".*[a-z].*"), "Must have at least one lowercase character")
      .regex(new RegExp(".*\\d.*"), "Must have at least one  number")
      .regex(new RegExp(".*[^a-zA-Z0-9\s].*"), "Must have at least one special symbol"),
    passwordConfirm: string().min(1, 'Please confirm your password'),
  }).refine((data) => data.password === data.passwordConfirm, {
    path: ['passwordConfirm'],
    message: 'Passwords do not match',
  });

  //?!@#$%&

  // 👇 Object containing all the methods returned by useForm
  const methods = useForm({
    resolver: zodResolver(signupSchema),
    defaultValues,
  });

  // 👇 Form Handler
  const onSubmitHandler = async (values) => {

      let email = values["email"].toLowerCase();
      const result = await registerWithEmailAndPassword(values["name"], email, values["password"]);
      if (result.error) {
          setError(result.error);
      } else {
          setMessage("Please check your email and click on link to verify your account email!   If you don't see the email, please check you SPAM and JUNK folders.");
      }
  };


  //============================================================================
  // Handle login with Google provider
  //============================================================================
  const handleLoginWithGoogle = async () => {
    const result = await signInWithGoogle();
    if (result.error) {
      setError(result.error);
    } else if (result.user) {
      await continueLogin(result.user, true);
    }
  }


  //============================================================================
  //  Regardless to how the user logged in, complete the login.
  //============================================================================
  const continueLogin = async (user, isProvider=false) => { 

    // Setup authentication context
    appContext.email = user.email.toLowerCase();
    appContext.accessToken = user.accessToken;
    appContext.refreshToken = user.refreshToken;

    // Allow email/password link if password provider not available yet
    const passwordProvider = user.providerData.find(provider => {
      return provider.providerId === "password"
    });
    appContext.allowLink = (passwordProvider === undefined);

    // Access our database to get remainder of user context
    const loginContext = await completeLogin( isProvider, reCaptchaToken );
    if (loginContext.error) {
      appContext.email = undefined;
      appContext.accessToken = undefined;
      appContext.refreshToken = undefined;
      setError(loginContext.error);
    } else {
      appContext.educator = loginContext.educator;
      
      if (appContext.educator.agreeToTermsAndConditions) {
        navigate("/selectClassroom");
      } else {
        navigate("/agreeToTerms");
      }
    } 
  }


  //============================================================================
  // Handle change to the Captcha
  async function onChange(value) {
    setReCaptchaToken(value);
  }


  // 👇 Returned JSX
  return (
    <PageWrapper title="Sign Up">
        <Container
            maxWidth={false}
            sx={{ height: "80vh", paddingTop: "1vh" }}
        >
        <Grid
            container
            justifyContent='center'
            alignItems='center'
            sx={{ width: '100%', height: '100%' }}
        >
            <Grid item sx={{ maxWidth: '70rem', width: '100%', backgroundColor: '#fff' }}>

            <Grid
                container
                sx={{
                boxShadow: { sm: '0 0 5px #ddd' },
                py: '1rem',
                px: '1rem',
                }}
            >
                <FormProvider {...methods}>
                <Grid
                    item
                    container
                    justifyContent='space-between'
                    rowSpacing={5}
                    sx={{
                        maxWidth: { sm: '45rem' },
                        marginInline: 'auto',
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{ marginTop: "30px", borderRight: { sm: '1px solid #ddd' } }}
                    >
                    <Box
                        display='flex'
                        flexDirection='column'
                        component='form'
                        noValidate
                        autoComplete='off'
                        sx={{ paddingRight: { sm: '3rem' } }}
                        onSubmit={methods.handleSubmit(onSubmitHandler)}
                    >
                        <Typography
                        variant='h6'
                        component='h1'
                        sx={{ textAlign: 'center', mb: '1.5rem' }}
                        >
                            Create new your account
                        </Typography>

                        <FormInput
                        label='Name'
                        type='text'
                        name='name'
                        focused
                        required
                        />
                        <FormInput
                        label='Enter your email'
                        type='email'
                        name='email'
                        focused
                        required
                        />
                        <FormInput
                        type='password'
                        label='Password'
                        name='password'
                        required
                        focused
                        />
                        <FormInput
                        type='password'
                        label='Confirm Password'
                        name='passwordConfirm'
                        required
                        focused
                        />

                        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={onChange} />
                        <br/>

                        <Button
                            disabled={reCaptchaToken === null}
                            type='submit'
                            variant='contained'
                            sx={{
                                py: '0.8rem',
                                mt: 2,
                                width: '80%',
                                marginInline: 'auto',
                            }}
                        >
                            Sign Up
                        </Button>
                    </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{marginTop: "30px"}}>
                    <Typography
                        variant='h6'
                        component='p'
                        sx={{
                        paddingLeft: { sm: '3rem' },
                        mb: '1.5rem',
                        textAlign: 'center',
                        }}
                    >
                        Sign up using another provider:
                    </Typography>
                    <Box
                        display='flex'
                        flexDirection='column'
                        sx={{ paddingLeft: { sm: '3rem' }, rowGap: '1rem' }}
                    >
                      <GoogleLoginButton onClick={handleLoginWithGoogle}/>
                    </Box>
                </Grid>
            </Grid>
            <Grid container justifyContent='center'>
                <Stack sx={{ mt: '2rem', textAlign: 'center' }}>
                <Typography sx={{ fontSize: '0.9rem', mb: '1rem' }}>
                    Already have an account? <LinkItem to='/login'>Login</LinkItem>
                </Typography>
                </Stack>
            </Grid>
                </FormProvider>
            </Grid>
            </Grid>
        </Grid>
        </Container>
        <AlertDialog message={error} severity="error" title="Sign up error" onClose={() => setError(undefined)}/>
        <AlertDialog message={message} title="Verify Email" onClose={() => navigate("/login")}/>
    </PageWrapper>
  );
};
