export default function getSubjectSelectedNextSteps(subject) {
    let nextSteps = [];

    // Add selected next step from strands
    for (const strand of subject.strands) {
        for (const group of strand.groups) {
            if (group.nextSteps) {
                for (const nextStep of group.nextSteps) {
                    if (nextStep.isSelected) {
                        nextSteps.push(nextStep);
                    }
                }
            }
        }
    }

    // If any next steps at subject level, add those too
    if (subject.nextSteps) {
        for (const nextStepGroup of subject.nextSteps) {
            for (const nextStep of nextStepGroup.nextSteps) {
                if (nextStep.isSelected) {
                    nextSteps.push(nextStep);
                }
            }
        }
    }
    return nextSteps;
}