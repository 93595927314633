import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../components/AppContext";
import PageWrapper from "../components/PageWrapper";
import StrandCard from "../components/StrandCard";
import SubjectMarkCard from "../components/SubjectMarkCard";
import AlertDialog from "../components/AlertDialog";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import numberExpectationsInSubject from "../helpers/numberExpectationsInSubject";
import strandHasExpectationsAtGrade from "../helpers/strandHasExpectationsAtGrade";


export default function SelectExpectations() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  // Setup state for trying again
	const [data, setData] = useState({
    subjectMark: appContext.subject.mark,
    clearAllToggle: false
	});
  const [error, setError] = useState(undefined);


  //====================================================================
  // Handle clear ALL
  // This will clear all selects (expectations, next steps, etc...)
  //====================================================================
	function handleClearAll() {

    // Clear all marks
    delete appContext.subject.mark;
    for (const strand of appContext.subject.strands) {
      delete strand.mark;
    }

    // Clear any selected expectations or next steps
    clearSelectedExpectationsAndNextSteps();

    setData({...data, clearAllToggle: !data.clearAllToggle, subjectMark: undefined});
    setError(undefined);
  }


  //====================================================================
  // Handle clear ALL
  // This will clear all selects (expectations, next steps, etc...)
  //====================================================================
	function clearSelectedExpectationsAndNextSteps() {

    // Clear all strand selections
    for (const strand of appContext.subject.strands) {
      strand.selectedGroup = undefined;
      strand.selectedGroupKey = undefined;
      for (const group of strand.groups) {
        if (group.expectations) {
          for (const expectation of group.expectations) {
            expectation.isSelected = false;
          }
        }
        if (group.nextSteps) {
          for (const nextStep of group.nextSteps) {
            nextStep.isSelected = false;
          }
        }
      }
    }

    // If subject has next steps, clear those selections too
    if (appContext.subject.nextSteps) {
      appContext.subject.selectedGroup = undefined;
      for (const nextStepGroup of appContext.subject.nextSteps) {
        for (const nextStep of nextStepGroup.nextSteps) {
          nextStep.isSelected = false;
        }
      }
    }
  }



  //====================================================================
  // Count characters in selected expectations and next steps
  //====================================================================
	function getCharCount() {
    let charCount = 0;

    // Clear all strand selections
    for (const strand of appContext.subject.strands) {
      for (const group of strand.groups) {
        if (group.expectations) {
          for (const expectation of group.expectations) {
            if (expectation.isSelected) {
              charCount += expectation.label.length;
            }
          }
        }
        if (group.nextSteps) {
          for (const nextStep of group.nextSteps) {
            if (nextStep.isSelected) {
              charCount += nextStep.label.length;
            }
          }
        }
      }
    }

    // If subject has next steps, clear those selections too
    if (appContext.subject.nextSteps) {
      for (const nextStepGroup of appContext.subject.nextSteps) {
        for (const nextStep of nextStepGroup.nextSteps) {
          if (nextStep.isSelected) {
            charCount += nextStep.label.length;
          }
        }
      }
    }

    return charCount;
  }



  //====================================================================
  // Handle continue
  //====================================================================
	function handleContinue(event) {

    // If the subject itself requires a mark, make sure that a mark has been assigned
    let errorMessage = undefined;
    if ((appContext.subject.isMarked !== false) && !appContext.subject.mark) {
      errorMessage = "You have not assigned a mark to the subject";
    } else if (numberExpectationsInSubject(appContext.subject) === 0) {
      errorMessage = "Must select at least one expectation to generate comment";
    }

    if (errorMessage) {
      setError(errorMessage);
    } else {
      navigate("/specificComment");
    }
	};


  //====================================================================
  // Handle subject mark change.   Basically reset marks for all strands
  //====================================================================
	function handleSubjectMarkChange(subjectMark) {

    if (subjectMark !== appContext.subject.mark) {

      // Change all marks to match the new subject level mark
      appContext.subject.mark = subjectMark;
      for (const strand of appContext.subject.strands) {
        if (strand.isMarked !== false) {
          strand.mark = subjectMark;
        }
      }

      // When mark is changed, we need to clear out all currently selected expectations
      clearSelectedExpectationsAndNextSteps();

      setData({...data, subjectMark});
      setError(undefined);
    }
  }


  //====================================================================
  // Handle underlying change in strand
  //====================================================================
	function handleSelectionChange() {
    setError(undefined);
  }
  

  return (
    <PageWrapper 
      title={"Expectations for " + appContext.student.name}
      leftButton="Back"
      leftIcon={<ArrowBackIcon />}
      onLeftClick={() => navigate(-1)}
      rightButton="Continue"
      rightIcon={<ArrowForwardIcon />}
      onRightClick={handleContinue}
      showSubject
      showComments
    >

      <div style={{marginLeft: "20px", marginRight: "20px"}}>
        <Typography variant="h6">
            Select the expectations and next steps to use in creating the term report for {appContext.student.name}.
        </Typography>
        <br/>
        <Typography variant="h7" color="primary">
            The provided bank of comments is intended as a starting point.   Teachers are encouraged to use
            their own professional judgment and knowledge of their community to choose the comments they feel
            best represent their students.   These comments can also be customized by the teacher to meet 
            their individual requirements.   Refer to the tutorial videos to see how.
        </Typography>
      </div>
      <br/>

      <div style={{display: "flex", width: "100%", justifyContent: "end", marginBottom: "10px"}}>
        <Button variant="outlined" size="small" sx={{marginRight: "10px"}} endIcon={<RestartAltIcon />} onClick={handleClearAll}>Clear ALL selections</Button>
      </div>

      {(appContext.subject.isMarked !== false) &&
        <div>
          <br/>
          <SubjectMarkCard onClick={handleSubjectMarkChange}/>
        </div>
      }

      {appContext.subject.strands.map((strand, index) => (
        <div key={index}>
          {strandHasExpectationsAtGrade(strand, appContext.classroom) && <StrandCard strand={strand} onChange={handleSelectionChange}/>}
        </div>
      ))}

      {appContext.subject.license && <Typography variant="body1" sx={{margin: "20px"}}>{appContext.subject.license}</Typography>}
      
      <AlertDialog title="Expectation Error" message={error} severity="error" onClose={() => setError(undefined)}/>
        
    </PageWrapper>
  );
}