//====================================================================
// For a given expectation and student, return the terms in which
// the expectation has already been used
//====================================================================
export default function getExpectationReportTags(appContext, strand, group, expectation) {
    let reportTags = [];

    // Get key for this expectation
    const key = strand.key + ":" + group.key + ":" + expectation.key;

    // We search the reports made for this student for the key
    for (const report of appContext.student.reports) {

        // Does the key occur in this report
        if (report.keys && (report.subject === appContext.subject.name)) {
            const keys = report.keys.split(",");
            if (keys.indexOf(key) >= 0) {
                reportTags.push(report.reportPeriod);
            }
        }
    }

    return (reportTags.length) ? reportTags : undefined;
}