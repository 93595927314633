import React, { useState, useContext, useEffect } from "react";
import AppContext from "../components/AppContext";
import { GroupSelector } from "../globalStyles";
import ExpectationCard from "./ExpectationCard";
import Selector from "./Selector";
import numberSelectionsInStrand from "../helpers/numberSelectionsInStrand";
import numberSelectionsInGroupLabel from "../helpers/numberSelectionsInGroupLabel";
import gradeFilterSatisfied from "../helpers/gradeFilterSatisfied";
import markFilterSatisfied from "../helpers/markFilterSatisfied";
import { Typography } from '@mui/material';


export default function StrandCard({strand, onChange}) {
    const appContext = useContext(AppContext);

    // Get some short hands
    const selectedGroup = getSelectedGroup();
    const availableGrades = getAvailableGrades();
    const markingChoices = getMarkingChoices();

    // What should be the strand's default grade (if any)
    const strandDefaultGrade = (availableGrades?.length === 1) ? availableGrades[0] : strand.assessedAtGrade || appContext.classroom.grade;

    // Setup state for trying again
    const [data, setData] = useState({
        togglesUpdated: false,
        strandMark: strand.mark,
        assessedAtGrade: strandDefaultGrade
	});

    useEffect(() => { 
        setData( {...data, strandMark: strand.mark, assessedAtGrade: strandDefaultGrade} );
    }, [strand.mark, strand.assessedAtGrade]);



    //====================================================================
    // Clear selected expectation and next steps
    //====================================================================
    function clearSelections() {

        // When we change the grade, we need to clear the selection state
        // for all expectations in the strand
        for (const group of strand.groups) {
            if (group.expectations) {
                for (const expectation of group.expectations) {
                    expectation.isSelected = false;
                }
            }
            if (group.nextSteps) {
                for (const nextStep of group.nextSteps) {
                    nextStep.isSelected = false;
                }
            }
        }
    }

    //====================================================================
    // Handle a change to the student's mark in the strand
    //====================================================================
    function handleAssessedAtGradeChange(event) {

        if (strand.assessedAtGrade !== event.target.value) {
            strand.assessedAtGrade = event.target.value;

            // When we change the grade, we need to clear the selection state
            // for all expectations and next steps in the strand
            clearSelections();

            setData({...data, assessedAtGrade: strand.assessedAtGrade});

            // If onChange handler identified, call it
            if (onChange) { onChange(); }
        }
    }


    //====================================================================
    // Handle a change to the student's mark in the strand
    //====================================================================
    function handleMarkChange(event) {

        if (strand.mark !== event.target.value) {

            strand.mark = event.target.value;

            // When we change the mark, we need to clear the selection state
            // for all expectations and next steps.
            clearSelections();

            setData({...data, strandMark: strand.mark});

            // If onChange handler identified, call it
            if (onChange) { onChange(); }
        }
    }


    //====================================================================
    // Handle click on expectation
    //====================================================================
    function handleExpectationClick(isSelected) {
        if (onChange) { onChange(); }
    }


    //====================================================================
    // Get marking choices
    //====================================================================
    function getMarkingChoices() {
        let markingChoices = undefined;
        if (strand.isMarked !== false) {
            markingChoices = [...appContext.subject.markingScheme];
        }
        return markingChoices;
    }


    //====================================================================
    // Get available grades within the strand
    //====================================================================
    function getAvailableGrades() {

        // Scan all the groups in the strand to determine which grades are supported for assessment
        let gradesInStrand = [];
        for (const group of strand.groups) {
            if (group.expectations) {
                for (const expectation of group.expectations) {
                    if (expectation.grades) {
                        if (!gradesInStrand.includes(expectation.grades)) {
                            gradesInStrand.push(expectation.grades);
                        }
                    }
                }
            }
        }

        // Next we compare this list against what the classroom/subject is setup for
        const assessAtGrades = appContext.classroom.assessAt ?? [appContext.classroom.grade];
        let availableGrades = [];
        for (const grade of assessAtGrades) {
            if (gradesInStrand.includes(grade)) {
                availableGrades.push(grade);
            }
        }

    
        // If no grades specifically mentioned, then remove
        if (availableGrades.length === 0) {
            availableGrades = undefined;
        }

        return availableGrades;
    }


    //====================================================================
    // Render the collection of group selectors for the strand
    //====================================================================
    function selectGroup(newGroup) {

        // If we click on the same group, deselect it
        if (strand.selectedGroupKey === newGroup) {
            strand.selectedGroupKey = undefined;
        } else {
            strand.selectedGroupKey = newGroup;
        }

        setData( {...data, togglesUpdated: !data.togglesUpdated});
    }


    //====================================================================
    // Get the selected group from the selected group name
    //====================================================================
    function getSelectedGroup() {
        if (strand.selectedGroupKey) {
            return strand.groups.find(group => group.key === strand.selectedGroupKey);
        } else {
            return undefined;
        }
    }


    //====================================================================
    // Render the collection of group selectors for the strand
    //====================================================================
    function renderGroupSelectors() {

        // If a group has no name, we consider it a default group of 
        // expectations.
        if ((strand.groups.length === 1) && (strand.groups[0].label === undefined)) {
            strand.groups[0].label = "Expectations";
        }

        return (
            <div style={{display: "flex", flexWrap: "wrap"}}>
                {strand.groups.map((group) => (
                    <div key={(strand.key + group.key)}>
                        {!group.isHidden &&
                            <GroupSelector className={(strand.selectedGroupKey === group.key)? 'selected' : undefined}>
                                <Typography variant="body1" textAlign="right" onClick={() => selectGroup(group.key)}>{group.label} {numberSelectionsInGroupLabel(group)}</Typography>
                            </GroupSelector>
                        }
                    </div>
                ))}
            </div>
        );
    }


    return (
        <div style={{padding: "10px 10px 0px 10px", backgroundColor: "#f8f8f8", border: "1px solid #1d609c"}}>

            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="h5" textAlign="left">{strand.label} {numberSelectionsInStrand(strand)}</Typography>
                <div style={{display: "flex", flexWrap: "wrap", alignItems: "center"}}>
                    {availableGrades &&
                        <Selector label="Grade" choices={availableGrades} value={data.assessedAtGrade} onClick={handleAssessedAtGradeChange}/>
                    }
                    {!availableGrades && <div></div>}

                    {(strand.isMarked !== false) && ((appContext.subject.isMarked === false) || appContext.subject.mark) &&
                        <div style={{marginLeft: "30px"}}>
                            <Selector label="Mark" choices={markingChoices} value={strand.mark} onClick={handleMarkChange}/>
                        </div>
                    }
                    {(strand.isMarked === false) && <div></div>}

                </div>
            </div>

            <div style={{marginTop: "10px", marginLeft: "20px"}}>

                {(!strand.isMarked || strand.mark) && renderGroupSelectors()}

                {selectedGroup && 
                    <div>
                        {selectedGroup.expectations && 
                            <div>
                                {selectedGroup.expectations.map((expectation) => (
                                    <div key={(strand.key + selectedGroup.key + expectation.key)}>
                                        {gradeFilterSatisfied(expectation, data.assessedAtGrade) && markFilterSatisfied(strand, expectation) && !(expectation.isHidden === true)  &&
                                        <div>
                                            <ExpectationCard strand={strand} group={selectedGroup} expectation={expectation} onClick={handleExpectationClick} />
                                        </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        }

                        {selectedGroup.nextSteps && (selectedGroup.nextSteps.length > 0) &&
                            <div>
                                <br/>
                                {selectedGroup.expectations && (selectedGroup.expectations.length > 0) && <Typography variant="h5" textAlign="center">Next Steps </Typography>}
                                {selectedGroup.nextSteps.map((nextStep) => (
                                    <div key={(strand.key + selectedGroup.key + nextStep.key)}>
                                        {gradeFilterSatisfied(nextStep, data.assessedAtGrade) && markFilterSatisfied(strand, nextStep) && !(nextStep.isHidden === true)  &&
                                            <div>
                                                <ExpectationCard strand={strand} group={selectedGroup} expectation={nextStep} onClick={handleExpectationClick} />
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        }
                        <br/>
                    </div>
                }
            </div>
        </div>
    );
}