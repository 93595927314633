import { useNavigate } from "react-router-dom";
import PageWrapper from "../components/PageWrapper";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';


export default function ContactUs() {
  const navigate = useNavigate();

  return (
    <PageWrapper
      title="Contact Us"
      leftButton="Back"
      leftIcon={<ArrowBackIcon />}
      onLeftClick={() => navigate(-1)}
    >
        <br/>
        <div style={{margin: "0px 10px 0px 10px", display: "flex", alignItems: "center", flexDirection: "column" }}>
          <Typography variant="h6">If you have comments, questions, or feedback, please contact us at</Typography>
          <br/>
          <Typography variant="h5"><b>myReportAssistant@gmail.com</b></Typography>
          <br/>
          <Typography variant="h6">We will endeavour to respond to your inquiries within 24 hours</Typography>
        </div>

    </PageWrapper>
  );
}