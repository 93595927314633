export default function numberSelectionsInStrandLabel(strand) {
    let total = 0;

    for (const group of strand.groups) {
        if (group.expectations) {
            for (const expectation of group.expectations) {
                if (expectation.isSelected) {
                    total++;
                }
            }
        }
        if (group.nextSteps) {
            for (const nextStep of group.nextSteps) {
                if (nextStep.isSelected) {
                    total++;
                }
            }
        }
    }

    if (total > 0) {
        return "(" + total + ")";
    } else {
        return "";
    }
}