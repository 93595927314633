import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmDialog({title="Please Confirm", yesNo, prompt, onCancel, onConfirm}) {

  return (
    <div>
      <Dialog
        open={prompt !== undefined}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {prompt}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{yesNo ? "No" : "Cancel"}</Button>
          <Button onClick={onConfirm} autoFocus>{yesNo ? "Yes" : "Continue"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}