import { useContext } from "react";
import AppContext from "../components/AppContext";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import LinearProgressWithLabel from './LinearProgressWithLabel';
import { CardActionArea } from '@mui/material';


export default function SubjectCard({subject, subjectClick}) {
    const appContext = useContext(AppContext);
    

    //==========================================================================
    // Compute the subject stats for student completion
    //==========================================================================
    const computeSubjectStats = (classroom, subject) => {
        let stats = {
            numStudents: classroom.students.length,
            numReports: 0,
            percentComplete: 0
        }

        // Count the number of subjects with reports for this student/subject/term
        for (const student of classroom.students) {
            for (const report of student.reports) {
                if ((report.reportPeriod === classroom.reportPeriod) &&
                    (report.classroomId === classroom._id) &&
                    (report.subject === subject.name)) {
                    stats.numReports++;
                    break;
                }
            }
        }

        // Update the percent complete
        if (stats.numStudents > 0) {
            stats.percentComplete = Math.round(stats.numReports / stats.numStudents * 100);
        }

        return stats;
    }

    const stats = computeSubjectStats(appContext.classroom, subject);

    return (
        <Card sx={{ width: "100%", backgroundColor: "#F0F0F0" }}>
            <CardActionArea onClick={subjectClick}>
                <div style={{display: 'flex', flexWrap: "wrap", justifyContent: 'space-between'}}>
                    <div style={{display: 'flex'}}>
                        <CardMedia
                            component="img"
                            sx={{ width: "160px", height: "160px", padding: "5px" }}
                            image={require("../images/subjects/" + subject.name + ".png")} 
                            alt={subject.name}
                        />
                        <Box sx={{ display: 'flex', width: "200px", flexDirection: 'column', alignItems: "left" }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="h5">
                                    {subject.label}
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    {subject.description}
                                </Typography>
                                {!subject.comingSoon && 
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        {subject.numStrands} Strands
                                    </Typography>
                                }
                            </CardContent>
                        </Box>
                    </div>
                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: "space-between", marginTop: "10px", marginLeft: "20px", marginRight: "20px", marginBottom: "20px"}}>
                        <Box sx={{ width: '300px'}}>
                            {subject.comingSoon && <Typography variant="h5" align="right" color="primary">Coming Soon</Typography>}
                            {!subject.comingSoon && <LinearProgressWithLabel sx={{height: "14px"}} variant="determinate" label={ stats.numReports + " of " + stats.numStudents + " Students reported"}  value={stats.percentComplete} />}
                        </Box>
                    </Box>
                </div>

            </CardActionArea>
        </Card>
    );
}