import { useContext } from "react";
import { Navigate, Outlet } from 'react-router-dom';
import AppContext from "../components/AppContext";
import ErrorBoundary from "./ErrorBoundary";

export default function ProtectedLayout() {
  const appContext = useContext(AppContext);

  // If not authenticated, redirect to login
  if (!appContext.educator) {
    return <Navigate to="/login" />;
  } else {
    return (
      <ErrorBoundary>
        <div style={{backgroundColor: "white"}}>
          <Outlet/>
        </div>
      </ErrorBoundary>
    );
  }
}