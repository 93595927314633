import { capitalize } from "lodash";
import React, { useContext, useState } from "react";
import {useNavigate} from "react-router-dom";
import AppContext from "../components/AppContext";
import PageWrapper from "../components/PageWrapper";
import StrandEditCard from "../components/StrandEditCard";
import ConfirmDialog from "../components/ConfirmDialog";
import AlertDialog from "../components/AlertDialog";
import { Button, Typography, FormControl, FormGroup, FormControlLabel, FormLabel, Radio } from '@mui/material';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/Save';
import updateSubject from "../helpers/httpRequests/updateSubject";
import restoreSubject from "../helpers/httpRequests/restoreSubject";


export default function EditSubject() {
	const navigate = useNavigate();
	const appContext = useContext(AppContext);
	const tenseChoices = ["past", "present"];

	// Setup state for info being collected
	const [subject, setSubject] = useState(appContext.subject);
	const [error, setError] = useState(undefined);

	// Are we requesting restore of subject to default?
	const [confirmRestore, setConfirmRestore] = useState(false);
	const [reportTense, setReportTense] = useState(appContext.subject.reportTense);


	//=========================================================================
	// Complete restore request
	//=========================================================================
	async function completeRestore() {
		const result = await restoreSubject(subject);
		if (result.error) {
			setError(result.error);
		} else {
			appContext.subject = undefined;
			navigate(-1);
		}
	}

	//=========================================================================
	// Handle Cancel of edited Subject
	//=========================================================================
	function handleCancel() {
		appContext.subject = undefined;
		navigate(-1);
	}


	//====================================================================
	// Handle change to tense
	//====================================================================
	const handleTenseChange = (tense) => {
		setReportTense(tense);
	};


	//=========================================================================
	// Cleanup empty expectations / next steps
	//=========================================================================
	function cleanupEmptyExpectations(subject) {
		for (const strand of subject.strands) {
			for (const group of strand.groups) {

				// Cleanup empty expectations
				let expectations = [];
				for (const expectation of group.expectations) {
					if (expectation.label.trim().length > 0) {
						expectations.push(expectation);
					}
				}
				group.expectations = expectations;

				// Cleanup empty next steps
				let nextSteps = [];
				for (const nextStep of group.nextSteps) {
					if (nextStep.label.trim().length > 0) {
						nextSteps.push(nextStep);
					}
				}
				group.nextSteps = nextSteps;
			}
		}
	}


	//=========================================================================
	// Handle Saving of edited Subject
	//=========================================================================
	async function handleSave() {

		// First we cycle through and remove any blank expectations / next steps
		cleanupEmptyExpectations(subject);

		// Update the report tense
		subject.reportTense = reportTense;

		// Update the subject
		const result = await updateSubject(subject);
		if (result.error) {
			setError(result.error);
		} else {
			appContext.subject = undefined;
			navigate(-1);
		}
	}


	return (
		<PageWrapper
			title={"EDIT " + appContext.subject.label }
			leftButton="Cancel"
			leftIcon={<CancelIcon />}
			onLeftClick={handleCancel}
			rightButton="Save Changes to Subject"
			rightIcon={<SaveIcon />}
			onRightClick={handleSave}
			showSubject={false}

		>


			<Typography component="div" variant="h6" sx={{margin: "20px"}}>
				Use this page to customize the collection of expectations and next steps used
				for {appContext.subject.label}.  You can also elect to hide either individual or 
				entire groups of expectations and next steps.
        	</Typography>
			<Typography component="div" variant="h6" sx={{margin: "20px"}}>
				Any changes you make here will be used for all your reports going forward.   You can 
				revert back to the default definition for the subject at any time.
        	</Typography>
			<Typography component="div" variant="h7" sx={{margin: "20px"}}>
				You can use the following shortcuts for the gender tag in your expectations:
				<ul style={{marginLeft: "40px"}}>
					<li>&lt;he&gt; - for he/she/they</li>
					<li>&lt;his&gt; - for his/her/their</li>
					<li>&lt;him&gt; - for him/her/them</li>
					<li>&lt;himself&gt; - for himself/herself/themself</li>
					<li>&lt;boy&gt; - for boy/girl/person</li>
				</ul>
				<br/>
				As a convenience, if you use the male or female pronoun directly, the app will substitute it with the corresponding tag.   For example, if you type
				" she ", it will be replaced with the tag "&lt;he&gt;".  Please note however, "her" will be replaced with the "&lt;his&gt;" tag, not the "&lt;him&gt;" tag.
        	</Typography>

			<FormControl sx={{marginLeft: "20px", marginBottom: "20px"}}>
				<FormLabel id="gender-label">Tense to use for Reports</FormLabel>
				<FormGroup row>
					{tenseChoices.map((tense) => (
						<FormControlLabel
							key={tense}
							control={
								<Radio
									checked={tense === reportTense}
									onChange={() => handleTenseChange(tense)}
								/>
							}
							label={capitalize(tense)}
							sx={{margin: 0}}
						/>
					))}
				</FormGroup>
			</FormControl>

			{subject.educatorId && 
				<div style={{display: "flex", justifyContent: "end", margin: "20px"}}>
				<Button variant="outlined" onClick={() => setConfirmRestore(true)}>
					<Typography component="div" variant="body1">
						Restore {appContext.subject.label} to the default for the {appContext.curriculum.label}
        			</Typography>
				</Button>
				</div>
			}

			{subject.strands.map((strand, index) => (
				<div key={index}>
					<StrandEditCard strand={strand}/>
				</div>
			))}

			{confirmRestore && 
				<ConfirmDialog 
					title="Confirm Restore" 
					prompt={"Are you sure you want to restore " + 
							appContext.subject.label + 
							" to its default for the " + 
							appContext.curriculum.label +
							".  You will lose ALL changes that you have EVER made to the subject."
					} 
					onCancel={() => setConfirmRestore(false)} 
					onConfirm ={async () => await completeRestore()}
				/>
			}

      		{appContext.subject.license && <Typography variant="body1" sx={{margin: "20px"}}>{appContext.subject.license}</Typography>}

			<AlertDialog title="Subject Error" message={error} severity="error" onClose={() => setError(undefined)}/>
			
		</PageWrapper>
	);
}