import expectationAtGrade from "./expectationAtGrade";

//====================================================================
// Does the strand have any expectations at the selected grade level
//====================================================================
export default function groupHasExpectationsAtGrade(group, grades) {

    // Get collection of grades that classroom is assessing at
    let hasExpectations = false;

    // First check whether group has any expectations matching the grade
    for (const expectation of group.expectations) {
        hasExpectations = expectationAtGrade(expectation, grades);
        if (hasExpectations) {
            break;
        }
    }

    // If we haven't found any expectations at grade, check for next steps,
    // but only if the group doesn't define any expectations
    if (!hasExpectations && (!group.expectations || group.expectations.length === 0) && group.nextSteps) {
        for (const nextStep of group.nextSteps) {
            hasExpectations = expectationAtGrade(nextStep, grades);
            if (hasExpectations) {
                break;
            }
        }
    }

    return hasExpectations;
}