import compareByLabel from "./compareByLabel";

//=============================================================================
// For convenience, inject the curriculum and subject summaries into the
// classroom.
//=============================================================================
export default function injectClassroomInfo(appContext, classroom) {

    // First, grab the curriculum being used by the classroom
    const curriculum = appContext.educator.curriculums.find(curriculum => {
        return curriculum.name === classroom.curriculumName;
    });

    // We remember learning skills separately, because we want it at the head of the list
    let learningSkills = undefined;
    let learningSkillsFrench = undefined;

    // Grab each subject summary
    let subjectSummaries = [];
    for (const subjectName of classroom.subjects) {

        // Retrieve the subject summary from the curriculum
        const subjectSummary = curriculum.subjects.find(subjectSummary => {
            return subjectSummary.name === subjectName;
        });

        // Make sure we had learning skills to the top
        if (subjectSummary.name === "learningSkills") {
            learningSkills = subjectSummary;
        } else if (subjectSummary.name === "learningSkillsFrench") {
            learningSkillsFrench = subjectSummary;
        } else {
            subjectSummaries.push(subjectSummary);
        }
    }

    // Sort the list of subject summaries, and place learning skills at the top if present
    subjectSummaries.sort(compareByLabel);
    if (learningSkillsFrench) {
        subjectSummaries.unshift(learningSkillsFrench);
    }
    if (learningSkills) {
        subjectSummaries.unshift(learningSkills);
    }

    // Now inject the info
    classroom.curriculum = curriculum;
    classroom.subjectSummaries = subjectSummaries;
}