import httpPost from "./httpPost";
import Constants from "../../constants";

//=============================================================================
// Restore a custom subject for a particular curriculum
//=============================================================================
export default async function restoreSubject(subject) {
    const url = Constants.REQ_RESTORE_SUBJECT.replace("<name>", subject.name);

    const body = {
        curriculumName: subject.curriculumName,
        subjectName: subject.name
    }
    return await httpPost(url, body);
}