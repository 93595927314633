import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../components/AppContext";
import PageWrapper from "../components/PageWrapper";
import AlertDialog from "../components/AlertDialog";
import {StyledTableRow, StyledTableCell} from "../components/StyledTable";
import camelCaseToString from "../helpers/camelCaseToString";
import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';


export default function Subscriptions() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  // Setup state for trying again
	const [error, setError] = useState(undefined);
  const [showHidden, setShowHidden] = useState(false);


  //====================================================================
  // Toggle whether to show hidden
  //====================================================================
  function toggleShow() {
    setShowHidden(!showHidden);
  }


  //====================================================================
  // New Subscription
  //====================================================================
  async function newSubscription() {
    navigate("/pricing");
  }


  return (
    <PageWrapper
      title="Subscriptions"
      leftButton="Back"
      leftIcon={<ArrowBackIcon />}
      onLeftClick={() => navigate(-1)}
    >
        <br/>
        <div style={{margin: "0px 10px 0px 10px"}}>
          <br/>
          <Typography variant="h5">For "School Year" subscriptions, this page displays only your Term 2 allotment.  Your next term allotment of 240 comments will refresh on Sept 1, 2023 for use in the new School Year's Progress Report.</Typography>
          <br/>
          <Typography variant="h6">Please note, by default this page only shows active subscriptions.  Click on 'Show Expired Subscriptions' to see your expired subscriptions"</Typography>
          <br/>
          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "8px"}}>
            < FormGroup sx={{paddingLeft: "10px"}}>
              {!showHidden && <FormControlLabel control={<Checkbox onClick={toggleShow} />} label="Show Expired/Unpaid Subscripions" />}
              {showHidden && <FormControlLabel control={<Checkbox defaultChecked onClick={toggleShow} />} label="Show Expired/Unpaid Subscripions" />}
            </FormGroup>
            <Button variant="outlined" endIcon={<AddIcon />} onClick={newSubscription}>New Subscription</Button>
          </div>
          <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow hover>
                    <StyledTableCell>Type</StyledTableCell>
                    <StyledTableCell align="center">Term 2</StyledTableCell>
                    <StyledTableCell align="left">Expiry</StyledTableCell>
                    <StyledTableCell align="right">Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {appContext.educator.subscriptionData.subscriptions.map((subscription, index) => (
                  <React.Fragment key={index}>
                  {(showHidden || subscription.isActive) && <StyledTableRow
                    hover
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <StyledTableCell component="th" scope="row">{camelCaseToString(subscription.subscriptionType)}</StyledTableCell>
                    <StyledTableCell align="center">{subscription.commentsRemaining} / {subscription.originalComments}</StyledTableCell>
                    <StyledTableCell align="left">{subscription.expiry ? subscription.expiry.toString().substring(0,10) : ""}</StyledTableCell>
                    <StyledTableCell align="right">{subscription.isActive ? 'Active' : subscription.paymentStatus === 'unpaid' ? 'Unpaid' : 'Expired'}</StyledTableCell>
                  </StyledTableRow>}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer> 
        </div>

				<AlertDialog title="Subscription Error" message={error} severity="error" onClose={() => setError(undefined)}/>

    </PageWrapper>
  );
}