import React, { useContext, useState } from "react";
import {useNavigate} from "react-router-dom";
import AppContext from "../components/AppContext";
import { capitalize } from "lodash";
import PageWrapper from "../components/PageWrapper";
import AlertDialog from "../components/AlertDialog";
import ConfirmDialog from "../components/ConfirmDialog";
import addClassroom from "../helpers/httpRequests/addClassroom";
import updateClassroom from "../helpers/httpRequests/updateClassroom";
import compareByLabel from "../helpers/compareByLabel";
import assignString from "../helpers/assignString";
import checkEmptyString from "../helpers/checkEmptyString";
import injectClassroomInfo from "../helpers/injectClassroomInfo";
import Constants from "../constants";
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import {
	TextField,
	FormControl, 
	FormLabel,
	FormHelperText,
	InputLabel,
	Select,
	MenuItem, 
	FormGroup,
	FormControlLabel,
	Checkbox
} from '@mui/material';



export default function EditClassroom() {
	const navigate = useNavigate();
	const appContext = useContext(AppContext);

	//====================================================================
	// Handle change to input
	//====================================================================
	const getDefaultSession = () => {
		return "2024-2025";
	}


	//====================================================================
	// Return array of checked value
	//====================================================================
	const getChecked = (checkedArray) => {
		let checked = {};
		if (checkedArray) {
			for (const prop of checkedArray) {
				checked[prop] = true;
			}
		}
		return  checked;
	}

	const [label, setLabel] = useState(appContext.classroom?.label ?? "");
	const [labelError, setLabelError] = useState(undefined);
	const [session, setSession] = useState(appContext.classroom?.session ?? getDefaultSession());
	const [sessionError, setSessionError] = useState(undefined);
	const [curriculumName, setCurriculumName] = useState(appContext.classroom?.curriculumName ?? "");
	const [curriculumNameError, setCurriculumNameError] = useState(undefined);
	const [grade, setGrade] = useState(appContext.classroom?.grade ?? "");
	const [gradeError, setGradeError] = useState(undefined);
	const [error, setError] = useState(undefined);
	const [assessAt, setAssessAt] = useState(getChecked(appContext.classroom?.assessAt));
	const [subjects, setSubjects] = useState(getChecked(appContext.classroom?.subjects));
	const [newClassroomAdded, setNewClassroomAdded] = useState(false);
	const [saving, setSaving] = useState(false);


	//====================================================================
	// Handle change to classroom label
	//====================================================================
	const handleLabelChange = (event) => {
		event.preventDefault();
		assignString("Classroom name", event.target.value, Constants.MAX_LABEL_SIZE, setLabel, setLabelError);
	};


	//====================================================================
	// Handle change to session
	//====================================================================
	const handleSessionChange = (event) => {
		event.preventDefault();
		assignString("Session", event.target.value, Constants.MAX_LABEL_SIZE, setSession, setSessionError);
	};


	//====================================================================
	// Handle change to curriculum
	//====================================================================
	const handleCurriculumChange = (event) => {
		event.preventDefault();
		setCurriculumName(event.target.value);
		setCurriculumNameError(undefined);
	};


	//====================================================================
	// Handle change to grade
	//====================================================================
	const handleGradeChange = (event) => {
		event.preventDefault();
		setGrade(event.target.value);
		setGradeError(undefined);

		let assessAt = {};
		assessAt[event.target.value] = true;
		setAssessAt(assessAt);
		refreshSubjects(assessAt);
	};


	//====================================================================
	// Refresh the list of subjects displayed and checked
	//====================================================================
	const refreshSubjects= (assessedGrades) => {
		let subjects = {};
		const currentCurriculum = appContext.educator.curriculums.find(curriculum => curriculum.name === curriculumName);
		for (const subject of currentCurriculum.subjects) {
			subjects[subject.name] = subjectInGrade(subject, assessedGrades);
		}
		setSubjects(subjects);
	}


	//====================================================================
	// Handle change to checked assessed values
	//====================================================================
	const handleAssessAtChecked = (id) => (e) => {
		const { checked } = e.target;
		assessAt[id] = checked;
		setAssessAt(assessAt);
		refreshSubjects(assessAt);
	};


	//====================================================================
	// Handle change to checked assessed values
	//====================================================================
	const handleSubjectChecked = (subject) => (e) => {
		const { checked } = e.target;
		setSubjects((values) => ({
		  ...subjects,
		  [subject.name]: checked
		}));
	};


	//====================================================================
	// Validate the form data
	//====================================================================
	const validateForm = () => {
		let labelError = checkEmptyString(label, "Please provide a name for your classroom", setLabelError);
		const sessionError = checkEmptyString(session, "Please provide the session for your classroom", setSessionError);
		const curriculumNameError = checkEmptyString(curriculumName, "You must select a curriculum", setCurriculumNameError );
		const gradeError = checkEmptyString(grade, "Please provide the classroom's grade", setGradeError );

		// If classroom name provided, we need to check to make sure its unique for the educator
		if (!labelError) {
			
			// Is there already a classroom for this educator with the same classroom label (ignore case)
			const newLabel = label.trim().toLowerCase();
			const existingClassroom = appContext.educator.classrooms.find(
				classroom => (classroom.label.toLowerCase() === newLabel) &&
							 (!appContext.classroom || (appContext.classroom._id !== classroom._id))
			);

			if (existingClassroom) {
				setLabelError("You already have a classroom with this name");
				labelError = true;
			}
		}

		return labelError || sessionError || curriculumNameError || gradeError;
	}


	//====================================================================
	// Convert a checked array to an array of items
	//====================================================================
	const checkedToArray = (checkedArray) => {
		let array = [];
		for (const property in checkedArray) {
			if (checkedArray[property] === true) {
				array.push(property);
			}
		}
		return array;
	}


	//====================================================================
	// Create or update the classroom
	//====================================================================
	const saveClassroom = async (event) => {
		event.preventDefault();
		if (!saving) {
			setSaving(true);
				
			const formError = validateForm();
			if (!formError) {

				// Setup classroom data
				const classroomData = {
					label,
					session,
					curriculumName,
					grade,
					assessAt: checkedToArray(assessAt),
					subjects: checkedToArray(subjects)
				}

				// Update or create new classroom
				let classroom = undefined;
				if (appContext.classroom) {
					classroom = await updateClassroom(appContext, classroomData);
				} else {
					classroom = await addClassroom(appContext, classroomData);
				}

				if (classroom.error) {
					setError(classroom.error);
				} else {

					// If updating classroom, then update in context
					if (appContext.classroom) {

						// Replace the old classroom
						const index = appContext.educator.classrooms.findIndex(item => item._id === classroom._id);
						if (index >= 0) {
							appContext.educator.classrooms[index] = classroom;
						}
						appContext.educator.classrooms.sort(compareByLabel);
						navigate(-1);

					// Add the classroom to list of educator classroms if its new
					} else {
						appContext.educator.classrooms.push(classroom);
						appContext.educator.classrooms.sort(compareByLabel);
						appContext.classroom = classroom;
						setNewClassroomAdded(true);
					}
				}
			}

			setSaving(false);
		}
	}


	//====================================================================
	// Retrieve the current curriculum
	//====================================================================
	const getCurrentCurriculum = () => {
		let currentCurriculum = undefined;
		if (curriculumName) {
			currentCurriculum = appContext.educator.curriculums.find(curriculum => curriculum.name === curriculumName);
		}
		return currentCurriculum;
	}


	//====================================================================
	// Retrieve the current curriculum
	//====================================================================
	const handleAddStudents = (event) => {
		event.preventDefault();

		// Inject/Refresh classroom info (curriculum/subjectSummaries) into classroom
		injectClassroomInfo(appContext, appContext.classroom);
		
		navigate("/manageStudents")
	}



	//====================================================================
	// Is the subject appropriate for the grades being assessed in 
	// the classroom
	//====================================================================
	const subjectInGrade = (subject, assessedGrades=assessAt) => {
		let inGrade = false;

		// Check whether any of the grades covered by the subject are covered by classroom
		if (!subject.grades) {
			inGrade = true;
		} else {
			for (const grade of subject.grades) {
				if (assessedGrades[grade]) {
					inGrade = true;
					break;
				}
			}
		}

		return inGrade;
	}


	//====================================================================
	// Group available subjects by grouping
	//====================================================================
	const getSubjectGroupings = (currentCurriculum) => {
		let groupings = [];

		// Get subject groupings only if curriculum selected
		if (currentCurriculum) {

			// Init the list of subjects.  Seed with Kindergarten so it appears first
			let subjectGroupings = [
				{ grouping: "kindergarten", subjects: [] }
			];

			// Place subject into appropriate subject grouping
			for (const subject of currentCurriculum.subjects) {

				// Only group subjects that are in the currently selected grade(s)
				if (subjectInGrade(subject)) {
						
					let subjectGrouping = subjectGroupings.find(group => group.grouping === subject.grouping);

					// If new subject grouping encountered, create bucket for it
					if (!subjectGrouping) {
						subjectGrouping = { grouping: subject.grouping, subjects: [] };
						subjectGroupings.push(subjectGrouping);
					}

					// Add subject to the grouping
					subjectGrouping.subjects.push(subject);
				}
			}

			// Sort each of the grouping by label (and filter out kindergarten if no subjects in it)
			groupings = [];
			for (const grouping of subjectGroupings) {
				if (grouping.subjects.length > 0) {
					grouping.subjects.sort(compareByLabel);
					groupings.push(grouping);
				}
			}
		}

		return groupings;
	}


	// Access the current curriculum and the list of grouped subjects for the class
	const currentCurriculum = getCurrentCurriculum();
	const subjectGroupings = getSubjectGroupings(currentCurriculum);

	return (
		<PageWrapper
			title={appContext.classroom ? "Edit Classroom" : "Add Classroom"}
			leftButton="Cancel"
			leftIcon={<CancelIcon />}
			onLeftClick={() => navigate(-1)}
			rightButton="Save Classroom"
			rightIcon={<SaveIcon />}
			rightDisabled={saving}
			onRightClick={saveClassroom}
		>

			<div style={{width: "90%", margin: "auto"}}>
				<TextField sx={{ m: 1, minWidth: "340px", marginTop: "10px" }}
					required
					error={labelError !== undefined}
					name="label"
					label="Classroom name"
					value={label}
					placeholder="Provide a unique name for your classroom"
					onChange={handleLabelChange}
					helperText={labelError}
					variant="outlined"
				/>
				
				<TextField sx={{ m: 1, minWidth: "100px", marginTop: "10px" }}
					required
					error={sessionError !== undefined}
					name="session"
					label="Session"
					value={session}
					placeholder="e.g. 2024-2025"
					onChange={handleSessionChange}
					helperText={sessionError}
					variant="outlined"
				/>

				<br/>
				<div style={{display: "flex", flexWrap: "wrap"}}>

					<FormControl sx={{ m: 1 }}>
						<InputLabel id="curriculum-label">Curriculum</InputLabel>
						<Select
							error={curriculumNameError !== undefined}
							labelId="curriculum-label"
							id="curriculumName"
							value={curriculumName}
							label="Curriculum"
							name="curriculumName"
							onChange={handleCurriculumChange}
							sx={{ minWidth: "340px"}}
						>
							{appContext.educator.curriculums.map((curriculum) => (
								<MenuItem key={curriculum.name} value={curriculum.name}>
									{curriculum.label}
								</MenuItem>
							))}
							
						</Select>
						{curriculumNameError && <FormHelperText sx={{color: "#d74444"}}>Select a curriculum to use for this classroom</FormHelperText>}
					</FormControl>
				</div>


				{curriculumName &&
					<FormControl sx={{ m: 1, minWidth: "250px" }}>
						<InputLabel id="grade-label">Grade</InputLabel>
						<Select
							error={gradeError !== undefined}
							labelId="grade-label"
							id="grade"
							value={grade}
							label="Grade"
							name="grade"
							onChange={handleGradeChange}
						>
							{currentCurriculum.grades.map((item) => (
								<MenuItem key={item.name} value={item.name}>
									{item.label}
								</MenuItem>
							))}
						</Select>
						{gradeError && <FormHelperText sx={{color: "#d74444"}}>Select the classroom's grade</FormHelperText>}
					</FormControl>
				}


				{curriculumName && grade && 
					<div>
						<div style={{border: "1px solid #cbcbcb", borderRadius: '5px', margin: "8px", padding: "10px"}}>
							<FormControl>
								<FormLabel sx={{paddingLeft: "10px", paddingBottom: "10px"}}>Other grades you will assess at:</FormLabel>
								<FormGroup row>
									{currentCurriculum.grades.map((item) => (
										<FormControlLabel
											key={item.name}
											control={
												<Checkbox
													checked={assessAt[item.name] === true}
													onChange={handleAssessAtChecked(item.name)}
													disabled={item.name === grade}
												/>
											}
											label={item.name}
											labelPlacement="top"
											sx={{margin: 0}}
										/>
									))}
								</FormGroup>
							</FormControl>
						</div>

						{subjectGroupings.map((subjectGrouping) => (
							<div key={subjectGrouping.grouping}>
								<br/>
								<p style={{marginLeft: "10px"}}>{capitalize(subjectGrouping.grouping)} subjects:</p>
								<div style={{border: "1px solid #cbcbcb", borderRadius: '5px', margin: "8px", padding: "10px"}}>
									<FormControl>
										<FormGroup row>
											{subjectGrouping.subjects.map((subject) => (
												<React.Fragment key={subject.name}>
													{subjectInGrade(subject) && <FormControlLabel
														control={
															<Checkbox
																checked={subjects[subject.name] === true}
																onChange={handleSubjectChecked(subject)}
															/>
														}
														label={subject.label}
														sx={{margin: 0, width: "200px"}}
													/>}
												</React.Fragment>
											))}
										</FormGroup>
									</FormControl>
								</div>
							</div>
						))}
					</div>
				}
			</div>
			
			<AlertDialog title="Classroom Error" message={error} severity="error" onClose={() => setError(undefined)}/>
      		{newClassroomAdded && <ConfirmDialog title="Add Students?" yesNo prompt={"Would you now like to add students to your new classroom, '" + appContext.classroom.label + "'"} onCancel={() => navigate(-1)} onConfirm ={handleAddStudents}/>}

		</PageWrapper>
	);
};