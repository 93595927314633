import getNickname from "../helpers/getNickname";
import { genderChoices } from "../constants";
import { capitalize } from "lodash";
import replaceAll from "./replaceAll";


//====================================================================
// Generate the label by filling in any general tags
//====================================================================
export default function generateLabel(appContext, label, ix) {

    // Establish student name to use.
    let studentName = getNickname(appContext.student);
  
    // Start by filling in known static
    label = replaceAll(label, "<studentName>", studentName);

    // Get the index to the student's gender in the avaiable genders in the curriculum
    const genderIndex = appContext.classroom.curriculum.genders.indexOf(appContext.student.gender);

    // Now scan for occurrences of gender choices in the form of <he>, <him>, <his>
    for (const genderChoice of genderChoices) {
        const tag = "<" + genderChoice[0] + ">";
        label = replaceAll(label, tag, genderChoice[genderIndex]);
        const capitalizeTag = "<" + capitalize(genderChoice[0]) + ">";
        label = replaceAll(label, capitalizeTag, capitalize(genderChoice[genderIndex]));
    }

    // Now replace the more generic "<gender>" related tags
    let startIndex = label.indexOf("<gender");
    while (startIndex >= 0) {
        const endIndex = label.substring(startIndex+7).indexOf(">") + startIndex + 7;
        const tag = label.substring(startIndex, endIndex+1);
        let genderOptions = label.substring(startIndex+8, endIndex).trim();

        // Split the gender options
        genderOptions = genderOptions.split("/");

        // Replace the gender tag with the appropriate option
        label = label.replace(tag, genderOptions[genderIndex]);

        // Check if anymore gender tags
        startIndex = label.indexOf("<gender");
    }


    // Now replace qualifier related tags if "ix" specified (ix denotes their mark from 0 to 3)
    if (ix !== undefined) {

        const ix2 = Math.floor(ix / 2 + .1);

        let startIndex = label.indexOf("<qualifier");
        while (startIndex >= 0) {
            const endIndex = label.substring(startIndex+10).indexOf(">") + startIndex + 10;
            const tag = label.substring(startIndex, endIndex+1);
            let qualifierOptions = label.substring(startIndex+11, endIndex).trim();

            // Split the qualifier options
            qualifierOptions = qualifierOptions.split("/");

            // Do we have 2 or 4 qualifier options
            if (qualifierOptions.length === 4) {
                label = label.replace(tag, qualifierOptions[ix]);
            } else if (qualifierOptions.length === 2) {
                label = label.replace(tag, qualifierOptions[ix2]);
            } else {
                label = label.replace(tag, "");
            }

            // Check if anymore qualifier tags
            startIndex = label.indexOf("<qualifier");
        }
    }

    return label;
}