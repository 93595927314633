import _ from "lodash";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../components/AppContext";
import PageWrapper from "../components/PageWrapper";
import ConfirmDialog from "../components/ConfirmDialog";
import AlertDialog from "../components/AlertDialog";
import getComment from "../helpers/httpRequests/getComment";
import updateClassroomMode from "../helpers/httpRequests/updateClassroomMode";
import updateClassroomClassOrder from "../helpers/httpRequests/updateClassroomClassOrder";
import getSubject from "../helpers/httpRequests/getSubject";
import compareByName from "../helpers/compareByName";
import {StyledTableRow, StyledTableCell} from "../components/StyledTable";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import ArrowUpIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import ArrowDownIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import CheckIcon from '@mui/icons-material/Check';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Paper from '@mui/material/Paper';


export default function SelectStudent() {

  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  appContext.student = undefined;

  const [outOfCredits, setOutOfCredits] = useState(false);
  const [mode, setMode] = useState(appContext.classroom.mode);
  const [students, setStudents] = useState(getStudentList());
  const [classOrderModified, setClassOrderModified] = useState(false);
  const [message, setMessage] = useState(undefined);
  const [sending, setSending] = useState(false);


  // Refresh the student list based on the classroom mode
  function getStudentList() {
    if (appContext.classroom.mode === 'sorted') {
      appContext.classroom.students = appContext.classroom.students.sort(compareByName);
    } else {
      let studentList = [];
      for (const studentId of appContext.classroom.studentIds) {
        studentList.push(appContext.classroom.students.find(student => {return student._id === studentId}));
      }
      appContext.classroom.students = studentList;
    }
    return appContext.classroom.students;
  }


  //====================================================================
  // Add Student to class list
  //====================================================================
  async function addStudent() {
    navigate("/editStudent");
  }


  //====================================================================
  // Add Student to class list
  //====================================================================
  async function handleCreditPurchase() {
    setOutOfCredits(false);
    navigate("/pricing");
  }

  //====================================================================
	// Handle change to classroom mode for display students 
	//====================================================================
	async function changeMode(event, newMode) {
    if ((newMode !== null) && (newMode !== mode)) {
      setMode(newMode);
      await updateClassroomMode(appContext, newMode);
      setStudents(getStudentList());
    }
	};


  //====================================================================
	// Move the student in the classOrder
	//====================================================================
	async function changeClassOrder(event, studentId, delta) {
    event.stopPropagation();

    // Move student up/down in class order
    let studentIds = appContext.classroom.studentIds;
    const index = studentIds.indexOf(studentId);
    if ((index > 0) && (delta === -1)) {
        const prevStudentId = studentIds[index-1];
        studentIds[index-1] = studentIds[index];
        studentIds[index] = prevStudentId;
        setClassOrderModified(true);
        setStudents(getStudentList());
    } else if ((index >= 0) && (index < studentIds.length-1) && (delta === 1)) {
        const nextStudentId = studentIds[index+1];
        studentIds[index+1] = studentIds[index];
        studentIds[index] = nextStudentId;
        setClassOrderModified(true);
        setStudents(getStudentList());
    }
	};



  //====================================================================
	// Save the modified class order
	//====================================================================
	async function saveClassOrder(event) {
    event.stopPropagation();
    await updateClassroomClassOrder(appContext);
    setClassOrderModified(false);
  }


  //====================================================================
  // Handle selection the student
  //====================================================================
  async function selectStudent(student) {
    if (appContext.educator.subscriptionData.commentsRemaining === 0) {
      setOutOfCredits(true);
    } else {

      // We create a deep copy of the student to start
      appContext.student = _.cloneDeep(student);

      // Set retry counts allowable
      appContext.student.commentAttempts = 0;

      // Depending on the subject, we don't want to remember selections from 
      // previous student.  If so, we need to grab a new copy of the subject
      if (!(appContext.subject.rememberSelections === true) && appContext.subject.studentSelected) {
        appContext.subject = await getSubject(appContext.classroom.curriculum.name, appContext.subject.name);
      }

      // We flag the subject has having had a student selected.   This is
      // so that we only reload the subject on a student selection if a 
      // previous student was selected
      appContext.subject.studentSelected = true;

      // If the student has been flagged with an IEP in this subject, then got to the IEP comment page
      if (appContext.student.iepSubjects.indexOf(appContext.subject.name) >= 0) {
        navigate("/iepComment");
      } else {
        navigate("/selectExpectations");
      }
    }
  }



  //====================================================================
  // Return the student report for the current subject and specified
  // report period.
  //====================================================================
  function getStudentReport(student, reportPeriod) {
    const report =  student.reports.find(report => {
      return (report.reportPeriod === reportPeriod) &&
             (report.classroomId === appContext.classroom._id) &&
             (report.subject === appContext.subject.name);
    });
    return report;
  }


  //============================================================================
  // Handle request to review comment from report
  //============================================================================
  const reviewComment = async (event, student, reportPeriod) => {
    event.stopPropagation();

    if (!sending) {
      setSending(true);

      const report = getStudentReport(student, reportPeriod);
      if (!report.commentId) {
        setMessage("Comment did not complete successfully");
      } else {
        const result = await getComment(report.commentId);
        if (result.comment) {
          appContext.student = student;
          appContext.comment = result.comment;
          navigate("/reviewComment");
        } else {
          setMessage("Comment no longer available.  Typically comments are kept for only 7 days");
        }
      }

      setSending(false);
    }
  }


  return (
    <PageWrapper 
      title="Select Student"
      leftButton="Subjects"
      leftIcon={<ArrowBackIcon />}
      onLeftClick={() => navigate("/selectSubject", {replace: true})}
      showClassroom
      showSubject
      showComments
    >

      <div style={{margin: "0px 10px 0px 10px"}}>

        <Typography variant="h6" sx={{marginLeft: "20px", marginRight: "20px", marginBottom: 0}}>
            Select the student that you would like to prepare report comments for.  You
            can also:
        </Typography>
        <Typography sx={{marginLeft: "30px", marginRight: "20px"}}>
            - add new students to the classroom
            <br/>- review the list of current term reports made for the student and the '{appContext.subject.label}' subject by clicking on the corresponding checkmark
          </Typography>
        <br/>
        {appContext.subject.rememberSelections && <Typography variant="h6" sx={{marginLeft: "20px", marginRight: "20px"}}>
          For {appContext.subject.label} we will remember your expectation selections between students.
          This will allow you to complete reports for students sharing a similar set of expectations more easily.
          To clear all expectations, you can either select "Clear All Selections" on the next page, or you can return
          to the subject list and select {appContext.subject.label} again.
        </Typography>}
        <br/>

        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", paddingBottom: "8px"}}>
          <ToggleButtonGroup
            color="primary"
            value={mode}
            exclusive
            onChange={changeMode}
            aria-label="Platform"
          >
            <ToggleButton size="small" value='classOrder'>Class Order</ToggleButton>
            <ToggleButton size="small" value='sorted'>Sorted</ToggleButton>
          </ToggleButtonGroup>
          {classOrderModified && <Button variant="outlined" size="small" onClick={saveClassOrder}>Save Class Order</Button>}
          <Button variant="outlined" size="small" endIcon={<AddIcon />} onClick={addStudent}>Add Student</Button>
        </div>

        <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow hover>
                  <StyledTableCell>Student Name</StyledTableCell>
                  <StyledTableCell align="center">Gender</StyledTableCell>
                  {appContext.classroom.curriculum.reportPeriods.map((reportPeriod) => (
                    <StyledTableCell key={reportPeriod.name} align="center">{reportPeriod.name}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
              {appContext.classroom.students && appContext.classroom.students.map((student, index) => (
                <StyledTableRow
                  key={student._id}
                  hover
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => selectStudent(student)}
                >
                  <StyledTableCell component="th" scope="row">
                    <div style={{display: "flex", alignItems: "center"}}>
                      {(appContext.classroom.mode !== "sorted") && 
                        <div style={{display: "flex"}}>
                          <IconButton color="primary" sx={{padding: 0, margin: 0}} onClick={async (event) => changeClassOrder(event, student._id, -1)}>
                            <ArrowUpIcon fontSize="large"/>
                          </IconButton>
                          <IconButton color="primary" sx={{padding: 0, marginRight: "10px"}} onClick={async (event) => changeClassOrder(event, student._id, 1)}>
                            <ArrowDownIcon fontSize="large"/>
                          </IconButton>
                        </div>
                      }
                      {student.name} {student.nickname ? ("(" + student.nickname + ")") : ''}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="center">{student.gender}</StyledTableCell>
                  {appContext.classroom.curriculum.reportPeriods.map((reportPeriod) => (
                    <StyledTableCell key={reportPeriod.name} align="center">{getStudentReport(student, reportPeriod.name) && <CheckIcon onClick={(event) => reviewComment(event, student, reportPeriod.name)}/>}</StyledTableCell>
                  ))}
                  </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> 
      </div>

      {outOfCredits && <ConfirmDialog title="Out of Comment Credits" prompt="Press Continue to purchase additional comment credits" onCancel={() => setOutOfCredits(false)} onConfirm ={handleCreditPurchase}/>}
      <AlertDialog message={message} title="Comment no longer available" onClose={() => setMessage(undefined)}/>

    </PageWrapper>
  );
}