import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../components/AppContext";
import PageWrapper from "../components/PageWrapper";
import AlertDialog from "../components/AlertDialog";
import { ButtonWrapper, SmallText } from "../globalStyles";
import newComment from "../helpers/httpRequests/newComment";
import generateLabel from "../helpers/generateLabel";
import getNickname from "../helpers/getNickname";
import assignString from "../helpers/assignString"
import Alert from "@mui/material/Alert";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RedoIcon from '@mui/icons-material/Redo';
import SwapIcon from '@mui/icons-material/SwapHoriz';
import addReport from "../helpers/httpRequests/addReport";
import getMarkIndex from "../helpers/getMarkIndex";
import getPronouns from "../helpers/getPronouns";
import replaceAll from "../helpers/replaceAll";
import Constants from "../constants";
import reportAssistantAtWork from "../images/reportAssistantAtWork.gif";
import { CopyToClipboard } from "react-copy-to-clipboard";


export default function GetComment() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  // Establish student name to use.
  const studentName = getNickname(appContext.student);
  
  // Group the expectations together
  let grouping = undefined;

  // Setup state
  const [comment, setComment] = useState(undefined);
  const [commentError, setCommentError] = useState(undefined);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [previousComment, setPreviousComment] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [message, setMessage] = useState(undefined);
  const [sending, setSending] = useState(undefined);
  const [accepting, setAccepting] = useState(undefined);
  const [tryAgainInstructions, setTryAgainInstructions] = useState("");
  const [tryAgainInstructionsError, setTryAgainInstructionsError] = useState(undefined);
  const [reportTenseToggled, setReportTenseToggled] = useState(false);



  //====================================================================
  // Generate a new comment if none available
  //====================================================================
  useEffect( () => {
    async function fetchData() {

      // Start by grouping all the related expectations/next steps together
      grouping = getGrouping();

      // Reset the chat messages chain with the initial query
      appContext.messages = [
        { role: "system", content: "You are a grade school teacher writing a student report card comment" },
        { role: "user",   content:  buildQuery() }
      ]

      // Generate the comment
      await generateComment();
    }
    fetchData();
  }, []);


  //============================================================================
  // We group the expectations and next steps for the subject as follows:
  // unmarked - expectations that have been flagged as unmarked
  // marked - collections of expectations based on mark 
  //============================================================================
  const getGrouping = () => {

    let grouping = {
      unmarked: [],
      strengths: [],
      weaknesses: [],
      nextSteps: []
    }

    for (const strand of appContext.subject.strands) {

      // If the strand is marked, get the index to the mark as well as the appropriate qualifiers for that mark
      let isStrength = undefined;
      let markIndex = undefined;
      let qualifiers = undefined;
      if (strand.isMarked && strand.mark) {
        markIndex = getMarkIndex(appContext, strand.mark);
        isStrength = (markIndex >= 2);
        if (appContext.subject.qualifiers && appContext.subject.qualifiers.length > 0) {
          qualifiers = appContext.subject.qualifiers[markIndex].split(",");
        }
      }

      for (const group of strand.groups) {
        if (group.expectations) {
          for (const expectation of group.expectations) {
            if (expectation.isSelected) {

              // Generate the base expectation label (replacing gender and mark qualifiers)
              let expectationLabel = generateLabel(appContext, expectation.label, markIndex);

              // If strand isn't marked, add it to the unmarked expectations
              if (!strand.isMarked || !strand.mark) {
                grouping.unmarked.push(expectationLabel);
              } else {

                // If the marked expectation needs to be qualified, do so now
                if (!expectation.marks && qualifiers) {

                  // Get a random qualifier for this expectation based on mark
                  const rnd = Math.floor(Math.random() * qualifiers.length);
                  const qualifier = qualifiers[rnd].trim();

                  // Qualify the expectation with the random qualifier
                  expectationLabel = qualifier + " " + expectationLabel;
                }

                // Add the expectation based on whether its a strength or weakness
                if (isStrength) {
                  grouping.strengths.push(expectationLabel);
                } else {
                  grouping.weaknesses.push(expectationLabel);
                }
              }
            }
          }
        }
        if (group.nextSteps) {
          for (const nextStep of group.nextSteps) {
            if (nextStep.isSelected) {
              grouping.nextSteps.push(generateLabel(appContext, nextStep.label, markIndex));
            }
          }
        }
      }
    }

    return grouping;
  }


  //============================================================================
  // Handle changes to the try again instructions
  //============================================================================
  const handleTryAgainInstructions = (event) => {
    event.preventDefault();
    assignString("Try again instructions", event.target.value, Constants.MAX_INSTRUCTIONS_SIZE, setTryAgainInstructions, setTryAgainInstructionsError);
  }


  //============================================================================
  // Handle request to retry comment
  //============================================================================
  const handleTryAgain = async (event) => {
    event.preventDefault();
    const instructions = "Reword the paragraph according to the following: " + tryAgainInstructions;
    appContext.messages.push({ role: "user", content: instructions});
    await generateComment();
    setTryAgainInstructions("");
  }


  //============================================================================
  // Return the opposite of the current report tense
  //============================================================================
  const toggledReportTense = () => {
    return (appContext.subject.reportTense === "present") ? "past" : "present";
  }


  //============================================================================
  // Handle request to use other report tense
  //============================================================================
  const handleToggleReportTense = async (event) => {
    event.preventDefault();

    // Toggle the report tense
    appContext.messages.push({ role: "user", content: "Rewrite the paragraph in the " + toggledReportTense() + " tense"});

    await generateComment();
    setReportTenseToggled(true)
  }


  //============================================================================
  // Swap back to prevoius comment
  //============================================================================
  async function generateComment() {
    if (!sending) {
        
      setSending(true);

      // Make the query to reportAssistant and handle any error
      const result = await newComment(appContext);
      if (!result.error) {

        // Because we are looking for a single paragraph, replace line breaks with spaces
        result.comment = replaceAll(result.comment, "\n\n", "  ");

        // Update our message chain with the answer from the assistant
        appContext.messages.push({ role: "assistant", content: result.comment });

        // Update subscription data if its changed
        if (result.subscriptionData) {
          appContext.educator.subscriptionData = result.subscriptionData;
        }

        // Record usage, and increment # of attempts
        appContext.usage = result.usage;
        appContext.student.commentAttempts = appContext.student.commentAttempts + 1;
      
        // Build the full comment from the answer we received and include the "iep" prefix is present.
        let fullComment = replaceAll(result.comment, "Zaza", studentName);
        if (appContext.student.acceptIEP) {
          fullComment = appContext.student.iepComment + " " + fullComment;
        }
        
        setPreviousComment(comment);
        assignString("Comment", fullComment, Constants.MAX_COMMENT_SIZE, setComment, setCommentError);

      } else {
        setError(result.error);
      }	

      setSending(false);
    }
  }


  //============================================================================
  // Swap back to prevoius comment
  //============================================================================
  const swapComment = async () => {
    const currentComment = comment;
    setComment(previousComment)
    setPreviousComment(currentComment);
    setCommentError(undefined);
  }


	//=========================================================================
	// Build reportAssistantQuery query from info and prompts providedn
	//=========================================================================
	const buildQuery = () => {

    let query = "Please write a grammatically correct " + (appContext.subject.isFrench ? "French " : "") +
                "paragraph in the " + appContext.subject.reportTense + " tense and third person about a " +
                "student named Zaza using pronouns " + getPronouns(appContext) + ".  Avoid excessive repetition of the " +
                "student's name. The paragraph should be limited to " + appContext.subject.maxCharacterCount +
                 " characters and avoid sentences with multiple clauses. ";

    // If an educator comment was flagged
    if (appContext.student.educatorComment && (appContext.student.educatorCommentPlacement === "beginning")) {
      query += "\nStart the paragraph with this observation: " + appContext.student.educatorComment;
    }

    // Include any unmarked observations about the student
    if (grouping.unmarked.length > 0) {
      query += "\nUse the following general observations:";
      for (const expectationLabel of grouping.unmarked) {
        query += "\n- " + expectationLabel + ".";
      }
    }

    // Include any strength comments
    if (grouping.strengths.length > 0) {
      query += "\nInclude the following strengths:";
      for (const expectationLabel of grouping.strengths) {
        query += "\n- " + expectationLabel + ".";
      }
    }

    if (appContext.student.educatorComment && (appContext.student.educatorCommentPlacement === "embed")) {
      query += "\nEmbed the following observation in the paragraph: " + appContext.student.educatorComment;
    }

    // Include any weakness comments
    if (grouping.weaknesses.length > 0) {
      query += "\nInclude the following weaknesses";
      for (const expectationLabel of grouping.weaknesses) {
        query += "\n- " + expectationLabel + ".";
      }
    }

    if (appContext.student.educatorComment && (appContext.student.educatorCommentPlacement === "end")) {
      query += "\nInclude an observation about: " + appContext.student.educatorComment;
    }

    // Include any identified next steps
    if (grouping.nextSteps.length > 0) {
      query += "\nFinally, use the present tense to close the paragraph with the following next steps that Zaza should take:";
      for (const nextStepLabel of grouping.nextSteps) {
        query += "\n- " + nextStepLabel + ".";
      }
    }

    //console.log("Query: " + query);
		return query;
	}


  //============================================================================
  // Clear the query context
  //============================================================================
  function clearQueryContext() {
    setComment(undefined);
    setCommentError(undefined)
  }


	//====================================================================
	// Handle change to comment
	//====================================================================
  const handleCommentChange = (event) => {
		event.preventDefault();
    setCopiedToClipboard(false);
		assignString("Comment", event.target.value, Constants.MAX_COMMENT_SIZE, setComment, setCommentError);
	};


  //============================================================================
  // Handle request to generate new comment
  //============================================================================
  const handleBack = async () => {
    clearQueryContext();
    appContext.student.commentAttempts = 0;
    navigate(-1);
  }


  //============================================================================
  // Handle request to generate new comment
  //============================================================================
  const handleCopyToClipboard = async () => {
    setCopiedToClipboard(true);
  }


  //============================================================================
  // Handle request for accepting the comment.   Once we return to subject,
  // we should clear the context for the current subject and query
  //============================================================================
  const acceptComment = async () => {
    if (!accepting) {
      setAccepting(true);

      clearQueryContext();

      // Save the report for the current student
      await addReport(appContext, comment);

      // Display message
      setMessage("Your comment will be saved until the end of the current school year (June 30th, 2025), and will be available from the student summary.  To access the comment, click on the checkmark next to the subject for the corresponding report period.")

      setAccepting(false);
    }
  }

	// Does "try again instructions" contain student's name
	const containsStudentName = tryAgainInstructions && 
								((tryAgainInstructions.indexOf(getNickname(appContext.student) + " ") >= 0) ||
								 (tryAgainInstructions.indexOf(" " + getNickname(appContext.student)) >= 0));


  return(

    <PageWrapper 
      title={"Comment for " + appContext.student.name}
      leftButton="Back"
			leftIcon={<ArrowBackIcon />}
			onLeftClick={handleBack}
      leftDisabled={sending}
			rightButton="Accept Comment"
			rightIcon={<ArrowForwardIcon />}
			onRightClick={acceptComment}
      rightDisabled={sending || accepting || !comment}
      showSubject
      showComments
    >

      <div style={{backgroundColor: "#ffffff"}}>
        <br/>
        <div style={{padding: "0px 20px"}}>
        
          {comment && !sending &&
            <div>
              <Typography variant="h6">You can edit directly in this box before copying to your report card, but any edits will not be included if you regenerate the comment.</Typography>
              <br/>
              <Typography variant="h6">Once you are happy with the comment, please select "Accept Comment" from the top right corner.   After accepting the comment, you can review the comment until the end of the current term by clicking on the corresponding checkmark in the student summary.</Typography>
              <br/>
              <br/>
              <SmallText>Comment length: {comment.length}</SmallText>
              <CopyToClipboard text={comment} onCopy={handleCopyToClipboard}>
                <div style={{display: "flex", justifyContent: "end", marginBottom: "5px"}}>
                  <Button variant="outlined" endIcon={<ContentCopyIcon />} size="small" disabled={copiedToClipboard || !comment || (comment.length === 0)}>Copy to clipboard</Button>
                </div>
              </CopyToClipboard>
              <TextField 
                error={commentError !== undefined}
                fullWidth 
                multiline 
                label="Your Comment" 
                id="fullWidth" 
                name="comment" 
                value={comment} 
                onChange={handleCommentChange} 
                helperText={commentError}
              />
            </div>
          }

          {comment && !sending && 
            <div>
              <br/>
              <ButtonWrapper>
                {!reportTenseToggled && <Button variant="outlined" startIcon={<RedoIcon />} onClick={handleToggleReportTense}>Redo with {toggledReportTense()} tense </Button>}
                {previousComment && <Button variant="outlined" startIcon={<SwapIcon />} onClick={swapComment}>Swap with previous comment</Button>}
              </ButtonWrapper>
            </div>
          }

        </div>
      </div>

      {sending && 
        <div>
          <Alert severity="info" >
              Please be patient, your personal Report Assistant is busy writing.  This could take a minute or two ...
          </Alert>
          <div style={{display: "flex", justifyContent: "center"}}>
              <img src={reportAssistantAtWork} width="400px" style={{marginTop: "30px"}}/>
          </div>
        </div>
      }

      <AlertDialog title="Comment Error" message={error} severity="error" onClose={() => setError(undefined)}/>
      <AlertDialog message={message} title="Comment Saved" onClose={() => navigate("/selectStudent", {replace: true})}/>

      {!sending && !message &&
        <div style={{padding: "0px 20px"}}>
          <br/>
          {(appContext.student.commentAttempts < Constants.MAX_COMMENT_ATTEMPTS) && 
            <div>
              <br/>
              <TextField sx={{ width: "100%" }}
                error={tryAgainInstructionsError !== undefined}
                multiline
                name="tryAgainInstructions"
                value={tryAgainInstructions}
                placeholder="Try again by providing us with additional instructions to perfect your comment"
                onChange={handleTryAgainInstructions}
                helperText={tryAgainInstructionsError}
              />
              {containsStudentName && 
                <Alert severity="warning" sx={{marginTop: "10px"}}>
                    Please try and AVOID the use of the student name in your try again instructions
                </Alert>
              }
              <br/><br/>
              <ButtonWrapper>
                <Button variant="outlined" disabled={!tryAgainInstructions} endIcon={<RedoIcon />} onClick={async (event) => await handleTryAgain(event)}>Try Again Using Instructions</Button>
              </ButtonWrapper>
            </div>
          }
        </div>}

    </PageWrapper>
  );
}