//====================================================================
// Get the collection of currently selected keys for each
// expectation and next steps
//====================================================================
export default function getReportKeys(appContext) {
    let reportKeys = "";

    // First gather all next steps selected from strands
    for (const strand of appContext.subject.strands) {
        for (const group of strand.groups) {
            let groupKey = strand.key + ":" + group.key + ":"
            if (group.expectations) {
                for (const expectation of group.expectations) {
                    if (expectation.isSelected) {
                        const key =  groupKey + expectation.key;
                        reportKeys += (reportKeys.length > 0) ? "," + key : key;
                    }
                }                
            }
            if (group.nextSteps) {
                for (const nextStep of group.nextSteps) {
                    if (nextStep.isSelected) {
                        const key = groupKey + nextStep.key;
                        reportKeys += (reportKeys.length > 0) ? "," + key : key;
                    }
                }
            }
        }
    }

    return reportKeys;
}