import { useContext } from "react";
import {useNavigate} from "react-router-dom";
import PageWrapper from "../components/PageWrapper";
import { TitleText, ButtonWrapper } from '../globalStyles';
import Button from '@mui/material/Button';
import AppContext from "../components/AppContext";

export default function ErrorPage({error}) {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  function gotoLogin(event) {
    event.preventDefault();
    appContext.educator = undefined;
    navigate("/login");
  }

  return (
    <PageWrapper>
      <TitleText>UNEXPECTED Error Occurred, please contact us</TitleText>
      <br/><br/>
        <TitleText>Please provide us with the following information</TitleText>
        <div style={{margin: "40px"}}>
          <br/><br/>
          <p>{error.stack}</p>
        </div>
      <br/><br/>
      <ButtonWrapper>
        <Button variant="outlined" onClick={gotoLogin}>Go to Login</Button>
      </ButtonWrapper>
    </PageWrapper>
  );
}