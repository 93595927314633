import React, { useState, useContext, useEffect } from "react";
import AppContext from "../components/AppContext";
import AlertDialog from "../components/AlertDialog";
import { MyExpectationCard, MyExpectationTitle} from "../globalStyles";
import generateLabel from "../helpers/generateLabel";
import getExpectationReportTags from "../helpers/getExpectationReportTags";
import getMarkIndex from "../helpers/getMarkIndex";
import { Checkbox } from '@mui/material';

export default function ExpectationCard({strand, group, expectation, onClick}) {
  const appContext = useContext(AppContext);

	// Setup state for info being collected
  const [isSelected, setIsSelected] = useState( expectation.isSelected ?? false );
  const [error, setError] = useState(undefined);

  useEffect(() => { 
    setIsSelected(expectation.isSelected ?? false);
    setError(undefined);
  }, [appContext.subject.mark, expectation.isSelected]);


  function handleClick() {

    // Don't allow click if strand requires marking and its not marked
    if (strand && (strand.isMarked !== false) && !strand.mark) {
      setError("You must first assign a mark to the strand");
    } else {
      if (expectation.isSelected) {
        expectation.isSelected = false;
      } else {
        expectation.isSelected = true;
      }
      setIsSelected(expectation.isSelected);

      if (onClick) {
        onClick(expectation.isSelected);
      }
    }
  }

  const reportTags = getExpectationReportTags(appContext, strand, group, expectation);

  return (
    <MyExpectationCard className={isSelected ? 'active' : undefined} onClick={handleClick}>
      <Checkbox checked={isSelected} value={isSelected} sx={{ color: "black", "&.Mui-checked": { color: "white" }}} inputProps={{ 'aria-label': 'controlled' }} />
      <MyExpectationTitle>{generateLabel(appContext, expectation.label, getMarkIndex(appContext, strand.mark))}</MyExpectationTitle>
      {reportTags && 
        <div style={{display: "flex"}}>
          {reportTags.map((reportTag, index) => (
            <div key={index} style={{alignText: "center", backgroundColor: "green", color: "white", margin: "2px", padding: "2px"}}>{reportTag}</div>
          ))}
        </div>
      }
      
      <AlertDialog title="Expectation Error" message={error} severity="error" onClose={() => setError(undefined)}/>

    </MyExpectationCard>
  );
}