import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import getNickname from "../helpers/getNickname";
import AppContext from "../components/AppContext";
import PageWrapper from "../components/PageWrapper";
import {
  TextField,
  Typography,
  Button
} from '@mui/material';
import { ButtonWrapper } from "../globalStyles";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SkipNextIcon from '@mui/icons-material/SkipNext';


export default function IEPComment() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  appContext.student.acceptIEP = false;

  // Create the default IEP comment for the subject and grade
  const defaultIEPComment = "The mark for " + appContext.subject.reportLabel + " is based on the " +
                            "achievement of the expectations in the IEP, which may vary from the Grade " + 
                            appContext.classroom.grade + " expectations.";

  // Setup state for trying again
	const [iepComment, setIEPComment] = useState(appContext.student.iepComment ?? defaultIEPComment);
  const [commentError, setCommentError] = useState(undefined);


  //====================================================================
  // Handle change to comment
  //====================================================================
  const handleCommentChange = (event) => {
    event.preventDefault();
    appContext.student.iepComment = event.target.value;
    if (appContext.student.iepComment.length > 300) {
      setCommentError("Comment limited to 300 characters")
      appContext.student.iepComment = appContext.student.iepComment.substring(0,300);
    } else {
      setCommentError(undefined);
    }
    setIEPComment(appContext.student.iepComment);
  };


  //====================================================================
  // Handle change to comment
  //====================================================================
  const handleAccept = (event) => {
    event.preventDefault();
    appContext.student.acceptIEP = true;
    appContext.student.iepComment = iepComment;
    navigate("/selectExpectations");
  };


  return (
    <PageWrapper 
      title={"Optional IEP Comment for " + getNickname(appContext.student)}
      leftButton="Back"
      leftIcon={<ArrowBackIcon />}
      onLeftClick={() => navigate(-1)}
      rightButton="Skip"
			rightIcon={<SkipNextIcon />}
			onRightClick={() => navigate("/selectExpectations")}
      showSubject
    >
        <div style={{margin: "0px 10px 0px 10px"}}>
          <Typography variant="h6" sx={{marginTop: "30px", marginLeft: "20px", marginRight: "20px", marginBottom: 0}}>
            Please review the {appContext.subject.reportLabel} IEP comment for {getNickname(appContext.student)}.
          </Typography>
          <Typography variant="h6" sx={{marginTop: "5px", marginLeft: "20px", marginRight: "20px", marginBottom: 0}}>
            You can edit the comment, and when you are done, either accept the comment, OR press skip to exclude it.
          </Typography>
          <div style={{marginTop: "40px", marginLeft: "20px", marginRight: "20px"}}>
            <TextField
              required
              error={commentError !== undefined}
              fullWidth 
              multiline 
              name="iepComment"
              label={ "Your " + appContext.subject.reportLabel + " IEP Comment for " + appContext.student.name}
              value={iepComment}
              placeholder="IEP Comment"
              onChange={handleCommentChange}
              helperText={commentError}
              variant="outlined"
            />
          </div>
          <br/>
          <ButtonWrapper>
            <Button variant="outlined" disabled={iepComment.trim().length === 0} onClick={handleAccept}>Accept IEP Comment for {appContext.student.name}</Button>
          </ButtonWrapper>
        </div>

    </PageWrapper>
  );
}