import httpPut from "./httpPut";
import Constants from "../../constants";


//=============================================================================
// Update the classroom's current report period
//=============================================================================
export default async function updateClassroomMode(appContext, mode) {
    const url = Constants.REQ_UPDATE_CLASSROOM_MODE.replace("<id>", appContext.classroom._id);

    // Update mode in current classroom context
    appContext.classroom.mode = mode;

    // Update classroom mode on server
    return await httpPut(url, {mode});
}