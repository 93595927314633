import React from 'react';
import {useNavigate} from "react-router-dom";
import { Grid, Typography, Button, Box } from '@mui/material';
import aboutUsImage from '../images/aboutUs.jpg';

const AboutUs = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        minHeight: '400px',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '30px 0px 50px 0px'
      }}
    >
      <Grid container spacing={6} 
        sx={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '1300px',
          padding: '50px'
        }}
      >
        <Grid item xs={12} md={5}>
          <img src={aboutUsImage} alt="About Us" style={{width: '100%'}} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h3" fontWeight={700} sx={{paddingBottom: '15px'}}>
            Built by Teachers, for Teachers...
          </Typography>
          <Typography 
            sx={{
              opacity: '0.7',
              paddingBottom: '30px',
              fontSize: '18px'        
            }}
          >
            We are thrilled to unveil <b>myReportAssistant</b>, a groundbreaking service that leverages the latest advancements in machine learning to make report card season just a little easier.
            <br/><br/><b>MyReportAssistant</b> is a collaboration between a sister and brother team, a teacher and technology consultant, working with each other's strengths to bring you products that we believe can streamline and improve the Teacher experience.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: '200px', fontSize: '16px' }}
            onClick={() => navigate("/contactUs")}
          >
            CONTACT US
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;