import httpPost from "./httpPost";
import Constants from "../../constants";

//=============================================================================
// Retrieve definition for a specific subject
//=============================================================================
export default async function updateSubject(subject) {
    const url = Constants.REQ_UPDATE_SUBJECT.replace("<name>", subject.name);
    const body = { subject }
    return await httpPost(url, body);
}