import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';


export default function TutorialCard({tutorial, onTutorialClick}) {

    return (
        <Card sx={{ width: "100%", backgroundColor: "#F0F0F0" }}>
            <CardActionArea onClick={onTutorialClick}>
                <div style={{display: 'flex', flexWrap: "wrap", justifyContent: 'space-between'}}>
                    <div style={{display: 'flex'}}>
                        <CardMedia
                            component="img"
                            sx={{ width: "160px", height: "160px", padding: "5px" }}
                            image={require("../images/tutorials/" + tutorial.name + ".png")} 
                            alt={tutorial.name}
                        />
                        <Box sx={{ display: 'flex', width: "100%", flexDirection: 'column', alignItems: "left" }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="h5">
                                    {tutorial.title}
                                </Typography>
                                <Typography component="div" variant="h6">
                                    {tutorial.description}
                                </Typography>
                            </CardContent>
                        </Box>
                    </div>
                </div>

            </CardActionArea>
        </Card>
    );
}