import React, {} from "react";
import { Button, Paper, TableContainer, Table, TableRow, TableHead, TableBody } from '@mui/material';
import {StyledTableRow, StyledTableCell} from "./StyledTable";
import AddIcon from '@mui/icons-material/Add';

export default function LabelList({listName, list, editable=true, nameLabel=false}) { 

    function addItem() {  
        if (editable) {  
        }
    }

    function editItem(item) {  
        if (editable) {
        }
    }

    return (
        <div style={{margin: 0}}>
            {editable && <div style={{display: "flex", justifyContent: "end", alignItems: "center", paddingBottom: "8px"}}>
                <Button variant="outlined" endIcon={<AddIcon />} onClick={addItem}>Add {listName}</Button>
            </div>}
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                <TableHead>
                    <TableRow hover>
                        <StyledTableCell>{listName}s</StyledTableCell>
                        {nameLabel && <StyledTableCell align="right">Short Names</StyledTableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((item, index) => (
                    <React.Fragment key={index}>
                        <StyledTableRow
                            hover
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            onClick={() => editItem(item)}
                        >
                            {!nameLabel && <StyledTableCell component="th" scope="row">{item}</StyledTableCell>}
                            {nameLabel && <StyledTableCell component="th" scope="row">{item.label}</StyledTableCell>}
                            {nameLabel && <StyledTableCell align="right">{item.name}</StyledTableCell>}
                        </StyledTableRow>
                    </React.Fragment>
                    ))}
                </TableBody>
                </Table>
            </TableContainer> 
        </div>
    );
}