import React, { useContext } from "react";
import AppContext from "../components/AppContext";
import { SummaryContainer, SummaryCard, MyStyledHeader, MarkLineItem, TitleText } from "../globalStyles";
import getMarkingLabel from "../helpers/getMarkingLabel";
import strandHasSelectedExpectations from "../helpers/strandHasSelectedExpectations";
import getStrandSelectedExpectations from "../helpers/getStrandSelectedExpectations";
import getSubjectSelectedNextSteps from "../helpers/getSubjectSelectedNextSteps";
import generateLabel from "../helpers/generateLabel";
import getMarkIndex from "../helpers/getMarkIndex";
import getNickname from "../helpers/getNickname";
import Typography from '@mui/material/Typography';



export default function MarkSummary() {
    const appContext = useContext(AppContext);


    //====================================================================
    // Filter strands with one of the specified mark indices
    //====================================================================
    function splitStrandsByStrength() {

        let splitStrands = {
            weak: [],
            strong: []
        }

        for (const strand of appContext.subject.strands) {
            if ((strand.isMarked !== false) && strand.mark) {
                const index = appContext.subject.markingScheme.indexOf(strand.mark);
                if (index < 2) {
                    splitStrands.weak.push(strand);
                } else {
                    splitStrands.strong.push(strand);
                }
            }
        }
        return splitStrands;
    }



    //====================================================================
    // Are there any marks available in this subject
    //====================================================================
    function strandHasMarks() {
        let hasMarks = (appContext.subject.mark !== undefined);
        if (!hasMarks) {
            for (const strand of appContext.subject.strands) {
                if (strand.mark !== undefined) {
                    hasMarks = true;
                    break;
                }
            }
        }
        return hasMarks;
    }


    // Split subject's strength and weakness strands
    const splitStrands = splitStrandsByStrength();
    const nextSteps = getSubjectSelectedNextSteps(appContext.subject);
    const studentName = getNickname(appContext.student);


    // Get the selected anotations from the 
    return (
        <SummaryContainer>

            
            {strandHasMarks() && 
                <SummaryCard>
                    <MyStyledHeader>
                        {!appContext.subject.mark &&
                            <TitleText style={{margin: "5px 0px 5px 0px"}}>{appContext.subject.label} {getMarkingLabel(appContext)}</TitleText>
                        }
                        {appContext.subject.mark &&
                            <TitleText style={{margin: "5px 0px 5px 0px"}}>{appContext.subject.label} - {getMarkingLabel(appContext)}: {appContext.subject.mark}</TitleText>
                        }
                    </MyStyledHeader>

                    <div style={{padding: "10px"}}>
                        {appContext.subject.strands.map((strand, index) => (
                            <div key={index}>
                                {strand.mark &&
                                    <MarkLineItem key={index}>
                                        <div style={{marginRight: "10px"}}>{strand.label}</div>
                                        <div>{strand.mark}</div>
                                    </MarkLineItem>
                                }
                            </div>
                        ))}
                    </div>
                </SummaryCard>
            }

            {appContext.student.acceptIEP &&
                <SummaryCard style={{width: "100%"}}>
                    <MyStyledHeader>
                        <TitleText style={{margin: "5px 0px 5px 0px"}}>{appContext.subject.reportLabel} IEP Comment</TitleText>
                    </MyStyledHeader> 

                    <div style={{padding: "10px"}}>
                        <Typography variant="h7">{appContext.student.iepComment}</Typography>
                    </div>
                </SummaryCard>
            }

            {appContext.subject.strands.map((strand, index) => (
                <div key={index}>
                    {(strand.isMarked === false) && !strand.mark && strandHasSelectedExpectations(strand) &&
                        <SummaryCard key={index} style={{width: "100%"}}>
                            <MyStyledHeader>
                                <TitleText style={{margin: "5px 0px 5px 0px"}}>{strand.label}</TitleText>
                            </MyStyledHeader> 

                            <div style={{padding: "10px"}}>
                                {getStrandSelectedExpectations(strand).map((expectation, index) => (
                                    <div key={index}>
                                        <div>
                                            <Typography variant="h7">- {generateLabel(appContext, expectation.label)}</Typography>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </SummaryCard>
                    }
                </div>
            ))}
                
            {appContext.student.educatorComment &&
                <div style={{width: "100%"}}>
                    <br/><br/>
                    {(appContext.student.educatorCommentPlacement === "embed") &&
                        <Typography variant="string" color={"text.secondary"} component="div">
                            The following teacher comment will be EMBEDDED into the generated comment.
                        </Typography>
                    }
                    {(appContext.student.educatorCommentPlacement !== "embed") &&
                        <Typography variant="string" color={"text.secondary"} component="div">
                            The following teacher comment will be placed at the {appContext.student.educatorCommentPlacement.toUpperCase()} of the generated comment.
                        </Typography>
                    }
                    <br/>
                    <Typography variant="h6" color={"text.primary"} component="div">
                        "{appContext.student.educatorComment}"
                    </Typography>
                    <br/>
                </div>
            }
            {(splitStrands.strong.length > 0) &&
                <SummaryCard style={{width: "100%"}}>
                    <MyStyledHeader>
                        <TitleText style={{margin: "5px 0px 5px 0px"}}>Selected Strengths</TitleText>
                    </MyStyledHeader>

                    <div style={{padding: "10px"}}>
                        {splitStrands.strong.map((strand, index) => (
                            <div key={index}>
                                {getStrandSelectedExpectations(strand).map((expectation, index) => (
                                    <div key={index}>
                                        <div>
                                            <Typography variant="h7">- {generateLabel(appContext, expectation.label, getMarkIndex(appContext, strand.mark))}</Typography>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>

                </SummaryCard>
            }

            {(splitStrands.weak.length > 0) &&
                <SummaryCard style={{width: "100%"}}>
                    <MyStyledHeader>
                        <TitleText style={{margin: "5px 0px 5px 0px"}}>Selected Weaknesses</TitleText>
                    </MyStyledHeader>

                    <div style={{padding: "10px"}}>
                        {splitStrands.weak.map((strand, index) => (
                            <div key={index}>
                                {getStrandSelectedExpectations(strand).map((expectation, index) => (
                                    <div key={index}>
                                        {expectation.isSelected &&
                                            <div>
                                                <p>- {generateLabel(appContext, expectation.label, getMarkIndex(appContext, strand.mark))}</p>
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>

                </SummaryCard>
            }

            {(nextSteps.length > 0) &&
                <SummaryCard style={{width: "100%"}}>
                    <MyStyledHeader>
                        <TitleText style={{margin: "5px 0px 5px 0px"}}>Next Steps for {studentName}</TitleText>
                    </MyStyledHeader>

                    <div style={{padding: "20px"}}>
                        {nextSteps.map((nextStep, index) => (
                            <div key={index}>
                                <Typography variant="h7">- {generateLabel(appContext, nextStep.label)}</Typography>
                            </div>
                        ))}
                    </div>
                </SummaryCard>
            }

        </SummaryContainer>
    );
}

/*

            */