import React from 'react';
import { Typography, Box, Card, CardContent, Grid } from '@mui/material';
import { Avatar } from '@mui/material';

export default function Testimonials() {
  const reviews = [
    {
      id: 1,
      name: 'Cindy',
      statement:
        'I was able to complete my reports in less than half the time it normally takes me.  All my comments were accepted by the administration without any corrections needed.',
      image_url:
        'https://sweta-myteam-website-fm.netlify.app/static/media/avatar-kady.78fc482c.jpg',
      position: 'Grade 3 Teacher',
    },
    {
      id: 2,
      name: 'Jessica',
      statement:
        'As a new teacher, I was particularly impressed with the bank of ready made expectations and next steps for my Kindergarten class.  I was also impressed with how it was able to rewrite my specific comments about a student.',
      image_url:
        'https://sweta-myteam-website-fm.netlify.app/static/media/avatar-aiysha.e119a0c1.jpg',
      position: 'Kindergarten Teacher',
    },
    {
      id: 3,
      name: 'Ben',
      statement:
        'MyReportAssistant allowed me to easily create my own expectations, and flipping back and forth between expectations for my grade 7/8 class was a breeze.  I also liked how it allowed me to tell it how to reword the comment to focus on a particular area.',
      image_url:
        'https://sweta-myteam-website-fm.netlify.app/static/media/avatar-arthur.098c2e26.jpg',
      position: 'Intermediate Teacher',
    },
  ];
  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '300px',
      }}
    >
      <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '40px', marginBottom: '10px'}}>
        <Typography variant="h5" fontWeight={700} sx={{color: 'black'}}>
          Testimonials
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {reviews.map((review) => (
          <Grid item sm={12} md={4} key={review.id}>
            <Card sx={{
              backgroundColor: '#eee',
              padding: '10px',
              minHeight: '200px',
              display: 'flex',
              alignItems: 'center',
            }}>
              <CardContent>
                <Typography sx={{paddingBottom: '25px'}}>
                  "{review.statement}"
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Avatar
                    src={review.image_url}
                    size="large"
                    sx={{marginRight: '10px'}}
                  />
                  <Box>
                    <Typography>{review.name}</Typography>
                    <Typography sx={{fontSize: '14px', opacity: '0.6'}}>
                      {review.position}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};