import React, { useContext } from "react";
import {useNavigate} from "react-router-dom";
import AppContext from "../components/AppContext";
import PageWrapper from "../components/PageWrapper";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TextContainer } from "../globalStyles";


export default function TermsAndConditions() {
  const navigate = useNavigate();


  return (
    <PageWrapper
      title="Terms And Conditions"
      leftButton="Back"
      leftIcon={<ArrowBackIcon />}
      onLeftClick={() => navigate(-1)}
    >
          <TextContainer style={{marginLeft: "30px", marginRight: "30px"}}>

            <br/>
            <h1>TERMS AND CONDITIONS</h1>
            Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the MyReportAssistant website (the "Service") operated by Artimetix Software Inc. ("us", "we", or "our").
            <br/><br/>
            Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.

            <br/><br/>
            <h2>SERVICE</h2>
            The Service is a tool designed to assist teachers in generating report card comments. The Service is intended to support teachers in their task of writing report card comments, not to replace their professional judgment or responsibilities.

            <br/><br/>
            <h2>LICENSE</h2>
            We grant you a non-transferable, non-exclusive, revocable license to use the Service for your personal, non-commercial use only and as permitted by the features of the Service.

            <br/><br/>
            <h2>USE OF THE SERVICE</h2>
            By using the Service, you represent that you are a certified teacher and that you will use the Service only for its intended purpose. Your subscription to the Service is limited to your own personal use. Sharing your subscription with others, or using the Service to generate comments for other teachers, is strictly prohibited.   You also agree that you will only use the first name of students, and will not provide any additional identification"

            <br/><br/>
            <h2>RESPONSIBILITY</h2>
            While we strive to provide an accurate and helpful service, you understand and agree that the final responsibility for the accuracy, appropriateness, and legal compliance of all report card comments rests with you. You agree to review all generated comments and ensure their correctness and suitability before use.

            <br/><br/>
            <h2>TERM AND TERMINATION</h2>
            These Terms will remain in effect until terminated by you or us. We may terminate or suspend your access to the Service at any time, including in the event of your breach of these Terms, or non-payment of subscription fees.

            <br/><br/>
            <h2>INTELLECTUAL PROPERTY</h2>
            The Service and its original content, features and functionality are and will remain the exclusive property of Artimetix Software Inc. and its licensors. The Service is protected by copyright, trademark, and other laws of both the Ontario, Canada jurisdiction and foreign countries.

            <br/><br/>
            <h2>USER GENERATED CONTENT</h2>
            As part of using the Service, you may provide information, data, or other content ("User Content"). You remain the owner of all User Content that you provide to the Service, but you grant us a non-exclusive, worldwide, royalty-free right to use, copy, distribute, and display that User Content for the purpose of providing and improving the Service.
            <br/><br/>
            It is your responsibility to ensure that your User Content does not violate any copyright, trademark rights, or any other rights of any third party or any law. You represent and warrant that you have all necessary rights to provide the User Content to the Service and to grant us the rights in the User Content as provided in these Terms.
            <br/><br/>
            We are not responsible for any violations of intellectual property rights, copyright infringement, or any other legal issues that may arise from your use or misuse of the User Content. You agree to indemnify, defend, and hold harmless MyReportAssistant and Artimetix Software from and against all losses, expenses, damages, and costs, including reasonable attorneys' fees, resulting from any violation of these Terms or any activity related to your use of the Service (including negligent or wrongful conduct) by you or any other person accessing the Service using your account.

            <br/><br/>
            <h2>DISCLAIMER</h2>
            The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.

            <br/><br/>
            <h2>GOVERNING LAW</h2>
            These Terms shall be governed and construed in accordance with the laws of Ontario, without regard to its conflict of law provisions.

            <br/><br/>
            <h2>CHANGES</h2>
            We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.

            <br/><br/>
            <h2>CONTACT US</h2>
            If you have any questions about these Terms, please contact us at <b>myReportAssistant@gmail.com</b>.


          </TextContainer>

    </PageWrapper>
  );
}