import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import aiImage from '../images/ai.jpg';
import genderImage from '../images/gender.png';
import workSmarterImage from '../images/workSmarter.jpg';
import curriculumImage from '../images/curriculum.jpg';
import studentSpecificImage from '../images/studentSpecific.jpg';
import tryBeforeBuyImage from '../images/tryBeforeBuy.jpg';

export default function Features() {

  const sectionItems = [
    {
      id: 1,
      image: aiImage,
      sentence:
        'Our Report Assistant uses AI technology to put your learning skills, math, english, and other subject comments into cohesive paragraphs using past or present tense!',
    },
    {
      id: 2,
      image: genderImage,
      sentence:
        'Your comments will be reworded to use the correct pronouns for male, female, non-binary!',
    },
    {
      id: 3,
      image: workSmarterImage,
      sentence: 'Complete your report cards in a fraction of the time.  Use our predefined collection of expectations and next steps. Keep track of expectations used in previous reports.',
    },
    {
      id: 4,
      image: curriculumImage,
      sentence:
        "Customize the expectations from our default curriculum, or create your own.  You can include expectations specific to a student's grade and/or performance.",
    },
    {
      id: 5,
      image: studentSpecificImage,
      sentence:
        'Include comments that are specific to a student.  Our report assistant can even reword a specific comment to make sure it is more appropriate.',
    },
    {
      id: 6,
      image: tryBeforeBuyImage,
      sentence:
        'Once you register, you can generate up to 10 comments before you need to make a purchase.  No credit card required to give it a try.',
    },
  ];
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '40px'}}>
        <Typography variant="h5" fontWeight={700} sx={{color: 'black'}}>
          Features
        </Typography>
      </Box>
      <Grid 
        container 
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%'
        }}
      >
        {sectionItems.map((item) => (
          <Grid
            item
            xs={12}
            md={3.5}
            minHeight={300}
            key={item.id}
            sx={{
              color: 'white',
              backgroundColor: '#5094db',
              textAlign: 'center',
              padding: '30px',
              width: '200px',
              minHeight: "300px",
              borderRadius: '10px',
              border: '2px solid #1d609c',
              margin: '10px !important',
            }}
          >
            <img src={item.image} alt="Item" style={{maxWidth: '200px'}}/>
            <Typography>{item.sentence}</Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};