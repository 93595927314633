//============================================================================
// Manage pronouns to inject into query
//============================================================================
export default function getPronouns(appContext) {
    let pronouns = "he/him/his";
    if (appContext.student.gender === "female") {
        pronouns = "she/her";
    } else if (appContext.student.gender === "non-binary") {
        pronouns = "they/them";
    }
    return pronouns;
}