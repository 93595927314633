import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import GlobalStyle from './globalStyles';
import AppContext from "./components/AppContext";
import HomeLayout from "./components/HomeLayout";
import ProtectedLayout from "./components/ProtectedLayout";
import ScrollToTop from "./components/ScrollToTop";

// Pages
import Landing from "./pages/Landing";
import Overview from "./pages/Overview";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import LinkEmailPassword from "./pages/LinkEmailPassword";
import ForgotPassword from "./pages/ForgotPassword";
import ErrorPage from "./pages/ErrorPage";
import Curriculums from "./pages/Curriculums";
import EditCurriculum from "./pages/EditCurriculum";
import Subscriptions from "./pages/Subscriptions";
import Tutorials from "./pages/Tutorials";
import TutorialVideo from "./pages/TutorialVideo";
import Pricing from "./pages/Pricing";
import SelectSubject from "./pages/SelectSubject";
import EditSubject from "./pages/EditSubject";
import SelectClassroom from "./pages/SelectClassroom";
import EditClassroom from "./pages/EditClassroom";
import ManageStudents from "./pages/ManageStudents";
import StudentSubjectSummary from "./pages/StudentSubjectSummary";
import SelectStudent from "./pages/SelectStudent";
import EditStudent from "./pages/EditStudent";
import IEPComment from "./pages/IEPComment";
import SelectExpectations from "./pages/SelectExpectations";
import SpecificComment from "./pages/SpecificComment";
import SummaryPage from "./pages/SummaryPage";
import GetComment from "./pages/GetComment";
import ReviewComment from "./pages/ReviewComment";
import ContactUs from "./pages/ContactUs";
import AgreeToTerms from "./pages/AgreeToTerms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";

// Start off with an app context that is initially empty
const appContext = {}

function App() {
  return (
    <AppContext.Provider value={appContext}>
      <Router>
        <GlobalStyle />
        <ScrollToTop />
        <Routes>
          
          <Route element={<HomeLayout />}>
            <Route path="/" element={<Landing/>}/>
            <Route path="/overview" element={<Overview/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/signUp" element={<Signup/>}/>
            <Route path="/forgotPassword" element={<ForgotPassword/>}/>
            <Route path="/errorPage" element={<ErrorPage/>}/>
            <Route path="/contactUs" element={<ContactUs/>}/> 
          </Route>

          <Route element={<ProtectedLayout/>}>
            <Route path="/agreeToTerms" element={<AgreeToTerms/>}/>
            <Route path="/privacyPolicy" element={<PrivacyPolicy/>}/>  
            <Route path="/termsAndConditions" element={<TermsAndConditions/>}/>  
            <Route path="/linkEmailPassword" element={<LinkEmailPassword/>}/>
            <Route path="/curriculums" element={<Curriculums/>}/>
            <Route path="/editCurriculum" element={<EditCurriculum/>}/>
            <Route path="/subscriptions" element={<Subscriptions/>}/>
            <Route path="/pricing" element={<Pricing/>}/>
            <Route path="/tutorials" element={<Tutorials/>}/>
            <Route path="/tutorialVideo" element={<TutorialVideo/>}/>
            <Route path="/selectClassroom" element={<SelectClassroom/>}/>
            <Route path="/manageStudents" element={<ManageStudents/>}/>
            <Route path="/studentSubjectSummary" element={<StudentSubjectSummary/>}/>
            <Route path="/editClassroom" element={<EditClassroom/>}/>
            <Route path="/selectSubject" element={<SelectSubject/>}/>
            <Route path="/editSubject" element={<EditSubject/>}/>
            <Route path="/selectStudent" element={<SelectStudent/>}/>
            <Route path="/editStudent" element={<EditStudent/>}/>
            <Route path="/iepComment" element={<IEPComment/>}/>
            <Route path="/selectExpectations" element={<SelectExpectations/>}/>
            <Route path="/specificComment" element={<SpecificComment/>}/>            
            <Route path="/summaryPage" element={<SummaryPage/>}/>
            <Route path="/getComment" element={<GetComment/>}/>
            <Route path="/reviewComment" element={<ReviewComment/>}/>
          </Route>

          <Route path="*" element={<Navigate to="/login" replace />}/> 

        </Routes>

      </Router>
    </AppContext.Provider>
  );
}

export default App