//=============================================================================
// Check that string is not empty.  If it is, set the error using the setter
//=============================================================================
export default function checkEmptyString(value, message, errorSetter) {
    if (!value || value.trim().length === 0) {
        errorSetter(message);
        return true;
    } else {
        return false;
    }
}