import React, { useContext, useState } from "react";
import {useNavigate} from "react-router-dom";
import AppContext from "../components/AppContext";
import PageWrapper from "../components/PageWrapper";
import AlertDialog from "../components/AlertDialog";
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import addStudent from "../helpers/httpRequests/addStudent";
import updateStudent from "../helpers/httpRequests/updateStudent";
import { capitalize } from "lodash";
import hasOverlap from "../helpers/hasOverlap";
import assignString from "../helpers/assignString";
import checkEmptyString from "../helpers/checkEmptyString";
import Constants from "../constants";


export default function EditStudent() {
	const navigate = useNavigate();
	const appContext = useContext(AppContext);

	const [name, setName] = useState(appContext.student?.name ?? "");
	const [nameError, setNameError] = useState(undefined);
	const [nickname, setNickname] = useState(appContext.student?.nickname ?? "");
	const [nicknameError, setNicknameError] = useState(undefined);
	const [gender, setGender] = useState(appContext.student?.gender);
	const [genderError, setGenderError] = useState(undefined);
	const [error, setError] = useState(undefined);
	const [iepSubjects, setIEPSubjects] = useState(appContext.student?.iepSubjects ?? []);
	const [saving, setSaving] = useState(false);


	//====================================================================
	// Handle change to name
	//====================================================================
	const handleNameChange = (event) => {
		event.preventDefault();
		assignString("Student name", event.target.value, Constants.MAX_LABEL_SIZE, setName, setNameError);
	};


	//====================================================================
	// Handle change to nickname
	//====================================================================
	const handleNicknameChange = (event) => {
		event.preventDefault();
		assignString("Student nickname", event.target.value, Constants.MAX_LABEL_SIZE, setNickname, setNicknameError);
	};


	//====================================================================
	// Handle change to input
	//====================================================================
	const handleGenderChange = (newGender) => {
		setGender(newGender);
		setGenderError(undefined);
	};


	//====================================================================
	// Retrieve the set of candidate IEP subjects
	//====================================================================
	const getIEPCandidates = () => {
		let iepCandidates = [];

		for (const subjectSummary of appContext.classroom.subjectSummaries) {
			if (subjectSummary.iepCandidate && hasOverlap(subjectSummary.grades, appContext.classroom.assessAt)) {
				iepCandidates.push(subjectSummary);
			}
		}

		return iepCandidates;
	}


	//====================================================================
	// Update the IEP subject selected for this student
	//====================================================================
	const handleIEPChange = (event) => {
		const subjectName = event.target.name;

		if (iepSubjects.indexOf(subjectName) < 0) {
			let newIEPSubjects = [...iepSubjects];
			newIEPSubjects.push(subjectName);
			setIEPSubjects(newIEPSubjects);
		} else {
			setIEPSubjects(iepSubjects.filter(e => e !== subjectName));
		}
	};


	// Validate the form data
	const validateForm = () => {
		let nameError = checkEmptyString(name, 'You must provide a student name', setNameError);	
		const genderError = checkEmptyString(gender, 'You must provide a gender', setGenderError);	

		// If student name provided, we need to check to make sure its unique for the current classroom
		if (!nameError) {
	
			// Is there already a student with this same name in the classroom (ignore case)
			const studentName = name.trim().toLowerCase();
			const existingStudent = appContext.classroom.students.find(
				student => (student.name.toLowerCase() === studentName) &&
						   (!appContext.student || (appContext.student._id !== student._id))
			);

			if (existingStudent) {
				setNameError("You already have a student with this name in the classroom");
				nameError = true;
			}
		}

		return nameError || genderError;
	}


	const saveStudent = async (event) => {
		event.preventDefault();
		if (!saving) {

			setSaving(true);
				
			const formError = validateForm();
			if (!formError) {

				// Send request to add student to classroom
				let studentData = {
					name,
					nickname,
					gender,
					iepSubjects
				}

				// Update or create new student
				let student = undefined;
				if (appContext.student) {
					student = await updateStudent(appContext, appContext.student._id, studentData);
				} else {
					student = await addStudent(appContext, studentData);
				}

				if (student.error) {
					setError(student.error);
				} else {

					// If updating student, then update in context
					if (appContext.student) {
						const index = appContext.classroom.students.findIndex(item => item._id === student._id);
						if (index >= 0) {
							appContext.classroom.students[index] = student;
						}
					} else {

						// Add the student to the classroom
						appContext.classroom.studentIds.push(student._id);
						appContext.classroom.students.push(student);
					}

					navigate(-1);
				}
			}

			setSaving(false);
		}
	};


	// Get collection of genders available to curriculum
	const iepCandidates = getIEPCandidates();

	return (
		<PageWrapper
			title={appContext.student ? "Edit Student " + appContext.student.name : "Add Student"}
			leftButton="Cancel"
			leftIcon={<CancelIcon />}
			onLeftClick={() => navigate(-1)}
			rightButton="Save Student"
			rightIcon={<SaveIcon />}
			rightDisabled={saving}
			onRightClick={saveStudent}
			showClassroom
		>

				<div style={{backgroundColor: "#ffffff", padding: "20px"}}>
					<Typography variant="h6" sx={{marginLeft: "20px", marginRight: "20px", marginBottom: 0}}>
						Please note that the student's name and gender will be encrypted in the database.
						However, you should still only use student's first name, and perhaps initial.
						<br/><br/>
						The student name must be unique within the classroom.   If you have two children
						named John for example, you can name one "John A" and the other "John M".  You can
						then use the "Nickname/report" name to have the comment only place "John" into the 
						report.
					</Typography>
					<br/>
					<TextField sx={{ m: 1, width: '100%' }}
						required
						error={nameError !== undefined}
						name="name"
						label="Student Name"
						value={name}
						placeholder="Enter student UNIQUE name (preferably just first name)"
						onChange={handleNameChange}
						helperText={nameError}
						variant="standard"
					/>
					<TextField sx={{ m: 1, width: '100%' }}
						error={nicknameError !== undefined}
						name="nickname"
						label="Optional Nickname / Report name"
						value={nickname}
						placeholder="Optionally specify name to use in report (if different than student name)"
						onChange={handleNicknameChange}
						helperText={nicknameError}
						variant="standard"
					/>

					<br/><br/><br/>
					<FormControl error={genderError !== undefined}>
						<FormLabel id="gender-label">Gender</FormLabel>
						<FormGroup row>
							{appContext.classroom.curriculum.genders.map((genderChoice) => (
								<FormControlLabel
									key={genderChoice}
									control={
										<Radio
											checked={genderChoice === gender}
											onChange={() => handleGenderChange(genderChoice)}
										/>
									}
									label={capitalize(genderChoice)}
									sx={{margin: 0}}
								/>
							))}
						</FormGroup>
						{genderError && <FormHelperText>{genderError}</FormHelperText>}
					</FormControl>

					{(iepCandidates.length > 0) && 
						<div>
							<Typography variant="h6" sx={{marginTop: "30px"}}>
								Does this student have an IEP for any of these subjects?
							</Typography>

							{iepCandidates.map((subject) => (
								<FormControlLabel
									key={subject.name}
									control={
										<Checkbox
											checked={iepSubjects.indexOf(subject.name) >= 0}
											onChange={handleIEPChange}
										/>
									}
									name={subject.name}
									label={subject.label}
									labelPlacement="end"
									sx={{marginLeft: 0}}
								/>
							))}
						</div>
					}

					<AlertDialog title="Student Error" message={error} severity="error" onClose={() => setError(undefined)}/>
				</div>


		</PageWrapper>
	);
};