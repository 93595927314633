// This function is used to sort a list of objects by name
export default function compareByLabel(a,b) {
    const aLabel = a.label.toLowerCase();
    const bLabel = b.label.toLowerCase();
    if ( aLabel < bLabel ) {
        return -1;
    } else if ( aLabel > bLabel ) {
        return 1;
    } else {
        return 0;
    }
}