export default function numberExpectationsInSubject(subject) {
    let total = 0;

    for (const strand of subject.strands) {
        for (const group of strand.groups) {
            if (group.expectations) {
                for (const expectation of group.expectations) {
                    if (expectation.isSelected) {
                        total++;
                    }
                }
            }
        }
    }

    return total;
}