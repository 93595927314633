import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, Alert, DialogTitle } from '@mui/material';

export default function AlterDialog({message, title="Not Supported", severity="info", onClose}) {

  return (
    <div>
      <Dialog
        open={message !== undefined}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{minWidth: "50%"}}
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <Alert severity={severity}>{message}</Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} autoFocus>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}