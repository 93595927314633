import hasOverlap from "./hasOverlap";

//====================================================================
// Does the expectation have any overlap with the provided grades
//====================================================================
export default function expectationAtGrade(expectation, grades) {

    // If no grades specified for expectation, then good for all grades
    if (!expectation.grades) {
        return true;
    } else {
        return hasOverlap(grades, expectation.grades.split(","));
    }
}