import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <Typography align="center" variant="body1">{props.label} ({props.value}%)</Typography> <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}