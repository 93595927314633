import React, { useContext } from "react";
import {useNavigate} from "react-router-dom";
import AppContext from "../components/AppContext";
import PageWrapper from "../components/PageWrapper";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import overview from '../videos/overview.mp4';
import createClassroom from '../videos/createClassroom.mp4';
import generateComment from '../videos/generateComment.mp4';
import generateKindergartenComment from '../videos/generateKindergartenComment.mp4';
import customizePrimary from '../videos/customizePrimary.mp4';
import customizeKindergarten from '../videos/customizeKindergarten.mp4';

const videos = {
    "overview" : overview,
	"createClassroom" : createClassroom,
	"generateComment" : generateComment,
	"generateKindergartenComment" : generateKindergartenComment,
	"customizePrimary" : customizePrimary,
	"customizeKindergarten" : customizeKindergarten
}

export default function TutorialVideo () {
	const navigate = useNavigate();
	const appContext = useContext(AppContext);

	return (
		<PageWrapper
			title={appContext.tutorial.title}
			leftButton="Back"
			leftIcon={<ArrowBackIcon />}
			onLeftClick={() => navigate(-1)}
			backgroundBlack
		>
			<div style={{display: "flex", justifyContent: "center"}}>
				<video controls width="90%">
					<source src={videos[appContext.tutorial.name]} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			</div>

		</PageWrapper>
	);
};