import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../components/AppContext";
import PageWrapper from "../components/PageWrapper";
import updateClassroomMode from "../helpers/httpRequests/updateClassroomMode";
import updateClassroomClassOrder from "../helpers/httpRequests/updateClassroomClassOrder";
import compareByName from "../helpers/compareByName";
import {StyledTableRow, StyledTableCell} from "../components/StyledTable";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SubjectIcon from '@mui/icons-material/Subject';
import ArrowUpIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import ArrowDownIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Paper from '@mui/material/Paper';


export default function ManageStudents() {

  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  appContext.student = undefined;

  const [mode, setMode] = useState(appContext.classroom.mode);
  const [students, setStudents] = useState(getStudentList());
  const [classOrderModified, setClassOrderModified] = useState(false);

  // Refresh the student list based on the classroom mode
  function getStudentList() {

    if (appContext.classroom.mode === 'sorted') {
      appContext.classroom.students = appContext.classroom.students.sort(compareByName);
    } else {
      let studentList = [];
      for (const studentId of appContext.classroom.studentIds) {
        studentList.push(appContext.classroom.students.find(student => {return student._id === studentId}));
      }
      appContext.classroom.students = studentList;
    }
    return appContext.classroom.students;
  }


  //====================================================================
  // Add Student to class list
  //====================================================================
  async function addStudent() {
    navigate("/editStudent");
  }


  //====================================================================
	// Handle change to classroom mode for display students 
	//====================================================================
	async function changeMode(event, newMode) {
    if ((newMode !== null) && (newMode !== mode)) {
      setMode(newMode);
      await updateClassroomMode(appContext, newMode);
      setStudents(getStudentList());
    }
	};


  //====================================================================
	// Move the student in the classOrder
	//====================================================================
	async function changeClassOrder(event, studentId, delta) {
    event.stopPropagation();

    // Move student up/down in class order
    let studentIds = appContext.classroom.studentIds;
    const index = studentIds.indexOf(studentId);
    if ((index > 0) && (delta === -1)) {
        const prevStudentId = studentIds[index-1];
        studentIds[index-1] = studentIds[index];
        studentIds[index] = prevStudentId;
        setClassOrderModified(true);
        setStudents(getStudentList());
    } else if ((index >= 0) && (index < studentIds.length-1) && (delta === 1)) {
        const nextStudentId = studentIds[index+1];
        studentIds[index+1] = studentIds[index];
        studentIds[index] = nextStudentId;
        setClassOrderModified(true);
        setStudents(getStudentList());
    }
	};


  //====================================================================
	// Save the modified class order
	//====================================================================
	async function saveClassOrder(event) {
    event.stopPropagation();
    await updateClassroomClassOrder(appContext);
    setClassOrderModified(false);
  }


  //====================================================================
	// Save the modified class order
	//====================================================================
	async function editStudent(event, student) {
    event.stopPropagation();
    appContext.student = student;
    navigate("/editStudent");
  }

  //====================================================================
  // Handle selection the student
  //====================================================================
  async function selectStudent(student) {
    appContext.student = student;
    navigate("/studentSubjectSummary");
  }


  return (
    <PageWrapper 
      title=" Manage Students"
      leftButton="Classrooms"
      leftIcon={<ArrowBackIcon />}
      onLeftClick={() => navigate("/selectClassroom", {replace: true})}
      showClassroom
    >

      <div style={{margin: "0px 10px 0px 10px"}}>
        <Typography variant="h6" sx={{marginLeft: "20px", marginRight: "20px", marginBottom: 0}}>
            From here you can add new students to your classroom.   You can also:
        </Typography>
        <Typography sx={{marginLeft: "30px", marginRight: "20px"}}>
          - modify an existing student by pressing &nbsp; <EditIcon sx={{display: "inline-flex", verticalAlign: "middle"}} color="primary" />
          <br/>- view a summary of all reports made for a student (per subject) by pressing &nbsp; <SubjectIcon sx={{display: "inline-flex", verticalAlign: "middle"}} color="primary" />
          <br/>- change the student's position in the class order by pressing &nbsp; <ArrowUpIcon sx={{display: "inline-flex", verticalAlign: "middle"}} color="primary"/> or &nbsp; <ArrowDownIcon sx={{display: "inline-flex", verticalAlign: "middle"}} color="primary"/>
          
        </Typography>
        <br/>

        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", paddingBottom: "8px"}}>
          <ToggleButtonGroup
            color="primary"
            value={mode}
            exclusive
            onChange={changeMode}
            aria-label="Platform"
          >
            <ToggleButton size="small" value='classOrder'>Class Order</ToggleButton>
            <ToggleButton size="small" value='sorted'>Sorted</ToggleButton>
          </ToggleButtonGroup>
          {classOrderModified && <Button variant="outlined" size="small" onClick={saveClassOrder}>Save Class Order</Button>}
          <Button variant="outlined" size="small" endIcon={<AddIcon />} onClick={addStudent}>Add Student</Button>
        </div>

        <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow hover>
                  <StyledTableCell>Student Name</StyledTableCell>
                  <StyledTableCell align="center">Gender</StyledTableCell>
                  <StyledTableCell align="right"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {appContext.classroom.students && appContext.classroom.students.map((student, index) => (
                <StyledTableRow
                  key={student._id}
                  hover
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => selectStudent(student)}
                >
                  <StyledTableCell component="th" scope="row">
                    <div style={{display: "flex", alignItems: "center"}}>
                      {(appContext.classroom.mode !== "sorted") && 
                        <div style={{display: "flex"}}>
                          <IconButton color="primary" sx={{padding: 0, margin: 0}} onClick={async (event) => changeClassOrder(event, student._id, -1)}>
                            <ArrowUpIcon fontSize="large"/>
                          </IconButton>
                          <IconButton color="primary" sx={{padding: 0, marginRight: "10px"}} onClick={async (event) => changeClassOrder(event, student._id, 1)}>
                            <ArrowDownIcon fontSize="large"/>
                          </IconButton>
                        </div>
                      }
                      {student.name} {student.nickname ? ("(" + student.nickname + ")") : ''}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="center">{student.gender}</StyledTableCell>
                  <StyledTableCell align="right">
                    <IconButton color="primary" component="label">
                      <SubjectIcon />
                    </IconButton>
                    <IconButton color="primary" component="label" onClick={async (event) => await editStudent(event, student)}>
                      <EditIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> 
      </div>

    </PageWrapper>
  );
}