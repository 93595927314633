import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {

  return (
    <Box 
      sx={{ 
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        miHeight: '10vh',
        padding: '20px',
        justifyContent: 'center',
        backgroundColor: '#f2f0f1',
        flexDirection: 'column',
      }}>
      <Typography sx={{paddingBottom: '10px'}} >
        Provided by <b>MyReportAssistant</b>

      </Typography>
      <Typography sx={{opacity: '0.4'}}>Building intelligent software for Teachers</Typography>
    </Box>
  );
};

export default Footer;