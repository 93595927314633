export default function getStrandSelectedExpectations(strand, type) {
    let expectations = [];
    for (const group of strand.groups) {
        if (group.expectations) {
            for (const expectation of group.expectations) {
                if ((!type || (expectation.marks === type)) && expectation.isSelected) {
                    expectations.push(expectation);
                }
            }
        }
    }
    return expectations;
}