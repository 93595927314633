import { useContext, useState } from "react";
import {useNavigate} from "react-router-dom";
import AppContext from "../components/AppContext";
import { linkEmailPassword } from "../auth/auth";
import { Button, Grid, Box, Typography } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import FormInput from '../components/FormInput';
import PageWrapper from "../components/PageWrapper";
import { object, string } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import AlertDialog from "../components/AlertDialog";

  
export default function LinkEmailPassword() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const [error, setError] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  // 👇 Default Values
  const defaultValues = {
    password: '',
    passwordConfirm: '',
  };

  // SignUp Schema with Zod
  const passwordSchema = object({
    password: string()
      .min(1, 'Password is required')
      .min(8, 'Password must be more than 8 characters')
      .max(32, 'Password must be less than 32 characters')
      .regex(new RegExp(".*[A-Z].*"), "Must have at least one uppercase character")
      .regex(new RegExp(".*[a-z].*"), "Must have at least one lowercase character")
      .regex(new RegExp(".*\\d.*"), "Must have at least one  number")
      .regex(new RegExp(".*[^a-zA-Z0-9\s].*"), "Must have at least one special symbol"),
    passwordConfirm: string().min(1, 'Please confirm your password'),
  }).refine((data) => data.password === data.passwordConfirm, {
    path: ['passwordConfirm'],
    message: 'Passwords do not match',
  });

  // 👇 Object containing all the methods returned by useForm
  const methods = useForm({
    resolver: zodResolver(passwordSchema),
    defaultValues,
  });

  // 👇 Form Handler
  const onSubmitHandler = async (values) => {
    const result = await linkEmailPassword(appContext.email, values["password"]);
    if (result.error) {
        setError(result.error);
    } else {
        setMessage("Account linked to the provided email/password.   You will be logged out...");
    }
  };


  // 👇 Returned JSX
  return (
    <PageWrapper title="Link Account to Email/Password">

        <div style={{margin: "20px"}}>

            <Typography variant="h5" sx={{marginTop: "20px"}}>
                If you have only logged in through a provider (e.g. Google), than you can link a password
                to your account email.   This will allow you to also log in with an email/password to the same account.
            </Typography>


            <FormProvider {...methods}>
                <Grid
                    item
                    container
                    justifyContent='center'
                    rowSpacing={5}
                    sx={{
                        maxWidth: { sm: '45rem' },
                        marginInline: 'auto',
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{ marginTop: "30px"}}
                    >
                        <Box
                            display='flex'
                            flexDirection='column'
                            component='form'
                            noValidate
                            autoComplete='off'
                            sx={{ paddingRight: { sm: '3rem' } }}
                            onSubmit={methods.handleSubmit(onSubmitHandler)}
                        >
                            <Typography
                                variant='h6'
                                component='h1'
                                sx={{ textAlign: 'center', mb: '1.5rem' }}
                            >
                                Your Email: {appContext.email}
                            </Typography>

                            <FormInput
                                type='password'
                                label='Password'
                                name='password'
                                required
                                focused
                            />
                            <FormInput
                                type='password'
                                label='Confirm Password'
                                name='passwordConfirm'
                                required
                                focused
                            />

                            <Button
                                type='submit'
                                variant='contained'
                                sx={{
                                    py: '0.8rem',
                                    mt: 2,
                                    width: '80%',
                                    marginInline: 'auto',
                                }}
                            >
                                Link Email/Password
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

            </FormProvider>

        <AlertDialog message={error} severity="error" title="Account Link Error" onClose={() => setError(undefined)}/>
        <AlertDialog message={message} title="Account Linked" onClose={() => navigate("/login")}/>
        </div>
    </PageWrapper>
  );
};
