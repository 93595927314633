import groupHasExpectationsAtGrade from "./groupHasExpectationsAtGrade";

//====================================================================
// Does the strand have any expectations at the selected grade level
//====================================================================
export default function strandHasExpectationsAtGrade(strand, classroom) {
    let hasExpectations = false;

    // Does group have any expectations with any of these grades
    for (const group of strand.groups) {
        hasExpectations = groupHasExpectationsAtGrade(group, classroom.assessAt);
        if (hasExpectations) {
            break;
        }
    }

    return hasExpectations;
}