import {useContext, useState} from 'react';
import { useNavigate } from "react-router-dom";
import AppContext from "../components/AppContext";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import completeLogout from '../helpers/completeLogout';
import logo from '../images/logo-blue.png';

const userSettingsNoLink = ['Tutorials', 'Terms and Conditions', 'Privacy Policy', 'Contact Us', 'Logout'];
const userSettingsWithLink = ['Tutorials', 'Link Email/Password', 'Terms and Conditions', 'Privacy Policy', 'Contact Us', 'Logout'];
const fullSettingsNoLink = ['Classrooms', 'Curriculums', 'Subscriptions', 'Tutorials', 'Terms and Conditions', 'Privacy Policy', 'Contact Us', 'Logout'];
const fullSettingsWithLink = ['Classrooms', 'Curriculums', 'Subscriptions', 'Tutorials', 'Link Email/Password', 'Terms and Conditions', 'Privacy Policy', 'Contact Us', 'Logout'];

export default function PageAppBar(props) {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElFull, setAnchorElFull] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenFullMenu = (event) => {
    setAnchorElFull(event.currentTarget);
  };


  const handleCloseUserMenu = async (setting) => {
    handleMenuSelection(setting);
    setAnchorElUser(null);
  };

  const handleCloseFullMenu = async (setting) => {
    handleMenuSelection(setting);
    setAnchorElUser(null);
  };

  const handleMenuSelection = async (setting) => {
    if (setting === "Logout") {
      await completeLogout(navigate);
    } else if (setting === "Link Email/Password") {
      navigate("/linkEmailPassword");
    } else if (setting === "Subscriptions") {
      navigate("/subscriptions");
    } else if (setting === "Tutorials") {
      navigate("/tutorials");
    } else if (setting === "Privacy Policy") {
      navigate("/privacyPolicy");
    } else if (setting === "Terms and Conditions") {
      navigate("/termsAndConditions");
    } else if (setting === "Contact Us") {
      navigate("/contactUs");
    } else if (setting === "Classrooms") {
      navigate("/selectClassroom");
    } else if (setting === "Curriculums") {
      navigate("/curriculums");
    }
  }

  // Set settings options based on whether password provider defined
  const userSettings = appContext.allowLink ? userSettingsWithLink : userSettingsNoLink;
  const fullSettings = appContext.allowLink ? fullSettingsWithLink : fullSettingsNoLink;

  return (

      <AppBar position="static" style={{backgroundColor: "#f0f0f0", padding: "0px 10px"}}>
          <Toolbar disableGutters>
          
            <img src={logo} alt="logo" style={{height:"64px"}}/>

            {(props.landing === "true") && 
              <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'right' }}>
                <Button 
                  variant="contained" 
                  size="small"
                  onClick={() => navigate("/login")}
                >
                  Login
                </Button>
              </Box>
            }

            {appContext.educator && appContext.educator.agreeToTermsAndConditions &&
              <Box sx={{ flexGrow: 1, display: {xs: 'none', md: 'flex'}, justifyContent: 'right' }}>

                <Button sx={{marginRight: "10px"}} onClick={() => navigate("/selectClassroom", {replace: true})}>Classrooms</Button>
                <Button sx={{marginRight: "10px"}} onClick={() => navigate("/curriculums", {replace: true})}>Curriculums</Button>
                <Button sx={{marginRight: "10px"}} onClick={() => navigate("/subscriptions")}>Subscriptions</Button>

                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {userSettings.map((setting) => (
                    <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            }

            {appContext.educator && appContext.educator.agreeToTermsAndConditions &&
              <Box sx={{ flexGrow: 1, display: {xs: 'flex', md: 'none'}, justifyContent: 'right' }}>

                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenFullMenu} sx={{ p: 0 }}>
                    <MenuIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElFull}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElFull)}
                  onClose={handleCloseFullMenu}
                >
                  {fullSettings.map((setting) => (
                    <MenuItem key={setting} onClick={() => handleCloseFullMenu(setting)}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            }

          </Toolbar>

      </AppBar>
  );
}
