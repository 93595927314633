//=============================================================================
// Generate a new comment 
//=============================================================================
export default function assignString(label, value, maxSize, valueSetter, errorSetter) {
    if (value.length > maxSize) {
        errorSetter(`${label} limited to ${maxSize} characters`);
        value = value.substring(0, maxSize);
    } else {
        errorSetter(undefined);
    }
    valueSetter(value);
    return value;
}