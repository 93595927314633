import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../components/AppContext";
import PageWrapper from "../components/PageWrapper";
import AlertDialog from "../components/AlertDialog";
import {StyledTableRow, StyledTableCell} from "../components/StyledTable";
import clearAllContext from "../helpers/clearAllContext";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';


export default function Curriculums() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  // Clear all context
  clearAllContext(appContext);

  // Setup state for trying again
	const [error, setError] = useState(undefined);


  //====================================================================
  // Edit Curriculum
  //====================================================================
  async function editCurriculum(event, curriculum) {
    event.stopPropagation();
    appContext.curriculum = curriculum;
    navigate("/editCurriculum");
  }


  //====================================================================
  // Add Curriculum
  //====================================================================
  async function addCurriculum() {
    //navigate("/editCurriculum");
    setError("Adding Curriculum not currently supported, BUT coming soon ...");
  }


  return (
    <PageWrapper 
      title="Curriculums"
      leftButton="Classrooms"
      leftIcon={<ArrowBackIcon />}
      onLeftClick={() => navigate("/selectClassroom", {replace: true})}
    >

        <Typography component="div" variant="h6" sx={{margin: "30px 20px 20px 20px"}}>
          Currently we only support modifying the expectations or next
          steps in system curriculum subjects.
        </Typography>

        <div style={{margin: "0px 10px 0px 10px"}}>
          <div style={{display: "flex", justifyContent: "end", alignItems: "center", paddingBottom: "8px"}}>
            <Button variant="outlined" endIcon={<AddIcon />} onClick={addCurriculum}>Add Curriculum</Button>
          </div>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow hover>
                  <StyledTableCell>Curriculum Description</StyledTableCell>
                  <StyledTableCell align="center"># of Subjects</StyledTableCell>
                  <StyledTableCell align="right">Type</StyledTableCell>
                  <StyledTableCell align="right"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appContext.educator.curriculums.map((curriculum, index) => (
                  <React.Fragment key={curriculum.label}>
                    <StyledTableRow
                      hover
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <StyledTableCell component="th" scope="row">{curriculum.label}</StyledTableCell>
                      <StyledTableCell align="center">{curriculum.subjects.length}</StyledTableCell>
                      <StyledTableCell align="right">System</StyledTableCell>
                      <StyledTableCell align="right">
                        <IconButton color="primary" component="label" onClick={async (event) => await editCurriculum(event, curriculum)}>
                          <EditIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
        </TableContainer> 
        </div>

        <AlertDialog title="Curriculum Error" message={error} severity="error" onClose={() => setError(undefined)}/>
          
    </PageWrapper>
  );
}