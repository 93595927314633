//====================================================================
// Get student name to use in reports and UI (except for student list)
//====================================================================
export default function getNickname(student) {

    // Establish student name to use.
    let nickname = student.nickname;
    if (!nickname) {
        nickname = student.name;
    }

    return nickname;
}