import React, { useContext } from "react";
import {useNavigate} from "react-router-dom";
import AppContext from "../components/AppContext";
import PageWrapper from "../components/PageWrapper";
import TutorialCard from "../components/TutorialCard";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


export default function Tutorials () {
	const navigate = useNavigate();
	const appContext = useContext(AppContext);

	const tutorials = [
		{ 
			name: "overview", 
			title: "Overview of MyReportAssistant", 
			description: "This video provides an overview of the features of MyReportAssistant"
		},
		{ 
			name: "createClassroom", 
			title: "How to create your first classroom", 
			description: "This tutorial will show you how to create your first classroom"
		},
		{ 
			name: "generateComment", 
			title: "Generate a comment", 
			description: "This tutorial will walk you through the basics of generating a comment for any subject."
		},
		{ 
			name: "generateKindergartenComment", 
			title: "Generate a Kindergarten comment", 
			description: "This tutorial will walk you through the process of creating a comment for your Kindergarten student."
		},
		{ 
			name: "customizeKindergarten", 
			title: "Customizing the Curriculum - Kindergarten", 
			description: "This tutorial will walk you through the process of how you can customize a subject in your Kindergarten curriculum."
		},
		{ 
			name: "customizePrimary", 
			title: "Customizing the Curriculum - Grades 1 to 8", 
			description: "This tutorial will walk you through the process of how you can customize a subject in your Primary curriculum."
		}
	]

	const handleClick = (tutorial) => {
		appContext.tutorial = tutorial;
		navigate("/tutorialVideo");
	}


	return (
		<PageWrapper
			title="Tutorials"
			leftButton="Back"
			leftIcon={<ArrowBackIcon />}
			onLeftClick={() => navigate(-1)}
		>

          {tutorials.map((tutorial) => (
            <div key={tutorial.name} style={{padding: "0px 20px"}}>
              <TutorialCard tutorial={tutorial} onTutorialClick={() => handleClick(tutorial)} />
              <br/>
            </div>
          ))}

		</PageWrapper>
	);
};