import React, { useState, useContext, useEffect } from "react";
import AppContext from "../components/AppContext";
import { GroupSelector } from "../globalStyles";
import ExpectationEditCard from "./ExpectationEditCard";
import Selector from "./Selector";
import numberSelectionsInStrand from "../helpers/numberSelectionsInStrand";
import numberSelectionsInGroupLabel from "../helpers/numberSelectionsInGroupLabel";
import gradeFilterSatisfied from "../helpers/gradeFilterSatisfied";
import strengthOrWeaknessSatisfied from "../helpers/strengthOrWeaknessSatisfied";
import { Typography, Button } from '@mui/material';


export default function StrandEditCard({strand, onChange}) {
    const appContext = useContext(AppContext);
    
    // Get some short hands
    const selectedGroup = getSelectedGroup();
    const availableGrades = getAvailableGrades();
    const availableStrengthOrWeakness = getAvailableStrengthOrWeakness();

    // Update the group states for all groups in the strand
    updateGroupStates();

    // Setup state for trying again
    const [data, setData] = useState({
        strengthOrWeakness: availableStrengthOrWeakness ? availableStrengthOrWeakness[0] : undefined,
        grade: availableGrades ? availableGrades[0] : undefined
	});

    const [refreshToggle, setRefreshToggle] = useState(false);

    useEffect(() => { 
        setData( {...data, strandMark: strand.mark, assessedAtGrade: strand.assessedAtGrade } );
    }, [strand.mark, strand.assessedAtGrade]);


	//====================================================================
	// Refresh page
	//====================================================================
	const refreshPage = () => {
        setRefreshToggle(!refreshToggle);
	};

	//====================================================================
	// Handle change to input
	//====================================================================
	const handleChange = (event) => {
		setData({ ...data, [event.target.name]: event.target.value });
	};


    //====================================================================
    // Include filtered expectations in currently selected group
    //====================================================================
    const hideExpectations = (isHidden) => {
        if (selectedGroup && selectedGroup.expectations) {
            if (selectedGroup.expectations) {
                for (const expectation of selectedGroup.expectations) {
                    if (gradeFilterSatisfied(expectation, data.grade) && 
                        strengthOrWeaknessSatisfied(appContext.subject, expectation, data.strengthOrWeakness)) {
                        expectation.isHidden = isHidden;
                    }
                }
            }
            refreshPage();
        }
    }


    //====================================================================
    // Include filtered next steps in currently selected group
    //====================================================================
    const hideNextSteps = (isHidden) => {
        if (selectedGroup && selectedGroup.nextSteps) {
            for (const nextStep of selectedGroup.nextSteps) {
                if (gradeFilterSatisfied(nextStep, data.grade) && 
                    strengthOrWeaknessSatisfied(appContext.subject, nextStep, data.strengthOrWeakness)) {
                        nextStep.isHidden = isHidden;
                }
            }
            refreshPage();
        }
    }


    //====================================================================
    // Update the state of all groups in the strand
    //====================================================================
    function updateGroupStates() {
        for (const group of strand.groups) {
            if (group.isHidden) {
                group.state = (group === selectedGroup) ? "selectedHidden" : "hidden";
            } else {
                group.state = (group === selectedGroup) ? "selected" : undefined;
            }
        }
    }


    //====================================================================
    // Hide/Show selected group
    //====================================================================
    function hideSelectedGroup() {
        selectedGroup.isHidden = true; 
        refreshPage();
    }
    function showSelectedGroup() {
        selectedGroup.isHidden = false; 
        refreshPage();
    }


    //====================================================================
    // Do we need to provide option of editing by strength/weakness
    //====================================================================
    function getAvailableStrengthOrWeakness() {
        let availableStrengthOrWeakness = undefined;
        if (strand.markSpecific === true) {
            availableStrengthOrWeakness = ["Weakness", "Strength"];
        }
        return availableStrengthOrWeakness;
    }


    //====================================================================
    // Do we need to edit based on grades:
    //  - If strand marked as "gradeSpecific", include grade filter
    //  - If subject not configured for multiple grades, only the current grade
    //  - If subject configured to be assessed at multiple grade, then 
    //    multiple grade selections and default to current grades
    //====================================================================
    function getAvailableGrades() {
        let availableGrades = undefined;
        if (strand.gradeSpecific === true) {
            availableGrades = appContext.subject.grades;
        }
        return availableGrades;
    }



	//====================================================================
	// Generate unique key within the custom subject.   The subject 
	// object itself tracks the next key to use
	//====================================================================
	const generateKey = () => {
		const key = "c" + appContext.subject.nextKey;
		appContext.subject.nextKey++;
		return key;
	}


	//====================================================================
	// Return type to encode into new expectation/nextStep
	//====================================================================
	const getExpectationMarks = () => {
		let marks = undefined;
		if (strand.markSpecific !== false) {
			if (data.strengthOrWeakness === "Strength") {
				marks = appContext.subject.markingScheme.substring(2);
			} else {
				marks = appContext.subject.markingScheme.substring(0, 2);
			}
		}
		return marks;
	};


	//====================================================================
	// Return grade to encode into new expectation/nextStep
	//====================================================================
	const getExpectationGrades = () => {
		let grades = undefined;
		if (strand.gradeSpecific !== false) {
			grades = data.grade;
		}
		return grades;
	};


    const addExpectation = (isNextStep=false) => {

		const newExpectation = {
			key: generateKey(),
			label: "",
			marks: getExpectationMarks(),
			grades: getExpectationGrades(),
			isAdded: true
		}

		// Save to expectation or nextStep list
		if (isNextStep) {
			selectedGroup.nextSteps.unshift(newExpectation);
		} else {
			selectedGroup.expectations.unshift(newExpectation);
		}

        refreshPage();
	}


    //====================================================================
    // Delete an existing expectation in a group
    //====================================================================
    function deleteExpectation(expectation, isNextStep=false) {
        if (selectedGroup) {
            if (isNextStep) {
                const index = selectedGroup.nextSteps.indexOf(expectation);
                if (index >= 0) {
                    selectedGroup.nextSteps.splice(index, 1); 
                }
            } else {
                const index = selectedGroup.expectations.indexOf(expectation);
                if (index >= 0) {
                    selectedGroup.expectations.splice(index, 1); 
                }                
            }
            refreshPage();
        }
    }


    //====================================================================
    // Render the collection of group selectors for the strand
    //====================================================================
    function selectGroup(newGroup) {

        // If we click on the same group, deselect it
        if (strand.selectedGroupKey === newGroup) {
            strand.selectedGroupKey = undefined;
        } else {
            strand.selectedGroupKey = newGroup;
        }

        refreshPage();
    }


    //====================================================================
    // Render the collection of group selectors for the strand
    //====================================================================
    function renderGroupSelectors() {

        // If a group has no name, we consider it a default group of 
        // expectations.
        if ((strand.groups.length === 1) && (strand.groups[0].group === undefined)) {
            strand.groups[0].group = "Expectations";
        }

        return (
            <div style={{display: "flex", flexWrap: "wrap"}}>
                {strand.groups.map((group) => (
                    <div key={(strand.key + group.key)}>
                        <GroupSelector className={group.state}>
                            <Typography variant="body1" textAlign="right" onClick={() => selectGroup(group.key)}>{group.label} {numberSelectionsInGroupLabel(group)}</Typography>
                        </GroupSelector>
                    </div>
                ))}
            </div>
        );
    }


    //====================================================================
    // Get the selected group from the selected group name
    //====================================================================
    function getSelectedGroup() {
        if (strand.selectedGroupKey) {
            return strand.groups.find(group => group.key === strand.selectedGroupKey);
        } else {
            return undefined;
        }
    }


    return (
        <div style={{padding: "10px 10px 0px 10px", backgroundColor: "#f8f8f8", border: "1px solid #1d609c"}}>

            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="h5" textAlign="left">{strand.label} {numberSelectionsInStrand(strand)}</Typography>
                <div style={{display: "flex", flexWrap: "wrap", alignItems: "center"}}>

                    {availableGrades &&
                        <Selector name="grade" label="Grade" choices={availableGrades} value={data.grade} onClick={handleChange}/>
                    }
                    {!availableGrades && <div></div>}

                    {availableStrengthOrWeakness &&
                        <div  style={{margin: "5px 0px 5px 0px"}}>
                            <Selector name="strengthOrWeakness" choices={availableStrengthOrWeakness} value={data.strengthOrWeakness} onClick={handleChange}/>
                        </div>
                    }                    
                </div>
            </div>

            <div style={{marginTop: "10px", marginLeft: "20px"}}>

                {renderGroupSelectors()}

                {selectedGroup && <div>
                
                    {selectedGroup.isHidden && <div style={{marginBottom: "20px"}}>
                        <Button variant="outlined" onClick={showSelectedGroup}>Show Group</Button>
                    </div>}

                    {!selectedGroup.isHidden && 
                        <div>
                            <Button variant="outlined" size="small" onClick={hideSelectedGroup}>Hide Group</Button>
    
                            {selectedGroup.expectations && (selectedGroup.expectations.length > 0) &&
                                <div>
                                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "10px", paddingBottom:"5px"}}>
                                        <div style={{display: "flex"}}>
                                            <Button variant="outlined" size="small" sx={{marginRight: "10px"}} onClick={() => hideExpectations(false)}>Include All</Button>
                                            <Button variant="outlined" size="small" onClick={() => hideExpectations(true)}>Exclude All</Button>
                                        </div>
                                        <Button variant="outlined" size="small" onClick={() => addExpectation(false)}>Add Expectation</Button>
                                    </div>
                                    {selectedGroup.expectations.map((expectation) => (
                                        <div key={(strand.key + selectedGroup.key + expectation.key)}>
                                            {gradeFilterSatisfied(expectation, data.grade) && strengthOrWeaknessSatisfied(appContext.subject, expectation, data.strengthOrWeakness) &&
                                            <div>
                                                <ExpectationEditCard gradeSpecific={strand.gradeSpecific} expectation={expectation} onDelete={deleteExpectation} onClick={refreshPage}/>
                                            </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                            }

                            {selectedGroup.nextSteps && (selectedGroup.nextSteps.length > 0) &&
                                <div>
                                    {selectedGroup.expectations && (selectedGroup.expectations.length > 0) && 
                                        <div style={{marginTop: "20px"}}>
                                            <Typography variant="h5" textAlign="center">Next Steps </Typography>
                                        </div>
                                    }
                                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "10px", paddingBottom:"5px"}}>
                                        <div style={{display: "flex"}}>
                                            <Button variant="outlined" size="small" onClick={() => hideNextSteps(false)}>Include All</Button>
                                            <Button variant="outlined" size="small" onClick={() => hideNextSteps(true)}>Exclude All</Button>
                                        </div>
                                        <Button variant="outlined" size="small" onClick={() => addExpectation(true)}>Add Next Step</Button>
                                    </div>
                                    {selectedGroup.nextSteps.map((nextStep) => (
                                        <div key={(strand.key + selectedGroup.key + nextStep.key)}>
                                                {gradeFilterSatisfied(nextStep, data.grade) && strengthOrWeaknessSatisfied(appContext.subject, nextStep, data.strengthOrWeakness)  &&
                                                <div>
                                                    <ExpectationEditCard gradeSpecific={strand.gradeSpecific} expectation={nextStep} isNextStep={true} onDelete={deleteExpectation} onClick={refreshPage}/>
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                            }

                            <br/>
                        </div>
                    }
                </div>}
            </div>
        </div>
    );
}