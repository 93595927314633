import httpPost from "./httpPost";
import Constants from "../../constants";

//=============================================================================
// Generate a new comment 
//=============================================================================
export default async function newComment(appContext, maxTokens=4096) {

    // Provide info needed for new comment
    const body = { temperature: appContext.subject.temperature, maxTokens, messages: appContext.messages, subject: appContext.subject.name };

    // Request the comment
    let result = await httpPost(Constants.REQ_NEW_COMMENT, body, Constants.HTTP_LONG_TIMEOUT );
    if (result.error) {
        result.error = "Your Personal Report Assistant appears to be on a coffe break, TRY AGAIN";
    }

    return result;
}