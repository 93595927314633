import Constants from "../../constants";
import { auth } from '../../auth/firebase';


//==============================================================================
// Process an HTTP request
//==============================================================================
export default async function httpRequest(method, url, body, timeout ) {

    // If timeout not specified, default to long timeout (for personal assistant)
    if (!timeout) {
        timeout = Constants.HTTP_SHORT_TIMEOUT;
    }

    // Retrieve token to use for the request
    const token = await auth.currentUser.getIdToken();

    // Configure abort controller for specified timeout
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);


    const requestOptions = {
        method,
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: body ? JSON.stringify(body) : undefined,
        signal: controller.signal
    };
  
    let result = {};
    try {
        const res = await fetch(url, requestOptions);

        try {
            result = await res.json();
        } catch(e) {
            result.error = "It would appear that your school computer is blocking responses, most likely because it has recently gone to sleep.   Please logout, open a NEW browser tab, and log back in to continue.";
        }

    } catch (err) {
        result.error = "Error on call to server: " + err.message;
        result.extraInfo = err.toString();
    } finally {
        clearTimeout(timeoutId);
    }

    return result;
}