import httpPut from "./httpPut";
import Constants from "../../constants";


//=============================================================================
// Update the classroom's current report period
//=============================================================================
export default async function updateClassroomClassOrder(appContext) {
    const url = Constants.REQ_UPDATE_CLASSROOM_CLASSORDER.replace("<id>", appContext.classroom._id);

    // Update classroom class order on server
    return await httpPut(url, {studentIds: appContext.classroom.studentIds});
}