import React, { useState } from "react";
import assignString from "../helpers/assignString";
import Constants from "../constants";
import { MyExpectationEditCard } from "../globalStyles";
import { Checkbox, IconButton, Typography, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { genderChoices } from "../constants";
import { capitalize } from "lodash";
import replaceAll from "../helpers/replaceAll";

export default function ExpectationEditCard({gradeSpecific, expectation, group, isNextStep=false, onClick, onEdit, onDelete}) {

  const [toggleUpdated, setToggleUpdated] = useState(false);
  const [label, setLabel] = useState(expectation.label);
  const [labelError, setLabelError] = useState(undefined);


  //====================================================================
  // Handle toggling of hidden state of expectation
  //====================================================================
  function toggleHidden(event) { 
    expectation.isHidden = !expectation.isHidden;
    if (onClick) {
      onClick(event)
    }
    setToggleUpdated(!toggleUpdated);
  }


  //====================================================================
  // Handle delete request
  //====================================================================
  function deleteExpectation(event, expectation) { 
    event.stopPropagation();
    if (onDelete) {
      onDelete(expectation, isNextStep);
    }
  }


	//====================================================================
	// Handle change to input
	//====================================================================
	const handleChange = (event) => {
    event.stopPropagation();

    const endings = [" ", ".", ",", ":", ";"]

    // Attempt to replace any gender entries with their equivalent tag
    let label = event.target.value;

    for (const ending of endings) {
      for (let i = 0; i < 2; i++) {
        for (const genderChoice of genderChoices) {

          const capitalizedGenderChoice = capitalize(genderChoice[i]);
          const capitalizedGenderTag = capitalize(genderChoice[0]);

          // Because replaceAll uses RegEx, we need to escape the .
          let tag = " " + genderChoice[i] + ending;
          let capitalizeTag = " " + capitalizedGenderChoice + ending;
          if (ending === ".") {
            tag = ` ${genderChoice[i]}\\.`
            capitalizeTag = ` ${capitalizedGenderChoice}\\.`
          }

          // Replace embedded tags
          label = replaceAll(label, tag, " <" + genderChoice[0] + ">" + ending);
          label = replaceAll(label, capitalizeTag, " <" + capitalizedGenderTag + ">" + ending);

          // Check for tag starting
          const startTag = genderChoice[i] + ending;
          if (label.startsWith(startTag)) {
            label = "<" + genderChoice[0] + ">" + ending + label.substring(startTag.length);
          }
          const startCapitalizeTag = capitalizedGenderChoice + ending;
          if (label.startsWith(startCapitalizeTag)) {
            label = "<" + capitalizedGenderTag + ">" + ending + label.substring(startCapitalizeTag.length);
          }
        }
      }
    }

    // Restrict the length of the label
    label = assignString("Expectation", label, Constants.MAX_EXPECTATION_SIZE, setLabel, setLabelError);
    expectation.label = label;
	};

  const handleClick = (event) => {
    event.stopPropagation();
	};
  

  return (

      <MyExpectationEditCard onClick={toggleHidden}>
      
      <div style={{alignSelf: "flex-start"}}>
        <Checkbox checked={expectation.isHidden !== true} sx={{ color: "#aa0000", "&.Mui-unchecked": { color: "red" }}} inputProps={{ 'aria-label': 'controlled' }} />
      </div>
      <div style={{flexGrow: 1}}>
        <TextField sx={{ width: "100%" }}
          error={labelError !== undefined}
          multiline
          disabled={expectation.isHidden}
					name="label"
					value={label}
					placeholder={isNextStep ? "<New Next Step>" : "<New Expectation>"}
					onChange={handleChange}
          helperText={labelError}
          onClick={handleClick}
				/>
      </div>
      
      {gradeSpecific && expectation.grades && expectation.grades.length > 1 && <Typography sx={{marginLeft: "5px", marginRight: "5px"}} variant="caption">Grades {expectation.grades}</Typography>}
      {gradeSpecific && !expectation.grades && <Typography sx={{marginLeft: "5px", marginRight: "5px"}} variant="caption">All Grades</Typography>}
      <div style={{display: "flex", justifyContent: "flex-end"}}>
        {!expectation.isHidden && expectation.isAdded && 
          <IconButton sx={{paddingRight: "5px"}}  color="primary" component="label" onClick={(event) => deleteExpectation(event, expectation)}>
            <DeleteIcon />
          </IconButton>
        }
      </div>
    </MyExpectationEditCard>

  );
}