import React, { useContext } from "react";
import AppContext from "../components/AppContext";
import PageAppBar from "./PageAppBar";
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import { Button, Typography} from '@mui/material';

const theme = createTheme({
typography: {
    fontFamily: [
    'Poppins',
    'sans-serif',
    ].join(','),
},});

 
export default function PageWrapper(props) {
    const appContext = useContext(AppContext);

    const floatingStyle = {
        position: "sticky",
        top: 0,
        zIndex: 99,
        backgroundColor: "#f8f8f8", 
        marginBottom: "20px"
    };

    
    let baseStyle = {maxWidth: '1400px', margin: 'auto', paddingBottom: "20px"};
    if (props.backgroundBlack) {
        baseStyle = {maxWidth: '1400px', margin: 'auto', backgroundColor: "black", paddingBottom: "20px"}
    }

    return (
        <div style={baseStyle}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <div style={floatingStyle}>
                    <PageAppBar landing={props.landing} title={props.title}/>

                    {props.title &&
                    <div style={{backgroundColor: "#d0d0d0", paddingBottom: "2px"}}>
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#f8f8f8", marginTop: "10px", paddingBottom: "5px"}}>
                            <div style={{minWidth: 100, marginLeft: "10px"}}>
                                {props.leftButton && <Button disabled={props.leftDisabled} variant="outlined" size="small" startIcon={props.leftIcon} onClick={props.onLeftClick}>{props.leftButton}</Button>}
                            </div>
                            <div>
                                <Typography variant="h5" color="primary" align="center">{props.title}</Typography>
                                {props.subTitle && <Typography variant="subtitle1" align="center" color="black">{props.subTitle}</Typography>}
                                {props.showClassroom && <Typography variant="subtitle1" align="center" color="black">{appContext.classroom.label}</Typography>}
                                {props.showSubject  && <Typography variant="subtitle1" align="center" color="black">{appContext.subject.label}</Typography>}
                                {props.showComments && <Typography variant="subtitle1" align="center" color="black">Comments Remaining: {appContext.educator.subscriptionData.commentsRemaining}</Typography>}

                            </div>
                            <div style={{display: "flex", flexDirection: "row-reverse", minWidth: 100, marginRight: "10px"}}>
                                {props.rightButton && <Button disabled={props.rightDisabled} variant="outlined" size="small" endIcon={props.rightIcon}  onClick={props.onRightClick}>{props.rightButton}</Button>}
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <div>
                    {props.children}
                </div>
            </ThemeProvider>
        </div>
    );
}