import React, { useState, useEffect } from "react";
import { MySelector, MySelectorButton} from "../globalStyles";

export default function Selector({name, choices, value, label, onClick}) {
    const [activeButton, setActiveButton] = useState(value);
    useEffect(() => { setActiveButton(value)}, [value] )  

    const handleClick = (event) => {
        event.stopPropagation();
        
        // If we are clicking the same button again, deselect it
        if (event.target.value === activeButton) {
            setActiveButton(event.target.value);
        }

        // If on click handler specified, pass the event up, after changing the name to the selectors name
        if (onClick) {
            event.target.name = name;
            onClick(event);
        }
    };

    return (
        <MySelector>
            {label ? <div style={{marginRight: "5px"}}>{label}:</div> : ""}
            {choices.map((choice, index) => (
                <MySelectorButton
                    key={index}
                    className={activeButton === choice? 'active' : undefined}
                    onClick={handleClick}
                    value={choice}
                >
                    {choice}
                </MySelectorButton>
            ))}
        </MySelector>
    );
}