import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../components/AppContext";
import PageWrapper from "../components/PageWrapper";
import AlertDialog from "../components/AlertDialog";
import ConfirmDialog from "../components/ConfirmDialog";
import {StyledTableRow, StyledTableCell} from "../components/StyledTable";
import clearAllContext from "../helpers/clearAllContext";
import getStudents from "../helpers/httpRequests/getStudents";
import updateClassroomActive from "../helpers/httpRequests/updateClassroomActive";
import updateEducatorShowHiddenClassrooms from "../helpers/httpRequests/updateEducatorShowHiddenClassrooms";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import PeopleIcon from '@mui/icons-material/People';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import injectClassroomInfo from "../helpers/injectClassroomInfo";

export default function SelectClassroom() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  // Clear all context
  clearAllContext(appContext);

  // Setup state for trying again
	const [error, setError] = useState(undefined);
  const [sending, setSending] = useState(false);
  const [showHidden, setShowHidden] = useState(appContext.educator.showHiddenClassrooms);
  const [notices, setNotices] = useState(undefined);
  const [toggleView, setToggleView] = useState(false);
  const [firstLogin, setFirstLogin] = useState(appContext.educator.firstLogin);

  // Group any notices
	useEffect( () => {
    if (!appContext.educator.firstLogin) {
      if (appContext.educator.notices && appContext.educator.notices.length > 0) {
        let newNotices = "";
        for (const notice of appContext.educator.notices) {
          newNotices += notice.notice + "\n\n";
        }
        setNotices(newNotices);

        // We only want to see the notices once after logging in, so clear them from educator context
        appContext.educator.notices = undefined;
      }
    }
	}, [appContext.educator.notices]);


  //====================================================================
  // Handle selection the classroom by loading classroom
  //====================================================================
  async function selectClassroom(event, classroom) {
    event.stopPropagation();

    if (!sending) {
      setSending(true);

      // If the classroom doesn't already have its students loaded, do so now
      if (!classroom.students) {
        const result = await getStudents(classroom._id);
        if (result.error) {
          setError(result.error);
        } else {
          classroom.students = result.students;
        }
      }

      // Continue only if no error and we have a student list (possibly empty)
      if (classroom.students) {

        // Set classroom context and proceed to select subject
        appContext.classroom = classroom;

        // Inject/Refresh classroom info (curriculum/subjectSummaries) into classroom
        injectClassroomInfo(appContext, classroom);

        navigate("/selectSubject")
      }

      setSending(false);
    }
  }


  //====================================================================
  // Toggle whether to show hidden
  //====================================================================
  async function toggleClassroomActive(event, classroom) {
    event.stopPropagation();
    classroom.isActive = !classroom.isActive;
    await updateClassroomActive(classroom._id, classroom.isActive);
    setToggleView(!toggleView);
  }


  //====================================================================
  // Manage Students
  //====================================================================
  async function manageStudents(event, classroom) {
    event.stopPropagation();
    
    // If the classroom doesn't already have its students loaded, do so now
    if (!classroom.students) {
      const result = await getStudents(classroom._id);
      if (result.error) {
        setError(result.error);
      } else {
        classroom.students = result.students;
      }
    }

    // Continue only if no error
    if (classroom.students) {

      // Inject/Refresh classroom info (curriculum/subjectSummaries) into classroom
      injectClassroomInfo(appContext, classroom);

      appContext.classroom = classroom;
      navigate("/manageStudents");
    }
  }


  //====================================================================
  // Edit Classroom
  //====================================================================
  async function editClassroom(event, classroom) {
    event.stopPropagation();
    appContext.classroom = classroom;
    navigate("/editClassroom");
  }


  //====================================================================
  // Toggle whether to show hidden
  //====================================================================
  function toggleShow() {
    const showHiddenClassrooms = !showHidden;
    setShowHidden(showHiddenClassrooms);
    updateEducatorShowHiddenClassrooms(appContext, showHiddenClassrooms);
  }


  //====================================================================
  // Add Classroom
  //====================================================================
  async function addClassroom() {
    navigate("/editClassroom");
  }


  //====================================================================
  // Don't view the tutorials at this time
  //====================================================================
  function handleNoTutorials(event) {
    event.preventDefault();
    appContext.educator.firstLogin = false;
    setFirstLogin(false);
  }


  //====================================================================
  // View the tutorials at this time
  //====================================================================
  function handleViewTutorials(event) {
    event.preventDefault();
    appContext.educator.firstLogin = false;
    setFirstLogin(false);
    navigate("/tutorials");
  }


  return (
    <PageWrapper 
      title="Classrooms"
      subTitle={appContext.educator.email}
      leftButton="Logout"
      leftIcon={<ArrowBackIcon />}
      onLeftClick={() => navigate("/login", {replace: true})}
      showComments
    >

        <div style={{margin: "0px 10px 0px 10px"}}>
          <Typography variant="h6" sx={{marginLeft: "20px", marginRight: "20px", marginBottom: 0}}>
            Please select a classroom to begin creating report comments.   You can also:
          </Typography>
          <Typography sx={{marginLeft: "30px", marginRight: "20px"}}>
            - add a new classroom or modify an existing one by pressing &nbsp; <EditIcon sx={{display: "inline-flex", verticalAlign: "middle"}} color="primary" />
            <br/>- manage students in an existing classroom by pressing 
            &nbsp; <PeopleIcon sx={{display: "inline-flex", verticalAlign: "middle"}} color="primary" />
            <br/>- hide old classrooms by pressing &nbsp; <VisibilityIcon sx={{display: "inline-flex", verticalAlign: "middle"}} color="primary" />
          </Typography>
          <br/>
          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "5px"}}>
            < FormGroup sx={{paddingLeft: "10px"}}>
              {!showHidden && <FormControlLabel control={<Checkbox onClick={toggleShow} />} label="Show Hidden Classrooms" />}
              {showHidden && <FormControlLabel control={<Checkbox defaultChecked onClick={toggleShow} />} label="Show Hidden Classrooms" />}
            </FormGroup>
            <Button variant="outlined" size="small" endIcon={<AddIcon />} onClick={addClassroom}>Add Classroom</Button>
          </div>
          <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow hover>
                    <StyledTableCell>Your Classrooms</StyledTableCell>
                    <StyledTableCell align="center">Session</StyledTableCell>
                    <StyledTableCell align="center">Grade</StyledTableCell>
                    <StyledTableCell align="center"><PeopleIcon /></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {appContext.educator.classrooms.map((classroom, index) => (
                  <React.Fragment key={classroom.label}>
                  {(showHidden || classroom.isActive) && <StyledTableRow
                    hover
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    onClick={async (event) => await selectClassroom(event, classroom)}
                  >
                    <StyledTableCell component="th" scope="row">{classroom.label}</StyledTableCell>
                    <StyledTableCell align="center">{classroom.session}</StyledTableCell>
                    <StyledTableCell align="center">{classroom.grade}</StyledTableCell>
                    <StyledTableCell align="center">{classroom.studentIds.length}</StyledTableCell>
                    <StyledTableCell align="right">
                      <IconButton color="primary" aria-label="toggle hide" component="label" onClick={async (event) => await toggleClassroomActive(event, classroom)}>
                        {classroom.isActive && <VisibilityIcon />}
                        {!classroom.isActive && <VisibilityOffIcon />}
                      </IconButton>
                      <IconButton color="primary" aria-label="edit classroom" component="label" onClick={async (event) => await manageStudents(event, classroom)}>
                        <PeopleIcon />
                      </IconButton>
                      <IconButton color="primary" aria-label="edit classroom" component="label" onClick={async (event) => await editClassroom(event, classroom)}>
                        <EditIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer> 
        </div>

        <AlertDialog title="Classroom Error" message={error} severity="error" onClose={() => setError(undefined)}/>
        <AlertDialog message={notices} title="Notices" onClose={() => setNotices(undefined)} />
        {firstLogin && <ConfirmDialog title="View Tutorials?" yesNo prompt="Would you like to see a list of tutorials?   Alternatively, you can access the list of tutorials at any time from the top corner menu" onCancel={handleNoTutorials} onConfirm ={handleViewTutorials}/>}

        <br/>     
    </PageWrapper>
  );
}