import { useState } from "react";
import {useNavigate} from "react-router-dom";
import {
    Container,
    Grid,
    Box,
    Typography
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import { useForm, FormProvider } from 'react-hook-form';
import FormInput from '../components/FormInput';
import PageWrapper from "../components/PageWrapper";
import { sendPasswordReset } from "../auth/auth";
import { object, string } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import AlertDialog from "../components/AlertDialog";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [message, setMessage] = useState(undefined);

  // 👇 Default Values
  const defaultValues = {
    email: ''
  };

  // 👇 Forgot Password Schema with Zod
  const forgotPasswordSchema = object({
    email: string().min(1, 'Email is required').email('Email is invalid'),
  });

  // 👇 The object returned from useForm Hook
  const methods = useForm({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues,
  });


  // 👇 Submit Handler
  const onSubmitHandler = async (values) => {
    await sendPasswordReset(values["email"]);
    setMessage("If you have previously registered with MyReportAssistant, then check your email for a reset link.");
  };


  return (
    <PageWrapper>
      <Container
        maxWidth={false}
        sx={{ height: "80vh", paddingTop: "1vh" }}
      >
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          sx={{ width: '100%', height: '100%' }}
        >
          <Grid item sx={{ maxWidth: '70rem', width: '100%', backgroundColor: '#fff' }}>
            <FormProvider {...methods}>
              <Grid
                container
                sx={{
                  boxShadow: { sm: '0 0 5px #ddd' },
                  py: '6rem',
                  px: '1rem',
                }}
              >
                <Grid
                  item
                  container
                  justifyContent='space-between'
                  rowSpacing={5}
                  sx={{
                    width: "100%",
                    maxWidth: "30rem",
                    marginInline: 'auto',
                  }}
                >

                    <Box
                      display='flex'
                      flexDirection='column'
                      component='form'
                      noValidate
                      autoComplete='off'
                      sx={{ width: "100%" }}
                      onSubmit={methods.handleSubmit(onSubmitHandler)}
                    >
                      <Typography
                        variant='h6'
                        component='h1'
                        sx={{ textAlign: 'center', mb: '1.5rem' }}
                      >
                        Forgot Password
                      </Typography>
  
                      <FormInput
                        label='Enter your email'
                        type='email'
                        name='email'
                        focused
                        required
                      />

                      <LoadingButton
                        loading={false}
                        type='submit'
                        variant='contained'
                        sx={{
                          py: '0.8rem',
                          mt: 2,
                          width: '80%',
                          marginInline: 'auto',
                        }}
                      >
                        Send reset email
                      </LoadingButton>
                    </Box>

                </Grid>                           
              </Grid>
            </FormProvider>
          </Grid>
        </Grid>
      </Container>
      <AlertDialog message={message} title="Email sent" onClose={() => navigate("/login")}/>
    </PageWrapper>
  );
};