import { useContext, useState } from "react";
import {useNavigate} from "react-router-dom";
import AppContext from "../components/AppContext";
import { GoogleLoginButton } from "react-social-login-buttons";
import {
    Container,
    Grid,
    Box,
    Typography,
    Stack
} from '@mui/material';
import Button from '@mui/material/Button';
import { useForm, FormProvider } from 'react-hook-form';
import FormInput from '../components/FormInput';
import PageWrapper from "../components/PageWrapper";
import completeLogin from "../helpers/httpRequests/completeLogin";
import clearAllContext from "../helpers/clearAllContext";
import { logInWithEmailAndPassword, signInWithGoogle } from "../auth/auth";
import { object, string, boolean } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { LinkItem } from "../globalStyles";
import AlertDialog from "../components/AlertDialog";
import ReCAPTCHA from "react-google-recaptcha";


export default function Login() {
  const navigate = useNavigate();
	const appContext = useContext(AppContext);
  const [error, setError] = useState(undefined);
  const [reCaptchaToken, setReCaptchaToken] = useState(null);

  // Make sure all context is clear
  appContext.educator = undefined;
  clearAllContext(appContext);

  // 👇 Default Values
  const defaultValues = {
    email: '',
    password: '',
    persistUser: false
  };

  // 👇 Login Schema with Zod
  const loginSchema = object({
    email: string().min(1, 'Email is required').email('Email is invalid'),
    password: string()
      .min(1, 'Password is required')
      .min(8, 'Password must be more than 8 characters')
      .max(32, 'Password must be less than 32 characters'),
    persistUser: boolean().optional(),
  });

  // 👇 The object returned from useForm Hook
  const methods = useForm({
    resolver: zodResolver(loginSchema),
    defaultValues,
  });


  //============================================================================
  // Handle login with email/password
  //============================================================================
  const handleLoginWithEmailAndPassword = async (values) => {

    const result = await logInWithEmailAndPassword(values["email"], values["password"]);
    if (result.error) {
      setError(result.error);
    } else if (result.user.emailVerified === false) {
      setError( "User not found or invalid password or user not verified. If you haven't verified your email, please do so now. " +
                "If you have only logged in with Google, then in order to use an email/password to login, you will need to first log in with Google " +
                "then select the menu option to 'Link Email/Password'.");
    } else {
      await continueLogin(result.user);
    }
  };


  //============================================================================
  // Handle login with Google provider
  //============================================================================
  const handleLoginWithGoogle = async () => {
    const result = await signInWithGoogle();
    if (result.error) {
      setError(result.error);
    } else if (result.user) {
      await continueLogin(result.user, true);
    }
  }


  //============================================================================
  //  Regardless to how the user logged in, complete the login.
  //============================================================================
  const continueLogin = async (user, isProvider=false) => { 

    // Setup authentication context
    appContext.email = user.email;
    appContext.accessToken = user.accessToken;
    appContext.refreshToken = user.refreshToken;

    // Allow email/password link if password provider not available yet
    const passwordProvider = user.providerData.find(provider => {
      return provider.providerId === "password"
    });
    appContext.allowLink = (passwordProvider === undefined);

    // Access our database to get remainder of user context
    const loginContext = await completeLogin(isProvider, reCaptchaToken);
    if (loginContext.error) {
      appContext.email = undefined;
      appContext.accessToken = undefined;
      appContext.refreshToken = undefined;
      setError(loginContext.error);
    } else {
      appContext.educator = loginContext.educator;

      if (appContext.educator.agreeToTermsAndConditions) {
        navigate("/selectClassroom");
      } else {
        navigate("/agreeToTerms");
      }
    } 
  }


  //============================================================================
  // Handle change to the Captcha
  async function onChange(value) {
    setReCaptchaToken(value);
  }


  return (
    <PageWrapper title="Login">
      <Container
        maxWidth={false}
        sx={{ height: "80vh", paddingTop: "1vh" }}
      >
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          sx={{ width: '100%', height: '100%' }}
        >
          <Grid item sx={{ maxWidth: '70rem', width: '100%', backgroundColor: '#fff' }}>
            <FormProvider {...methods}>
              <Grid
                container
                sx={{
                  boxShadow: { sm: '0 0 5px #ddd' },
                  py: '6rem',
                  px: '1rem',
                }}
              >
                <Grid
                  item
                  container
                  justifyContent='space-between'
                  rowSpacing={5}
                  sx={{
                    maxWidth: { sm: '45rem' },
                    marginInline: 'auto',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ borderRight: { sm: '1px solid #ddd' } }}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      component='form'
                      noValidate
                      autoComplete='off'
                      sx={{ paddingRight: { sm: '3rem' } }}
                      onSubmit={methods.handleSubmit(handleLoginWithEmailAndPassword)}
                    >
                      <Typography
                        variant='h6'
                        component='h1'
                        sx={{ textAlign: 'center', mb: '1.5rem' }}
                      >
                        Log into your account
                      </Typography>
  
                      <FormInput
                        label='Enter your email'
                        type='email'
                        name='email'
                        focused
                        required
                      />
                      <FormInput
                        type='password'
                        label='Password'
                        name='password'
                        required
                        focused
                      />

                      <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={onChange} />
                      <br/>

                      <Button
                        disabled={reCaptchaToken === null}
                        type='submit'
                        variant='contained'
                        sx={{
                          py: '0.8rem',
                          mt: 2,
                          width: '80%',
                          marginInline: 'auto',
                        }}
                      >
                        Login
                      </Button>
                      

                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant='h6'
                      component='p'
                      sx={{
                        paddingLeft: { sm: '3rem' },
                        mb: '1.5rem',
                        textAlign: 'center',
                      }}
                    >
                      Log in with another provider:
                    </Typography>
                    <Box
                      display='flex'
                      flexDirection='column'
                      alignItems='center'
                      sx={{ paddingLeft: { sm: '3rem' }, rowGap: '1rem' }}
                    >
                      <GoogleLoginButton style={{width: "80%"}} onClick={handleLoginWithGoogle}/>
                    </Box>
                  </Grid>
                </Grid>                           

                <Grid container justifyContent='center'>
                  <Stack sx={{ mt: '3rem', textAlign: 'center' }}>
                    <Typography sx={{ fontSize: '0.9rem', mb: '1rem' }}>
                      Need an account?{' '}
                      <LinkItem to='/signUp'>Sign up here</LinkItem>
                    </Typography>
                    <Typography sx={{ fontSize: '0.9rem' }}>
                      Forgot your{' '}
                      <LinkItem to='/forgotPassword'>password?</LinkItem>
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </FormProvider>
          </Grid>
        </Grid>
      </Container>
      <AlertDialog message={error} severity="error" title="Login Error" onClose={() => setError(undefined)}/>
    </PageWrapper>
  );
};