import React, { useContext } from "react";
import {useNavigate} from "react-router-dom";
import AppContext from "../components/AppContext";
import PageWrapper from "../components/PageWrapper";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {TextContainer } from "../globalStyles";

export default function TermsAndConditions() {
  const navigate = useNavigate();


  return (
    <PageWrapper
      title="Privacy Policy"
      leftButton="Back"
      leftIcon={<ArrowBackIcon />}
      onLeftClick={() => navigate(-1)}
    >
          <TextContainer style={{marginLeft: "30px", marginRight: "30px"}}>

            <br/>
            <h1>PRIVACY POLICY</h1>

            <p>Last Updated: April 26, 2023</p>
            <br/>
            <p>This Privacy Policy describes how we collect, use, process, and disclose your information, including personal information, 
               in conjunction with your access to and use of the <b>MyReportAssistant</b> website ("Service"). Please read this Privacy Policy 
               carefully.
            </p>
            <br/>
            <p>This Privacy Policy is compliant with the laws of Ontario, Canada as our initial offering is targeting users of the Ontario Curriculum only.</p>

            <br/>
            <h2>Information We Collect</h2>
            <p>We collect information to provide and improve our Service to you.</p>

            <br/>
            <div style={{marginLeft: "30px"}}>
            <h4> Information You Give Us</h4>

            <p>When you sign up and use our Service, we collect the following information:</p>
            <div style={{marginLeft: "40px"}}>
              <ul>
                <li>Registration information: This includes your email address/password or Google provider information when you sign up for our Service.  You are responsible for using non-trivial passwords and maintaining these passwords and/or Google accounts secure.</li>
                <li>Class list information, including only the first names of your students, along with their gender and IEP status: This information is used to personalize the report card comments. You agree to provide ONLY this information for your students, with no additional identifiable information.</li>
                <li>Report card comments: We collect and store your generated report card comments for a period of approximately 7 days.  This is to provide you with a reasonable timeframe to review and copy these comments into your report cards.</li>
                <li>Tags used to remember which expectations were selected in previous terms.</li>
              </ul>
            </div>
            <br/>
            <p>All emails, student names, gender, expectation selection tags, and report comments are securely encrypted in our database.</p>

            <br/>
            <h4>How We Use Your Information</h4>
            <p>We use, store, and process your information to provide, understand, improve, and develop our Service, and to create and maintain a safer environment.</p>
            <br/>
            <p>Specifically, we use the information:</p>
            <div style={{marginLeft: "40px"}}>
              <ul>
                <li>To enable you to access and use our Service.</li>
                <li>To comply with our legal obligations.</li>
                <li>To provide customer service.</li>
                <li>To send you service or support messages, updates, and account notifications.</li>
              </ul>
            </div>
            </div>

            <br/>
            <h2>Sharing & Disclosure</h2>

            We respect your privacy and do not share your information with third parties except as described in this policy.

            We may disclose your information:
            <div style={{marginLeft: "40px"}}>
              <ul>
                <li>To comply with legal process or a regulatory investigation.</li>
                <li>If we believe it's necessary to prevent harm or illegal activities.</li>
              </ul>
            </div>

            <br/>
            <h2>Data Security</h2>
            We are committed to protecting the data of the Service's users. We implement appropriate technical and organizational measures to help protect the security of your personal information. However, please note that no system is ever completely secure.

            <br/><br/>
            <h2>Data Retention</h2>
            We retain your information for as long as necessary to provide the Service and fulfill the transactions you have requested, or for other essential purposes such as complying with our legal obligations. Your report card comments are encrypted and stored for only seven days.

            <br/><br/>
            <h2>Your Rights</h2>
            You have certain rights in relation to your personal information. These include the right to access, correct, delete, or transfer your personal information, and in some circumstances to object to our processing of your personal information.

            <br/><br/>
            <h2>Changes to this Privacy Policy</h2>
            We may update this Privacy Policy from time to time, so please review it frequently. If we change our Privacy Policy, we will post the updated policy on our website and update the "Last Updated" date.

            <br/><br/>
            <h2>Contact</h2>
            <p>If you have any questions or complaints about this Privacy Policy or our information handling practices, you may email us at <b>myReportAssistant@gmail.com</b></p>
            <br/>
            <p>By using our Service, you are confirming that you have read and understood this policy including how and why we use your information.</p>
          </TextContainer>

    </PageWrapper>
  );
}