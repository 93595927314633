import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../components/AppContext";
import PageWrapper from "../components/PageWrapper";
import assignString from "../helpers/assignString";
import Constants from "../constants";
import { TitleText, SmallText } from "../globalStyles";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CopyToClipboard } from "react-copy-to-clipboard";


export default function ReviewComment() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const [comment, setComment] = useState(appContext.comment);
  const [commentError, setCommentError] = useState(undefined);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);


	//====================================================================
	// Handle change to comment
	//====================================================================
  const handleCommentChange = (event) => {
		event.preventDefault();
    setCopiedToClipboard(false);
		assignString("Comment", event.target.value, Constants.MAX_COMMENT_SIZE, setComment, setCommentError);
	};


  //============================================================================
  // Handle request to copy comment to clipboard
  //============================================================================
  const handleCopyToClipboard = async () => {
    setCopiedToClipboard(true);
  }


  return(

    <PageWrapper 
      title={"Comment for " + appContext.student.name}
      leftButton="Back"
			leftIcon={<ArrowBackIcon />}
			onLeftClick={() => navigate(-1)}
      showSubject
    >

      <div style={{backgroundColor: "#ffffff"}}>
        <br/>
        <div style={{padding: "0px 20px"}}>
          <TitleText>{appContext.subject.label} Comment for {appContext.student.name}</TitleText>
          <div>
            <br/>
            <SmallText>Comment length: {comment.length}</SmallText>
            <br/>
            <CopyToClipboard text={comment} onCopy={handleCopyToClipboard}>
              <div style={{display: "flex", justifyContent: "end", marginBottom: "5px"}}>
                <Button variant="outlined" endIcon={<ContentCopyIcon />} size="small" disabled={copiedToClipboard || !comment || (comment.length === 0)}>Copy to clipboard</Button>
              </div>
            </CopyToClipboard>
            <TextField 
              fullWidth 
              error={commentError !== undefined}
              multiline 
              label="Your Comment" 
              id="fullWidth" 
              name="comment" 
              value={comment} 
              onChange={handleCommentChange} 
              helperText={commentError}
            />
          </div>
        </div>
      </div>

    </PageWrapper>
  );
}