// This function is used to sort a list of objects by name
export default function compareByName(a,b) {
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();
    if ( aName < bName ) {
        return -1;
    } else if ( aName > bName ) {
        return 1;
    } else {
        return 0;
    }
}