import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../components/AppContext";
import PageWrapper from "../components/PageWrapper";
import { TitleText } from "../globalStyles";
import MarkSummary from "../components/MarkSummary";
import ConfirmDialog from "../components/ConfirmDialog";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function SummaryPage() {

  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const [outOfCredits, setOutOfCredits] = useState(false);


  //====================================================================
  // Handle continue
  //====================================================================
	function handleGenerateComment(event) {
      event.preventDefault();
      if (appContext.educator.subscriptionData.commentsRemaining === 0) {
        setOutOfCredits(true);
      } else {
        navigate("/getComment");
      }
	};


  //====================================================================
  // Add Student to class list
  //====================================================================
  async function handleCreditPurchase() {
    setOutOfCredits(false);
    navigate("/pricing");
  }


  return (
    <PageWrapper
      title={"Summary for " + appContext.student.name }
      leftButton="Back"
			leftIcon={<ArrowBackIcon />}
			onLeftClick={() => navigate(-1)}
			rightButton="Generate Comment"
			rightIcon={<ArrowForwardIcon />}
			onRightClick={handleGenerateComment}
      showSubject
      showComments
    >
        <div style={{backgroundColor: "#ffffff"}}>
          <div style={{padding: "0px 20px"}}>
            <TitleText>The following is a summary of what you have selected:</TitleText>
            <br/>
            <MarkSummary/>
          </div>
        </div>      
        
        {outOfCredits && <ConfirmDialog title="Out of Comment Credits" prompt="Press Continue to purchase additional comment credits" onCancel={() => setOutOfCredits(false)} onConfirm ={handleCreditPurchase}/>}

        
    </PageWrapper>
  );
}