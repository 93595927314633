import React, { useContext, useState } from "react";
import {useNavigate} from "react-router-dom";
import AppContext from "../components/AppContext";
import PageWrapper from "../components/PageWrapper";
import AlertDialog from "../components/AlertDialog";
import completeLogout from "../helpers/completeLogout";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TextContainer, ButtonWrapper } from "../globalStyles";
import { Button, Typography } from '@mui/material';
import updateEducatorAgreeToTermsAndConditions from "../helpers/httpRequests/updateEducatorAgreeToTermsAndConditions";

export default function AgreeToTerms() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const [message, setMessage] = useState(undefined);


  //====================================================================
  // Handle Back Button.
  // If educator has not yet accepted the terms and conditions, then
  // log them out.   If they have accepted, then the terms and conditions
  // are being displayed from menu, so just navigate back.
  //====================================================================
  function handleBack(event) {
    event.preventDefault();
    completeLogout(navigate);
  }

  //====================================================================
  // Accept terms, flag the educator as having  agreed to the terms and 
  // conditions, as well as the privary policy
  //====================================================================
  async function acceptTerms(event) {
    event.preventDefault();
    await updateEducatorAgreeToTermsAndConditions(appContext);
    appContext.educator.agreeToTermsAndConditions = true;
    setMessage("MyReportAssistant will allow you to select expectations to match your students performance, either from our bank of expectations, or by customizing your own.   MyReportAssistant will create a well crafted paragraph from your input, BUT it remains your responsibility to ensure the content and suitability of the paragraph for your student.");
  }

  
  return (
    <PageWrapper
      title="Agree to Terms and Conditions, Privacy Policy"
      leftButton="Back"
      leftIcon={<ArrowBackIcon />}
      onLeftClick={handleBack}
    >
      <TextContainer style={{marginLeft: "30px", marginRight: "30px"}}>

        <Typography variant="h6" color="primary">
          Before proceeding further, we kindly ask you to take a few moments to review our "Terms and Conditions" and our "Privacy Policy". By continuing to use our service, you acknowledge that you have read, understood, and agreed to be bound by these terms and policies. 
        </Typography>

        <ButtonWrapper style={{marginTop: "20px"}}>
          <Button variant="outlined" onClick={() => navigate("/termsAndConditions")}><Typography variant="h7">Review Terms and Conditions</Typography></Button>
          <Button variant="outlined" onClick={() => navigate("/privacyPolicy")}><Typography variant="h7">Review Privacy Policy</Typography></Button>
        </ButtonWrapper>

        <br/><br/>
        <Typography variant="h6" color="primary">
          Please note, you may also access these documents at any time from the top right menu after completing your login.        
        </Typography>

        <br/>
        <Typography variant="h6" color="primary">
          Your trust is our top priority, and we're committed to protecting your rights and interests. If you have any questions or concerns regarding these documents, please do not hesitate to contact our customer service team at "myReportAssistant@gmail.com".
        </Typography>


        <br/>
        <Typography variant="h6" color="primary">
          By pressing "I Agree", you are acknowledging that you have read, understood, and consent to both our "Terms and Conditions" and "Privacy Policy".
        </Typography>

      </TextContainer>

      <ButtonWrapper style={{marginTop: "20px"}}>
        <Button variant="outlined" onClick={handleBack}><Typography variant="h7">Decline</Typography></Button>
        <Button variant="outlined" onClick={acceptTerms}><Typography variant="h7">I agree</Typography></Button>
      </ButtonWrapper>

      <AlertDialog message={message} title="Confirm Usage" onClose={() => navigate("/selectClassroom", {replace: true})}/>

    </PageWrapper>
  );
}