import React, { useContext, useState } from "react";
import {useNavigate} from "react-router-dom";
import AppContext from "../components/AppContext";
import PageWrapper from "../components/PageWrapper";
import AlertDialog from "../components/AlertDialog";
import getNickname from "../helpers/getNickname";
import assignString from "../helpers/assignString";
import { ButtonWrapper } from "../globalStyles";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Constants from "../constants";


export default function SpecificComment () {
	const navigate = useNavigate();
	const appContext = useContext(AppContext);

	const [error, setError] = useState(undefined);
	const [sending, setSending] = useState(false);
	const [yourComment, setYourComment] = useState(appContext.student.yourComment ?? "");
	const [commentError, setCommentError] = useState(undefined);
	const [placement, setPlacement] = useState(appContext.student.educatorCommentPlacement ?? "end");



	//====================================================================
	// Handle change to input
	//====================================================================
	const handlePlacementChange = (event) => {
		setPlacement(event.target.value);
	};


	//====================================================================
	// Handle change to comment
	//====================================================================
	const handleCommentChange = (event) => {
		event.preventDefault();
		appContext.student.yourComment = assignString("Student comment", event.target.value, Constants.MAX_SPECIFIC_COMMENT_SIZE, setYourComment, setCommentError);
	};


	//====================================================================
	// Handle change to comment
	//====================================================================
	const handleClearComment = (event) => {
		event.preventDefault();
		appContext.student.yourComment = "";
		setYourComment("");
		setCommentError(undefined);
	};


	//====================================================================
	// Prep the comment
	//====================================================================
	const prepComment = (comment) => {
		if (!comment.endsWith(".")) {
			comment += ".";
		}
		return comment;
	}


	//====================================================================
	// Handle change to input
	//====================================================================
	const handleNext = (event) => {
		event.preventDefault();

		const comment = yourComment.trim();
		appContext.student.yourComment = comment;
		if (comment.length > 0) {
			appContext.student.educatorComment = prepComment(comment);
		} else {
			appContext.student.educatorComment = undefined;
		}

		appContext.student.educatorCommentPlacement = placement;
		navigate("/summaryPage");
	}


	// Does comment contain student's name
	const containsStudentName = yourComment && 
								((yourComment.indexOf(getNickname(appContext.student) + " ") >= 0) ||
								 (yourComment.indexOf(" " + getNickname(appContext.student)) >= 0));

	return (
		<PageWrapper
			title={"Specific Comment for " + appContext.student.name }
			leftButton="Back"
			leftIcon={<ArrowBackIcon />}
			onLeftClick={() => navigate(-1)}
			leftDisabled={sending}
			rightButton={(yourComment && yourComment.trim().length > 0) ? "Accept Comment" : "Skip"}
			rightIcon={<SkipNextIcon />}
			onRightClick={handleNext}
			rightDisabled={sending}
			showSubject
			showComments
		>


			<div style={{backgroundColor: "#ffffff", padding: "10px 20px 20px 20px"}}>
				<Typography variant="h6" sx={{marginLeft: "20px", marginRight: "20px", marginBottom: 0}}>
					Here we allow you to add a specific user comment, that can be embedded as part of the overall comment
					or added to the beginning or end.
				</Typography>
				<br/>

				<div style={{padding: "0px 20px 0px 20px"}}>
					<FormControl>
						<FormLabel id="placement-label">Placement Options</FormLabel>
						<RadioGroup
							aria-labelledby="placement-label"
							name="placement"
							value={placement}
							row
							onChange={handlePlacementChange}
						>
								<FormControlLabel name="placement" value="beginning" control={<Radio />} label="Beginning" />
								{appContext.subject.groupExpectationsByStrength && <FormControlLabel name="placement" value="embed" control={<Radio />} label="Embed" />}
								<FormControlLabel name="placement" value="end" control={<Radio />} label="End" />
						</RadioGroup>
					</FormControl>
					<br/><br/>
					{(placement === "embed") && <p>The comment will be embedded between the strengths and weaknesses that you have identified</p>}
					{(placement === "beginning") && <p>The comment will be placed at the beginning, even before any opening statement or general comment</p>} 
					{(placement === "end") && <p>The comment will be placed at the end, but before any next steps</p>} 
					<br/>
					<Typography variant="h6">
					Please keep in mind that any text you provide here will be included and reworded along with the remainder of the final paragraph.
					</Typography>

				</div>
				<div style={{padding: "0px 10px"}}>
					<br/>
					<br/>
					<TextField 
						fullWidth 
						error={commentError !== undefined} 
						multiline 
						label="Your Student Comment" 
						value={yourComment}
						name="yourComment" 
						placeholder={"You may enter up to " + Constants.MAX_SPECIFIC_COMMENT_SIZE + " characters for your student specific comment"}
						helperText={commentError}
						variant="outlined"
						onChange={handleCommentChange}
					/>

					{containsStudentName && 
						<Alert severity="warning" sx={{marginTop: "10px"}}>
							Please try and AVOID the use of the student name in your comment
						</Alert>
					}

					{(yourComment.trim().length > 0) &&
						<ButtonWrapper style={{margin: "20px 0px"}}>
							<Button variant="outlined" size="small" endIcon={<SaveIcon />} onClick={handleClearComment}>Clear comment</Button>
						</ButtonWrapper>
					}

				</div>
				<AlertDialog title="Comment Error" message={error} severity="error" onClose={() => setError(undefined)}/>

			</div>


		</PageWrapper>
	);
};