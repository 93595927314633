import React, { useState, useContext, useEffect } from "react";
import AppContext from "../components/AppContext";
import { useTheme } from '@mui/material/styles';
import Selector from "./Selector";
import getMarkingLabel from "../helpers/getMarkingLabel";
import { Typography } from '@mui/material';

export default function SubjectMarkCard({onClick}) {
    const appContext = useContext(AppContext);
    const theme = useTheme();

    // Setup state for trying again
    const [data, setData] = useState({
        subjectMark: appContext.subject.mark
	});

    useEffect(() => { 
        setData( {...data, subjectMark: appContext.subject.mark} );
    }, [appContext.subject.mark]);


    //====================================================================
    // Handle a change to the student's mark in the subject
    //====================================================================
    function handleMarkChange(event) {
        setData({...data, subjectMark: appContext.subject.mark});
        if (onClick) {
            onClick(event.target.value);
        }
    }


    // If marking supported by this strand, get choices and label to use
    const markingChoices = [...appContext.subject.markingScheme];
    const markingLabel = getMarkingLabel(appContext);

    return (
        <div style={{padding: "10px", backgroundColor: theme.palette.primary.light, border: "1px solid #1d609c"}}>

            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 10px", }}>
                <Typography variant="h5" color="white" textAlign="left">Start with Overall Mark for {appContext.subject.label}</Typography>
                <Selector choices={markingChoices} value={data.subjectMark} onClick={handleMarkChange}/>
            </div>
            <div style={{marginLeft: "20px", color: "white"}}>
                <p>The overall mark that you select for {appContext.student.name} will be used as a default value for all the strands.</p>
                <p>*** NOTE: Changing the mark for the subject will RESET all strand marks and selections.</p>
            </div>

        </div>
    );
}