//====================================================================
// If the strand/subject has expectations defined for both positive and 
// negative types, does this expectation match the mark
//====================================================================
export default function markFilterSatisfied(parent, expectation) {

    // The expectation will match the mark filter if:
    // - it doesn't have a mark specified
    // - if mark is specified, and it matches the mark assigned
    return !expectation.marks || 
           (expectation.marks && parent.mark && (expectation.marks.indexOf(parent.mark) >= 0));
}