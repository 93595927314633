//====================================================================
// If the strand/subject has expectations defined for both positive and 
// negative types, does this expectation match the mark
//====================================================================
export default function strengthOrWeaknessSatisfied(subject, expectation, strengthOrWeakness) {

    // Map strength/weakness setting
    if (strengthOrWeakness === "Strength") {
        strengthOrWeakness = subject.markingScheme.substring(2);
    } else if (strengthOrWeakness === "Weakness") {
        strengthOrWeakness = subject.markingScheme.substring(0, 2);
    } else {
        strengthOrWeakness = undefined;
    }

    return expectation.marks === strengthOrWeakness;
}