import { auth, googleProvider } from './firebase';
import { 
    signInWithPopup, 
    signInWithEmailAndPassword, 
    createUserWithEmailAndPassword,
    updateProfile,
    sendEmailVerification,
    sendPasswordResetEmail,
    signOut,
    EmailAuthProvider,
    linkWithCredential
} from "firebase/auth";
import AppContext from '../components/AppContext';


//==============================================================================
// Sign in with Google Provider
//==============================================================================
export const signInWithGoogle = async () => {
    let result = {};
    try {
      result = await signInWithPopup(auth, googleProvider);
    } catch (err) {

      // Ignore pop-up being dismissed, otherwise report on the error
      if ((err.message.indexOf("popup-closed-by-user") < 0) &&
          (err.message.indexOf("cancelled-popup-request") < 0)) {
        result.error = err.message;
      }
    }

    return result;
  }


  //==============================================================================
  // Link 
  //==============================================================================
  export const linkEmailPassword = async (email, password) => {
    let result = {};
    try {
      const credential = EmailAuthProvider.credential(email, password);
      await linkWithCredential(auth.currentUser, credential);
      AppContext.allowLink = false;
      result.message = "Account linked";
    } catch(err) {
      result.error = "Account linking error: " + err.message;
    }

    return result;
  }

  
  //==============================================================================
  // Sign in with email/password
  //==============================================================================
  export const logInWithEmailAndPassword = async (email, password) => {
    let result = {};
    try {
      result = await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        result.error = "User not found or invalid password or user not verified. If you haven't verified your email, please do so now. " +
                       "If you have only logged in with Google, then in order to use an email/password to login, you will need to first log in with Google " +
                       "then select the menu option to 'Link Email/Password'. Alternatively, you may select 'Forgot Password' to reset your password.";
    }

    return result;
  };
  
  
  //==============================================================================
  // Register with email/password
  // NOTE:  The creation of the user will complete on the first successful
  //        login.   This is to be compatible with logging in through a provider.
  //==============================================================================
  export const registerWithEmailAndPassword = async (displayName, email, password) => {
    let result = {};
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(auth.currentUser, {displayName});
      sendEmailVerification(auth.currentUser);
      result.message = "User created with email and password";
    } catch (err) {
      if (err.message.indexOf("email-already-in-use") >= 0) {
        result.error = "Could not create user.  It appears that you previously signed in with a google account that uses the same email.   If you would like to sign in with an email/password, please first sign in with your google account, then go to the 'Link Accounts' option in the user menu to set your password";
      } else {
        result.error = err.message;
      }
    }
    return result;
  };
  
  
  //==============================================================================
  // Forgot password.
  // If an auth error exists, we simply ignore to avoid fishing
  //==============================================================================
  export const sendPasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (err) {
      // Ignore any errors
    }
  };
  
  
  //==============================================================================
  // Sign-out
  //==============================================================================
  export const logout = async () => {
    await signOut(auth);
  };