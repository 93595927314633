export default async function clearAllContext(appContext) {

    // Clear all previous state (other than user state)
    appContext.classroom = undefined;
    appContext.curriculum = undefined;
    appContext.subject = undefined;
    appContext.student = undefined;
    appContext.subject = undefined;
    appContext.subjectAssessment = undefined;
    appContext.assessedAtGrade = undefined;
    appContext.messages = undefined;
    appContext.comment = undefined;
    appContext.previousComment = undefined;
}