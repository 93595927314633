//=========================================================================
// Does strand have selected expectations
//=========================================================================
export default function strandHasSelectedExpectations(strand) {
    let hasSelected = false;
    for (const group of strand.groups) {
        if (group.expectations) {
            for (const expectation of group.expectations) {
                if (expectation.isSelected === true) {
                    hasSelected = true;
                    break;
                }
            }
        }
        if (hasSelected) {
            break;
        }
    }
    return hasSelected;
}