import { Component } from "react";
import ErrorPage from "../pages/ErrorPage";

export default class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false, error: undefined };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true, error };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
    }
  
    render() {
      if (this.state.hasError) {
        return <ErrorPage error={this.state.error}/>
      } else {
        return this.props.children; 
      }
    }
}