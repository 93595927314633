export default function camelCaseToString(str) {

    // use regular expression to match word boundaries in camel case string
    var words = str.match(/[A-Z]?[a-z]+|[0-9]+/g);

    // capitalize first word, and make the remaining words lowercase
    for (var i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }

    // join the words into a single string, separated by a space
    var result = words.join(' ');

    return result;
}