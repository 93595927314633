import React, { useContext } from "react";
import overview from '../videos/overview.mp4';


export default function Overview () {
	return (
        <div style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
            <video controls width="90%">
                <source src={overview} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
	);
};