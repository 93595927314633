import React, { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import PricingContent from "../components/PricingContent";
import AlertDialog from "../components/AlertDialog";


export default function Pricing() {
  const navigate = useNavigate();

  	// Setup state for info being collected
	const [data, setData] = useState({
    title: undefined,
    severity: "info",
    message: undefined,
	});

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setData({ ...data, 
        title: "Order Placed",
        message: "You will receive an email confirmation.  You will need to log back in to see the new subscription."
      });
    }

    if (query.get("canceled")) {
      setData({ ...data, 
        title: "Order Cancelled",
        severity: "warning",
        message: "Please log back in and try again."
      });
    }
  }, []);

  return (
    <>
    <PricingContent />
    <AlertDialog message={data.message} title={data.title} severify={data.severity} onClose={() => navigate("/login", {replace: true})} />
    </>
  );
}