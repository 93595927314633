import { capitalize } from "lodash";
import React, { useContext, useState } from "react";
import {useNavigate} from "react-router-dom";
import AppContext from "../components/AppContext";
import PageWrapper from "../components/PageWrapper";
import AlertDialog from "../components/AlertDialog";
import LabelList from "../components/LabelList";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TextField, Typography, IconButton, Paper, TableContainer, Table, TableRow, TableHead, TableBody } from '@mui/material';
import {StyledTableRow, StyledTableCell} from "../components/StyledTable";
import EditIcon from '@mui/icons-material/Edit';
import getSubject from "../helpers/httpRequests/getSubject";
import compareByLabel from "../helpers/compareByLabel";



export default function EditCurriculum() {
	const navigate = useNavigate();
	const appContext = useContext(AppContext);

	// Setup state for info being collected
	const [data, setData] = useState({
		sending: false,
		label: appContext.curriculum.label,
		grades: appContext.curriculum.grades,
		labelError: undefined
	});
	const [error, setError] = useState(undefined);


	//====================================================================
	// Handle change to input
	//====================================================================
	const handleChange = (event) => {
		setData({ ...data, 
			[event.target.name]: event.target.value, 
			labelError: undefined
		});
	};



	//====================================================================
	// Validate the form data
	//====================================================================
	const validateForm = () => {

		const labelError = (!data.label || data.label.trim().length === 0)
			? "Please provide a name for your curriculum"
			: undefined

		setData({...data, labelError});
		return labelError;
	}


	//====================================================================
	// Edit an existing subject from the current curriculum
	//====================================================================
	const editSubject = async (subject) => {

		// Proceed only if subject is not coming soon
		if (!subject.comingSoon) {
			appContext.subject = await getSubject(appContext.curriculum.name, subject.name);
			navigate("/editSubject");
		}
	}


	//====================================================================
	// Group subjects by grouping
	//====================================================================
	const getSubjectGroupings = () => {

		// Init the list of subjects.  Seed with Kindergarten so it appears first
		let subjectGroupings = [
			{ grouping: "kindergarten", subjects: [] }
		];

		// Place subject into appropriate subject grouping
		for (const subject of appContext.curriculum.subjects) {
			let subjectGrouping = subjectGroupings.find(group => group.grouping === subject.grouping);

			// If new subject grouping encountered, create bucket for it
			if (!subjectGrouping) {
				subjectGrouping = { grouping: subject.grouping, subjects: [] };
				subjectGroupings.push(subjectGrouping);
			}

			// Add subject to the grouping
			subjectGrouping.subjects.push(subject);
		}

		// Sort each of the grouping by label
		for (const grouping of subjectGroupings) {
			grouping.subjects.sort(compareByLabel);
		}

		return subjectGroupings;
	}


	const subjectGroupings = getSubjectGroupings();

	return (
		<PageWrapper
			title={appContext.curriculum ? "Edit Curriculum" : "Add Curriculum"}
			leftButton="Back"
			leftIcon={<ArrowBackIcon />}
			onLeftClick={() => navigate(-1)}
		>

			<div style={{width: "90%", margin: "auto"}}>

				<TextField sx={{ minWidth: "340px", marginTop: "10px" }}
					required
					disabled={appContext.curriculum.type === "system"}
					error={data.labelError !== undefined}
					name="label"
					label="Curriculum name"
					value={data.label}
					placeholder="Provide a unique name for your curriculum"
					onChange={handleChange}
					helperText={data.labelError}
					variant="outlined"
				/>

				<br/>
				<div style={{display:"flex", flexWrap: "wrap"}}>

					{subjectGroupings.map((subjectGrouping) => (
						<div key={subjectGrouping.grouping} style={{minWidth: "340px", marginTop: "20px", marginRight: "20px"}}>
							<TableContainer component={Paper}>
								<Table aria-label="customized table">
								<TableHead>
									<TableRow hover>
										<StyledTableCell>{capitalize(subjectGrouping.grouping)} Subjects</StyledTableCell>
										<StyledTableCell align="right"></StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{subjectGrouping.subjects.map((subject) => (
									<React.Fragment key={subject.name}>
										<StyledTableRow
											hover
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											onClick={async () => await editSubject(subject)}
										>
											<StyledTableCell component="th" scope="row">{subject.label}</StyledTableCell>
											<StyledTableCell  align="right" sx={{padding: "14px"}}>
												{subject.comingSoon && <Typography variant="subtitle" color="primary">Coming soon</Typography>}
												{!subject.comingSoon && <IconButton sx={{padding: 0}} color="primary" component="label">
													<EditIcon />
												</IconButton>}
											</StyledTableCell>
										</StyledTableRow>
									</React.Fragment>
									))}
								</TableBody>
								</Table>
							</TableContainer> 
						</div>
					))}

					<div style={{minWidth: "340px", marginTop: "20px", marginRight: "20px"}}>
						<LabelList 
							listName="Grade" 
							list={appContext.curriculum.grades} 
							nameLabel={true}
							editable={appContext.curriculum.type !== "system"}
						/>
					</div>

					<div style={{minWidth: "340px", marginTop: "20px", marginRight: "20px"}}>
						<LabelList 
							listName="Gender" 
							list={appContext.curriculum.genders} 
							editable={appContext.curriculum.type !== "system"}
						/>
					</div>

					<div style={{minWidth: "340px", marginTop: "20px", marginRight: "20px"}}>
						<LabelList 
							listName="Report Period" 
							list={appContext.curriculum.reportPeriods} 
							nameLabel={true}
							editable={appContext.curriculum.type !== "system"}
						/>
					</div>

					<div style={{minWidth: "340px", marginTop: "20px", marginRight: "20px"}}>
						<LabelList 
							listName="Marking Label" 
							list={appContext.curriculum.markingLabels} 
							editable={appContext.curriculum.type !== "system"}
						/>
					</div>
					<div style={{minWidth: "340px", marginTop: "20px", marginRight: "20px"}}>
						<LabelList 
							listName="Marking Scheme" 
							list={appContext.curriculum.markingSchemes} 
							editable={appContext.curriculum.type !== "system"}
						/>
					</div>
				</div>
			</div>
			
			<AlertDialog title="Curriculum Error" message={error} severity="error" onClose={() => setError(undefined)}/>

		</PageWrapper>
	);
}