export default function numberSelectionsInGroupLabel(group) {
    let total = 0;

    if (group.expectations) {
        for (const expectation of group.expectations) {
            if (expectation.isSelected) {
                total++;
            }
        }
    }
    
    if (group.nextSteps) {
        for (const nextStep of group.nextSteps) {
            if (nextStep.isSelected) {
                total++;
            }
        }
    }

    if (total > 0) {
        return "(" + total + ")";
    } else {
        return "";
    }
}