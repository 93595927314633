import styled, { createGlobalStyle } from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';


const GlobalStyle = createGlobalStyle`
  *{
    box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Montserrat', sans-serif;
  }
`;


export const BrandColor = {
	WHITE: "#FFF",
	PURPLE: "#CDC3DE",
	DARK_PURPLE_FADED: "#7B6798",
	DARK_PURPLE: "#503374",
	YELLOW: "#FFBF44",
 };
 

export const BackgroundVideo = styled.video`
	object-fit: cover;
	width: 100%;
	height: 100%;
	background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1));
	top: 0;
	position: absolute;
	z-index: -1;
`;

export const PageText = styled.p`
	font-size: clamp(0.9rem, 1.5vw, 1.3rem);
	line-height: 24px;
	text-align: center;
	letter-spacing: 2px;
	color: #1d609c;
`;


export const SummaryCardRounded = styled.div`
	padding: 10px;
	background: white;
	border: 20px;
	flex: 1;
	max-width: 90%;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	justify-content: left;
	align-items: left;
	border-radius: 20px;
	flex-direction: column;
`;

export const PageCardRounded = styled.div`
	padding:  10px;
	background: white;
	border: 20px;
	flex: 1;
	max-width: 90%;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 20px;
	flex-direction: row;
	@media screen and (max-width: 768px) {
		max-width: 100% !important;
		flex-basis: 100%;
	}

	img {
		@media screen and (max-width: 768px) {
			display: none;
		}
	}
`;

export const PageCard = styled.div`
	padding: 0px;
	background: white;
	border: 20px;
	flex: 1;
	max-width: 60%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	@media screen and (max-width: 768px) {
		max-width: 100% !important;
		flex-basis: 100%;
	}

	img {
		@media screen and (max-width: 768px) {
			display: none;
		}
	}
`;

export const TextContainer = styled.div`
	text-align: left;
	content: "";
	display: inline-block;
	width: 90%;
`;


export const DynamicPageCard = styled.div`
	padding: 0px;
	color: ${props => props.color};
	background: ${props => props.background};
	border: 20px;
	flex: 1;
	max-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	@media screen and (max-width: 768px) {
		max-width: 100% !important;
		flex-basis: 100%;
	}

	img {
		@media screen and (max-width: 768px) {
			display: none;
		}
	}
`;

export const BlockImage = styled.img`
	display: block;
	width: 100%;
	height: null;
`;

export const WrappedRow = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const CategoryWrapper = styled.div`
	width: 50%;
	margin: 0 auto;
	display: flex !important;
	justify-content: center;
	flex-direction: row;
	align-items: center;
	border-radius: 10px;
	outline: none;

	@media screen and (min-width: 440px) {
		border: 1px solid #bebebe;
	}
`;

export const CategoryImage = styled.img`
	width: 100%;
	height: null;
	border-radius: 10px 10px 10px 10px;
	padding: 5px;
	object-fit: cover;
	vertical-align: middle;
`;

export const Container = styled.div`
	width: 100%;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding: 0 10px;
	@media screen and (max-width: 960px) {
		padding: 0 10px;
	}
`;

export const CardContainer = styled.div`
	width: 100%;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding: 0 0px;
	@media screen and (max-width: 960px) {
		padding: 0 10px;
	}
`;


export const CardDownloadContainer = styled.div`
	width: 100%;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding: 0 0px;
`;

export const TitleText = styled.p`
	margin-bottom: 0px;
	font-size: clamp(1.2rem, 1.5vw, 1.5rem);
	line-height: 24px;
	text-align: center;
	letter-spacing: 2px;
	color: #1d609c;
`;

export const SmallText = styled.p`
	margin-bottom: 0px;
	font-size: clamp(0.9rem, 1.1vw, 1.3rem);
	line-height: 24px;
	text-align: center;
	letter-spacing: 2px;
	color: #1d609c;
`;

export const DisclaimerText = styled.p`
	margin-bottom: 35px;
	font-size: clamp(0.6rem, 1.5vw, 1.3rem);
	line-height: 24px;
	text-align: left;
	letter-spacing: 2px;
	color: #1d609c;
`;

export const FooterText = styled.p`
	margin-bottom: 35px;
	font-size: clamp(0.7rem, 1.5vw, 1.3rem);
	line-height: 24px;
	text-align: left;
	letter-spacing: 2px;
	color: ${props => props.color};
`;

export const MainHeading = styled.h1`
	font-size: clamp(2.3rem, 6vw, 4.5rem);
	margin-bottom: 2rem;
	color: ${({ inverse }) => (inverse ? '$403ae3' : '#1d609c')};
	width: 100%;
	letter-spacing: 4px;
	text-align: center;
`;

export const Heading = styled.h2`
	font-size: clamp(1.3rem, 13vw, 3.1rem);
	margin: ${({ margin }) => (margin ? margin : '')};
	margin-bottom: ${({ mb }) => (mb ? mb : '')};
	margin-top: ${({ mt }) => (mt ? mt : '')};
	color: ${({ inverse }) => (inverse ? '$403ae3' : '#1d609c')};
	letter-spacing: 0.4rem;
	line-height: 1.06;
	text-align: center;
	width: ${({ width }) => (width ? width : '100%')};
`;
export const TextWrapper = styled.span`
	color: ${({ color }) => (color ? color : '')};
	font-size: ${({ size }) => (size ? size : '')};
	font-weight: ${({ weight }) => (weight ? weight : '')};
	letter-spacing: ${({ spacing }) => (spacing ? spacing : '')};
	padding: ${({ padding }) => (padding ? padding : '')};
	margin: ${({ margin }) => (margin ? margin : '')};
	margin-bottom: ${({ mb }) => (mb ? mb : '')};
	margin-top: ${({ mt }) => (mt ? mt : '')};
`;
export const Section = styled.section`
	padding: ${({ padding }) => (padding ? padding : '140px 0')};
	margin: ${({ margin }) => (margin ? margin : '')};
	background: ${({ inverse }) => (inverse ? 'white' : '#071c2f')};
	position: ${({ position }) => (position ? position : '')};
	width: ${({ width }) => (width ? width : 'auto')};
	min-width: ${({ minWidth }) => (minWidth ? minWidth : 'auto')};
	max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'auto')};
	height: ${({ height }) => (height ? height : 'auto')};
	max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : 'auto')};
	min-height: ${({ minHeight }) => (minHeight ? minHeight : 'auto')};
	@media screen and (max-width: 768px) {
		padding: ${({ smPadding }) => (smPadding ? smPadding : '70px 0')};
	}
`;
export const TransparentSection = styled.section`
	padding: ${({ padding }) => (padding ? padding : '140px 0')};
	margin: ${({ margin }) => (margin ? margin : '')};
	background: transparent;
	position: ${({ position }) => (position ? position : '')};
	width: ${({ width }) => (width ? width : 'auto')};
	min-width: ${({ minWidth }) => (minWidth ? minWidth : 'auto')};
	max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'auto')};
	height: ${({ height }) => (height ? height : 'auto')};
	max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : 'auto')};
	min-height: ${({ minHeight }) => (minHeight ? minHeight : 'auto')};
	@media screen and (max-width: 768px) {
		padding: ${({ smPadding }) => (smPadding ? smPadding : '70px 0')};
	}
`;

export const Row = styled.div`
	display: flex;
	justify-content: ${({ justify }) => (justify ? justify : '')};
	align-items: ${({ align }) => (align ? align : '')};
	gap: ${({ gap }) => (gap ? gap : '')};
	padding: ${({ padding }) => (padding ? padding : '')};
	margin: ${({ margin }) => (margin ? margin : '')};
	position: ${({ position }) => (position ? position : '')};
	width: ${({ width }) => (width ? width : 'auto')};
	min-width: ${({ minWidth }) => (minWidth ? minWidth : 'auto')};
	max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'auto')};
	height: ${({ height }) => (height ? height : 'auto')};
	max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : 'auto')};
	min-height: ${({ minHeight }) => (minHeight ? minHeight : 'auto')};
	flex-wrap: ${({ wrap }) => (wrap ? wrap : '')};
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: ${({ justify }) => (justify ? justify : '')};
	align-items: ${({ align }) => (align ? align : '')};
	gap: ${({ gap }) => (gap ? gap : '')};
	padding: ${({ padding }) => (padding ? padding : '')};
	margin: ${({ margin }) => (margin ? margin : '')};
	position: ${({ position }) => (position ? position : '')};
	width: ${({ width }) => (width ? width : 'auto')};
	min-width: ${({ minWidth }) => (minWidth ? minWidth : 'auto')};
	max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'auto')};
	height: ${({ height }) => (height ? height : 'auto')};
	max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : 'auto')};
	min-height: ${({ minHeight }) => (minHeight ? minHeight : 'auto')};
`;

export const ButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-flow: wrap;
	gap: 0.5rem;
`;

export const Button = styled.button`
	border-radius: 4px;
	background: none;
	white-space: nowrap;
	padding: 10px 20px;
	font-size: 16px;
	color: #1d609c;
	outline: none;
	border: 2px solid #1d609c;
	cursor: pointer;
	overflow: hidden;
	position: relative;
	&:before {
		background: #1d609c;
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: -1;
		transition: all 0.6s ease;
		width: 100%;
		height: 0%;
		transform: translate(-50%, -50%) rotate(45deg);
	}
	&:hover:before {
		height: 500%;
	}
	&:hover {
		color: black;
	}
`;

export const TextButton = styled.button`
	display: flex;
	width: 100%;
	border: 2px solid #666;
	border-radius: 4px;
	padding: 10px 20px;
	font-size: 18px;
	word-wrap: break-word;
	text-align: left;
	text-decoration: none;
	color: #666;
	white-space: normal;

	&:hover:hover {
		border-color: #333;
		color: #333;
	}
  
	&:hover:active {
		background-color: #ccc;
		border-color: #ccc;
		color: #fff;
	}
`;

export const CarouselImage = styled.img`
	width: 100%;
	height: null;
	border-radius: 10px 10px 0 0;
	object-fit: cover;
	vertical-align: middle;
`;


export const FormSection = styled.div`
	/* color: #fff; */
	padding: 160px 0;
	/* background: ${({ inverse }) => (inverse ? '#101522' : '#1d609c')}; */
	background: #3e89c7;
`;

export const FormTitle = styled.h1`
	margin-bottom: 24px;
	font-size: 48px;
	line-height: 1.1;
	font-weight: 600;
`;

export const FormContainer = styled.div`
	display: flex;
`;

export const FormColumn = styled.div`
	/* margin-bottom: 15px; */
	padding: 20px 20px;
	background: white;
	border: 20px;
	/* padding: ${({ small }) => (small ? '0 50px' : '0 15px')}; */
	flex: 1;
	max-width: 60%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	flex-direction: column;
	@media screen and (max-width: 768px) {
		max-width: 100% !important;
		flex-basis: 100%;
	}

	img {
		@media screen and (max-width: 768px) {
			display: none;
		}
	}
`;

export const FormRow = styled.div`
	display: flex;
	justify-content: center;
	margin: 0 -15px 0px -15px;
	flex-wrap: wrap;
	align-items: center;
`;

export const FormWrapper = styled.form`
	/* max-width: 540px; */
	padding-top: 0;
	width: 100%;
`;

export const FormMessage = styled(motion.div)`
	color: ${({ error }) => (error ? 'red' : 'green')};
	padding: 5px;
	text-align: center;
	margin-top: 1rem;
`;

export const FormInputRow = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: stretch;
	margin-bottom: 1.4rem;

	> p {
		font-size: 0.8rem;
		margin-top: 0.5rem;
		color: #f00e0e;
	}
`;
export const FormInput = styled.input`
	display: block;
	padding-left: 10px;
	outline: none;
	border-radius: 2px;
	height: 40px;
	width: 100%;
	border: none;
	border-bottom: 1px solid #cfcfcf;
	font-size: 1rem;
`;

export const FormLabel = styled.label`
	display: inline-block;
	font-size: 0.9rem;
	margin-bottom: 0.3rem;
	color: #afafaf;
`;
export const FormImgWrapper = styled.div`
	max-width: 555px;
	display: flex;
	justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')};
`;
export const FormImg = styled.img`
	padding-right: 0;
	border: 0;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
	max-height: 500px;
`;

export const FormButton = styled.button`
	border-radius: 4px;
	background: none;
	margin-top: 1.5rem;
	white-space: nowrap;
	color: #1d609c; 
	outline: none;
	width: 100%;
	font-size: 1.4rem;
	padding: 5px 15px;
	border: 2px solid #1d609c;
	cursor: pointer;
	position: relative;
	overflow: hidden;

	&:hover {
		color: #1d609c;
		transition: background-color 0.4s ease-in;
		background-color: black;
	}
`;

export const SummaryContainer = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
`;

export const SummaryCard = styled.div`
  height: 100%;
  border: 1px solid black;
  background-color: white;
  margin: 10px 0px 10px 0px;
`;


export const MarkLineItem= styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding: 5px 0px 5px 0px
`;


export const MyStyledCard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  background-color: white;
`;

export const MyStyledHeader = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px 10px 10px;
  align-items: center;
  justify-content: space-between;
  background-color: #88c7f9;
  color: white;
`;


export const StandHeader = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px 10px 10px;
  align-items: center;
  justify-content: space-between;
  background-color: #88c7f9;
  color: white;
`;


export const MyExpectationCard = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid #333;
  padding: 8px;
  margin: 0px;
  background-color: white;
  &:focus, &:active {
    outline: none;
  }
  &.active {
    border-color: blue;
	color: #ffffff;
	background-color: #4caf50;
  }
  &.hidden {
    border-color: blue;
	color: #aa0000;
	background-color: #ffffff;
  }
`;

export const SpacedBetween = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

export const MyExpectationEditCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #333;
  padding: 10px;
  margin: 0px;
  background-color: white;
  &:focus, &:active {
    outline: none;
  }
  &.active {
    border-color: red;
	background-color: #aa0000;
	color: white;
  }
`;


export const MyExpectationTitle = styled.div`
  flex: 1;
`;


export const MySelector = styled.div`
  display: flex;
  align-items: center;
`;

export const MySelectorButton = styled.button`
  border: 1px solid #333;
  padding: 6px 12px;
  cursor: pointer;
  &:focus, &:active {
    outline: none;
  }
  &.active {
    border-color: blue;
	color: #ffffff;
	background-color: #4caf50;
  }
`;

export const GroupSelector = styled.button`
  border: 1px solid #333;
  padding: 4px 8px;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
  &:focus, &:selected {
    outline: none;
  }
  &.selected {
    border-color: blue;
	color: #ffffff;
	background-color: #4caf50;
  }
  &.selectedHidden {
    border-color: blue;
	color: red;
	background-color: #4caf50;
  }
  &.hidden {
    border-color: blue;
	color: red;
  }
`;


// 👇 Styled React Route Dom Link Component
export const LinkItem = styled(Link)`
  text-decoration: none;
  color: #3683dc;
  &:hover {
    text-decoration: underline;
    color: #5ea1b6;
  }
`;


// 👇 Styled Material UI Link Component
export const OauthMuiLink = styled(MuiLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f6f7;
  border-radius: 1;
  padding: 0.6rem 0;
  column-gap: 1rem;
  text-decoration: none;
  color: #393e45;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: #fff;
    box-shadow: 0 1px 13px 0 rgb(0 0 0 / 15%);
  }
`;


export default GlobalStyle;